import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private apiService:ApiService, 
    private routes: Router) { 

    }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (localStorage.getItem('jwt') != null) {
      try{
        const verifyResult = await this.apiService.verify(localStorage.getItem('jwt')).toPromise()
        return true
      }catch(err) {}
    }
    this.routes.navigate(['/']);
    return false;
  } 

}
