import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import blackpink from './business-inquiries-translate.json';
import { Router } from "@angular/router";


@Component({
  selector: 'app-business-inquiries',
  templateUrl: './business-inquiries.component.html',
  styleUrls: ['./business-inquiries.component.scss'],
  providers: [ApiService]
})
export class BusinessInquiriesComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  msg = '';
  form: FormGroup;
  errorRegister: string | null = null
  translate = localStorage.getItem("translate")
  submitted: boolean = false
  submissionFailed: boolean = false
  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      full_name: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), Validators.required])],
      email: [null, Validators.compose([Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required])],
      business_name: [null, Validators.compose([Validators.required])],
      product_sold: [null, Validators.compose([Validators.required])],
      shipment_quantity: [null, [Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]],
      business_link: [null,]
    });

    this.translateArr = blackpink[localStorage.getItem("translate")]
    this.translate = localStorage.getItem("translate")
  }

  addInquiry() {
    const full_name = this.form.value.full_name;
    const phone = this.form.value.phone;
    const email = this.form.value.email;
    const business_name = this.form.value.business_name;
    const product_sold = this.form.value.product_sold;
    const shipment_quantity = parseInt(this.form.value.shipment_quantity);
    const business_link = this.form.value.business_link;

    this.service.createBusinessInquiry(full_name, phone, email, business_name, product_sold, shipment_quantity, business_link).subscribe(data => {
      this.submitted = true
    }, e => {
      console.log(e)
      this.submissionFailed = true
      this.errorRegister = "SERVICE_ERROR"
    })
    this.refresh();
  }
  refresh() {
    setTimeout(() => {
      this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() =>
          this.router.navigate(["business-inquiries"]));
    }, 3000);
  }
}
