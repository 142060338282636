<!-- <div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
  
    <app-breadcrumb-service></app-breadcrumb-service>
  
    <section id="pricing" class="section price-plan-area bg-gray overflow-hidden ptb_100">
      <div class="container">
        <div class="row justify-content-center pb-lg-5 pb-md-2 pb-sm-0">
          <div class="col-12 col-md-10 col-lg-7">
          
            <div class="section-heading text-center">
              <h2>{{translateArr.bp1}}</h2>
              <p class="d-none d-sm-block mt-4">{{translateArr.bp2}}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row price-plan-wrapper justify-content-center">
              <div class="col-lg-3 col-md-4 col-sm-6 mt-3 ">
                <div class="single-price-plan p-3 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                  <div class="plan-thumb text-center py-3">
                    <img loading="lazy" src="assets/img/services/sameday-service.png" alt="">
                  </div>
                 
                  <div class="plan-description">
                    <ul class="plan-features">
                      <li class="border-top py-3"><span
                          class="material-icons">access_time_filled</span>{{translateArr.bp3}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">place</span>{{translateArr.bp4}}</li>
                      <li class="border-top py-3"><span class="material-icons">local_mall</span>{{translateArr.bp5}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">emoji_people</span>-</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mt-3">
                <div class="single-price-plan p-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                  <div class="plan-thumb text-center py-3 ">
                    <img loading="lazy" src="assets/img/services/regular-plus.png" alt="">
                  </div>
              
                  <div class="plan-description">
                    <ul class="plan-features">
                      <li class="border-top py-3"><span
                          class="material-icons">access_time_filled</span>{{translateArr.bp6}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">place</span>{{translateArr.bp4}}</li>
                      <li class="border-top py-3"><span class="material-icons">local_mall</span>{{translateArr.bp5}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">emoji_people</span>{{translateArr.bp7}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mt-3">
                <div class="single-price-plan p-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                  <div class="plan-thumb text-center py-3">
                    <img loading="lazy" src="assets/img/services/nextday-service.png" alt="">
                  </div>
                  <div class="plan-description">
                    <ul class="plan-features">
                      <li class="border-top py-3"><span
                          class="material-icons">access_time_filled</span>{{translateArr.bp8}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">place</span>{{translateArr.bp4}}</li>
                      <li class="border-top py-3"><span class="material-icons">local_mall</span>{{translateArr.bp5}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">emoji_people</span>-</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mt-3">
                <div class="single-price-plan p-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                  <div class="plan-thumb text-center py-3">
                    <img loading="lazy" src="assets/img/services/cargo-service.png" alt="">
                  </div>
                  <div class="plan-description">
                    <ul class="plan-features">
                      <li class="border-top py-3"><span
                          class="material-icons">access_time_filled</span>{{translateArr.bp9}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">place</span>{{translateArr.bp4}}</li>
                      <li class="border-top py-3"><span class="material-icons">local_mall</span>{{translateArr.bp5}}
                      </li>
                      <li class="border-top py-3"><span class="material-icons">emoji_people</span>{{translateArr.bp7}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="modal fade p-4" id="payment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLongTitle">{{translateArr.bp13}}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{translateArr.bp14}}</p>
        <p><span class="material-icons">check_circle</span>{{translateArr.bp15}}</p>
        <p><span class="material-icons">check_circle</span>{{translateArr.bp16}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-checkout" data-dismiss="modal">{{translateArr.bp17}}</button>
      </div>
    </div>
  </div>
</div> -->

<section class="section service-area overflow-hidden">
  <div class="image-container">
    <img
      src="https://storage.onindonesia.id/ondelivery.id/our-service/thumbnail ondelivery express.png"
      alt="ONDELIVERY Express"
      class="background-image"
      loading="lazy"
    />
    <div class="text-overlay">
      <h2 class="text-white text-title">ONDELIVERY Express</h2>
      <h3 class="text-white text-subtitle">
        Pilih Layanan Pengiriman Yang Tepat Untuk Kamu
      </h3>
      <div class="button-custom-padding">
        <a href="/cost-estimation">
          <button mat-raised-button color="primary">Learn More</button>
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <app-scrollup></app-scrollup>
    <div style="padding: 50px 0" class="text-center">
      <h2>Jenis Pengiriman Kami</h2>
      <h4>
        Menyediakan layanan pengiriman barang, dokumen, dan makanan hingga
        mencakup seluruh Indonesia, dari penjemputan hingga pengantaran tujuan.
      </h4>
    </div>

    <div class="card-service-container">
      <mat-card
        class="col-sm-12 col-md-6 col-lg-6 p-0"
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
      >
        <div style="display: flex; flex-direction: row">
          <div class="column col-md-4 col-lg-4 p-0">
            <div
              style="
                background: #1254a0;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 10px;
              "
            >
              <h4
                class="text-center align-items-center text-white"
                style="padding: 10px"
              >
                24 jam Sampai*
              </h4>
            </div>

            <div class="p-0" style="border-radius: 18px">
              <img
                class="image-icon-responsive"
                loading="lazy"
                src="https://storage.onindonesia.id/ondelivery.id/our-service/Frame.png"
                alt=""
              />
            </div>
          </div>

          <div class="column col-md-8 col-lg-8">
            <h4 style="visibility: hidden">24 jam Sampai</h4>
            <h2>NEXT DAY</h2>
            <h3>Kirim Hari ini, Besok Sampai</h3>
            <h4>
              Layanan kirim barang yang hanya membutuhkan waktu 1x24 jam, barang
              kamu sudah sampai ke tujuan. Harga mulai dari Rp 10.000/kg
            </h4>
            <h3 class="text-color-primary">Dalam Kota & Kota Besar Tertentu</h3>
          </div>
        </div>
      </mat-card>

      <mat-card
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        class="col-sm-12 col-md-6 col-lg-6 p-0"
      >
        <div style="display: flex; flex-direction: row">
          <div class="column col-md-4 col-lg-4 p-0">
            <div
              style="
                background: #1254a0;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 10px;
              "
            >
              <h4
                class="text-center align-items-center text-white"
                style="padding: 10px"
              >
                2-4 Hari Sampai*
              </h4>
            </div>

            <div class="p-0" style="border-radius: 18px">
              <img
                class="image-icon-responsive"
                loading="lazy"
                src="https://storage.onindonesia.id/ondelivery.id/our-service/Frame.png"
                alt=""
              />
            </div>
          </div>

          <div class="column col-md-8 col-lg-8">
            <h4 style="visibility: hidden">24 jam Sampai</h4>
            <h2>REGULER</h2>
            <h3>Kirim Cepat, Harga Makin Hemat</h3>
            <h4>Layanan paling hemat mulai Rp 5.000 dan tiba dalam 2-4 hari</h4>
            <h3 class="text-color-primary">Nasional</h3>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="card-service-container">
      <mat-card
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        class="col-sm-12 col-md-6 col-lg-6 p-0"
      >
        <div style="display: flex; flex-direction: row">
          <div class="column col-md-4 col-lg-4 p-0">
            <div
              style="
                background: #1254a0;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 10px;
              "
            >
              <h4
                class="text-center align-items-center text-white"
                style="padding: 10px"
              >
                7 - 30 hari*
              </h4>
            </div>

            <div class="p-0" style="border-radius: 18px">
              <img
                class="image-icon-responsive"
                loading="lazy"
                src="https://storage.onindonesia.id/ondelivery.id/our-service/Frame.png"
                alt=""
              />
            </div>
          </div>

          <div class="column col-md-8 col-lg-8">
            <h4 style="visibility: hidden">7 - 30 hari*</h4>
            <h2>CARGO</h2>
            <h3>Makin Berat Makin Hemat</h3>
            <h4>
              Layanan paling hemat sekaligus gratis biaya jemput. Bisa kirim ke
              seluruh Indonesia harga mulai Rp 2.000 aja.
            </h4>
            <h3 class="text-color-primary">Nasional</h3>
          </div>
        </div>
      </mat-card>

      <mat-card
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        class="col-sm-12 col-md-6 col-lg-6 p-0"
      >
        <div style="display: flex; flex-direction: row">
          <div class="column col-md-4 col-lg-4 p-0">
            <div
              style="
                background: #1254a0;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 10px;
              "
            >
              <h4
                class="text-center align-items-center text-white"
                style="padding: 10px"
              >
                6-9 Jam
              </h4>
            </div>

            <div class="p-0" style="border-radius: 18px">
              <img
                style="width: 100%; height: 100%"
                loading="lazy"
                src="https://storage.onindonesia.id/ondelivery.id/our-service/Frame.png"
                alt=""
              />
            </div>
          </div>

          <div class="column col-md-8 col-lg-8">
            <h4 style="visibility: hidden">6-9 Jam</h4>
            <h2>SAMEDAY</h2>
            <h3>Anti Drama, Sameday Solusinya</h3>
            <h4>
              Layanan SAT SET SAT SET dalam kota kilat sampainya. Harga mulai Rp
              4.000 aja, Rp 20.000/5kg.
            </h4>
            <h3 class="text-color-primary">Dalam Kota</h3>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="card-service-container">
      <mat-card
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        class="col-sm-12 col-md-6 col-lg-6 p-0"
      >
        <div style="display: flex; flex-direction: row">
          <div class="column col-md-4 col-lg-4 p-0">
            <div
              style="
                background: #1254a0;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 10px;
              "
            >
              <h4
                class="text-center align-items-center text-white"
                style="padding: 10px"
              >
                6-9 Jam
              </h4>
            </div>

            <div class="p-0" style="border-radius: 18px">
              <img
                class="image-icon-responsive"
                loading="lazy"
                src="https://storage.onindonesia.id/ondelivery.id/our-service/Frame.png"
                alt=""
              />
            </div>
          </div>

          <div class="column col-md-8 col-lg-8">
            <h4 style="visibility: hidden">24 jam Sampai</h4>
            <h2>FROZEN FOOD SAMEDAY</h2>
            <h3>Tetap Segar Sampai Tujuan</h3>
            <h4>
              Layanan kirim Frozen Food enggak pake ribet. Harga mulai Rp 4.000
              -an aja, Rp 20.000/5kg
            </h4>
            <h3 class="text-color-primary">Nasional</h3>
          </div>
        </div>
      </mat-card>
    </div>
    <app-benefit-component></app-benefit-component>
    <section class="container" style="padding: 50px 0">
      <div class="card-section">
        <div class="image-content">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/about-us/logo-banner-download-kami.png"
            alt="OnApps Banner"
            class="image-responsive"
            loading="lazy"
          />
        </div>
        <div class="description-content">
          <div style="padding: 40px 10px">
            <div class="first-paragraph">
              Ayo, Mulai Pengirimanmu dari Sekarang
            </div>
            <div class="second-paragraph">
              Semua dapat memulai pengiriman layaknya profesional dengan
              ONDELIVERY. Tanpa perlu pengalaman. Tanpa harus bingung. Tinggal
              terima beres.
            </div>
            <div class="button-download">
              <div class="btn-download">
                <a
                  href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
                  target="_blank"
                >
                  <img
                    style="cursor: pointer"
                    src="https://storage.onindonesia.id/ondelivery.id/Badges-google-play.svg"
                    alt="Google Play"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://apps.apple.com/id/app/onapps-indonesia/id1587666667"
                  target="_blank"
                >
                  <img
                    style="cursor: pointer"
                    src="https://storage.onindonesia.id/ondelivery.id/Badges-app-store.svg"
                    alt="App Store"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div
      class="col-sm-12 col-md-12 col-lg-12 ptb_100"
      style="display: flex; justify-content: end"
    >
      <div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h2>The Benefit For You</h2>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h3>Lebih Hemat Semakin Cepat</h3>
        </div>
        <div
          class="row"
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <img
              style="width: 90px; width: 90px"
              src="../../../assets/img/our-service/sameday-min.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <img
              style="width: 90px; width: 90px"
              src="../../../assets/img/our-service/nextday-min.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <img
              style="width: 90px; width: 90px"
              src="../../../assets/img/our-service/REGULER-min.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <img
              style="width: 90px; width: 90px"
              src="../../../assets/img/our-service/frozen food-min.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
          <h3>Auto Pickup</h3>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h3>
            Paket di Pick-Up tanpa Minimum Order <br />
            Atau Setara Dengan 1KG, OnRider Auto Jemput <br />Kirimanmu
          </h3>
        </div>
      </div>
    </div> -->
  </div>
</section>
<app-footer-one></app-footer-one>
