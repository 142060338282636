<div class="p-4">
    <h3 class="contact-title pb-3">{{translateArr.bp2}}</h3>
    <form [formGroup]="AgentRegisterForm" (ngSubmit)="OnSubmit()">
        <div class="row">
            <div class="col-12 ">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user-alt"></i></span>
                        </div>
                        <input class="form-control" placeholder="{{translateArr.bp9}}" type="text"
                            [formControl]="AgentRegisterForm.controls['name']">
                    </div>
                    <mat-hint>
                        <small
                            *ngIf="AgentRegisterForm.controls['name'].hasError('required') && AgentRegisterForm.controls['name'].touched"
                            class="text-danger support-text">{{translateArr.bp3}}</small>
                    </mat-hint>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-envelope-open"></i></span>
                        </div>
                        <input class="form-control" placeholder="{{translateArr.bp10}}" type="email"
                            [formControl]="AgentRegisterForm.controls['email']">
                    </div>
                    <mat-hint>
                        <small
                            *ngIf="AgentRegisterForm.controls['email'].hasError('required') && AgentRegisterForm.controls['email'].touched"
                            class="text-danger support-text">{{translateArr.bp4}}</small>
                        <small
                            *ngIf="AgentRegisterForm.controls['email'].invalid && AgentRegisterForm.controls['email'].touched && !AgentRegisterForm.controls['email'].hasError('required')"
                            class="text-danger support-text">{{translateArr.bp5}}</small>
                    </mat-hint>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-phone"></i></span>
                        </div>
                        <input class="form-control" placeholder="{{translateArr.bp11}}" type="text"
                            [formControl]="AgentRegisterForm.controls['phone_number']">
                    </div>
                    <mat-hint>
                        <small
                            *ngIf="AgentRegisterForm.controls['phone_number'].hasError('required') && AgentRegisterForm.controls['phone_number'].touched"
                            class="text-danger support-text">{{translateArr.bp6}}</small>
                        <small
                            *ngIf="AgentRegisterForm.controls['phone_number'].invalid && AgentRegisterForm.controls['phone_number'].touched && !AgentRegisterForm.controls['phone_number'].hasError('required')"
                            class="text-danger support-text">{{translateArr.bp7}}</small>
                    </mat-hint>
                </div>
            </div>
            <div class="col-12">
                <button color="primary" class="login-button btn btn-lg mt-3 w-100" type="submit">{{translateArr.bp8}}</button>
            </div>
        </div>
    </form>
</div>