import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService, Waybill } from "src/app/api.service";
import dayjs from "dayjs";
import translationLib from "./tracking-translate.json";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Router } from "@angular/router";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
      },
    },
  ],
})
export class TrackingComponent implements OnInit {
  waybillForm: FormGroup;
  dataSourceWaybillBasic: any;
  displayedColumnsWaybillBasic: string[] = [
    "index",
    "waybill_number",
    "service",
    "consignee_district",
    "consignee_name",
    "received_at",
    "status",
    "action",
  ];
  waybillBasicTableDisplay: boolean = false;
  detailWaybillDisplay: boolean = false;
  detailWaybillData: any;
  waybillDetailDataSource: any = {
    waybill_number: null,
    service: null,
    sender_name: null,
    sender_province: null,
    consignee_name: null,
    consignee_province: null,
    consignee_district: null,
    date_of_shipment: null,
    status: null,
    search_tracks: {},
  };
  loadingMode: boolean = false;
  isLinear = false;

  translateArr = translationLib[localStorage.getItem("translate")];

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private routes: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.waybillForm = this.fb.group({
      waybill: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern("[a-zA-Z0-9-_,\n]*"),
        ]),
      ],
    });
    this.adjustFormWidth();
    // console.log(this.waybillForm)

    this.translateArr = translationLib[localStorage.getItem("translate")];
  }

  ngAfterContentInit(): void {
    if (window.history.state.waybill != undefined) {
      this.waybillForm.controls["waybill"].setValue(
        window.history.state.waybill
      );
      this.traceWaybillBasic();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.adjustFormWidth();
  }

  private adjustFormWidth(): void {
    const formElement = this.el.nativeElement.querySelector("form");
    if (window.innerWidth <= 1366) {
      this.renderer.removeClass(formElement, "col-sm-8");
      this.renderer.addClass(formElement, "col-sm-10");
    } else {
      this.renderer.removeClass(formElement, "col-sm-10");
      this.renderer.addClass(formElement, "col-sm-8");
    }
  }

  traceWaybillBasic() {
    if (!this.waybillForm.valid) {
      this.waybillForm.markAllAsTouched();
      return;
    }

    this.detailWaybillDisplay = false;
    this.waybillBasicTableDisplay = false;
    this.loadingMode = true;

    var query = this.waybillForm.value.waybill.split(/[,\n\s+]+/);
    var newquery = [];
    query.forEach((member) => {
      if (member.length > 5) {
        newquery.push(member);
      }
    });

    // console.log("payload query: ", newquery);

    const encodedQuery = btoa(JSON.stringify(newquery));
    const url = this.routes
      .createUrlTree(["/track/detail"], {
        queryParams: { listWaybill: encodedQuery },
      })
      .toString();

    window.open(url, "_blank");
    // this.service.waybillBasic(newquery).subscribe(
    //   (res) => {
    //     var i = 1;
    //     res.forEach((item) => {
    //       item.index = i;
    //       i++;
    //       item.waybill_number = item.waybill_number;
    //       // console.log(item.waybill_number.substring(0,19))
    //     });

    //     // console.log(r)

    //     this.dataSourceWaybillBasic = res;

    //     // console.log(this.dataSourceWaybillBasic);

    //     this.loadingMode = false;
    //     this.waybillBasicTableDisplay = true;
    //   },
    //   (e) => {
    //     this.dataSourceWaybillBasic = [{ index: "Not Found" }];
    //     this.loadingMode = false;
    //     this.waybillBasicTableDisplay = true;
    //   }
    // );
  }

  traceWaybillDetaill(index) {
    this.waybillDetailDataSource = this.dataSourceWaybillBasic[index];

    // console.log(this.waybillDetailDataSource.tracking_element)

    this.loadingMode = false;
    this.detailWaybillDisplay = true;
  }

  formatDate(date: any) {
    return dayjs(date).format("DD-MM-YYYY - HH:mm WIB");
  }
}
