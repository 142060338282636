<div class="row pt_50">
  <div class="row" style="gap: 25px; margin: 5px auto">
    <div class="card col-12 col-md-6 col-lg-6 my-3 res-margin">
      <!-- Image Box -->
      <div
        class="image-box text-left icon-1 p-5 wow fadeInLeft"
        data-aos-duration="2s"
        data-wow-delay="0.4s"
      >
        <!-- Featured Image -->
        <div class="mb-3">
          <div class="row">
            <img
              class="avatar-sm"
              src="https://storage.onindonesia.id/ondelivery.id/mail2.svg"
              alt=""
              loading="lazy"
            />
            <span class="custom-text-title-description ml-2">Email (24/7)</span>
          </div>
        </div>
        <!-- Icon Text -->
        <div class="icon-text">
          <span class="custom-text-description" style="text-align: justify">
            Email Partner di marketing&#64;ondelivery.id untuk informasi lebih
            lanjut tentang produk B2B Logistik
          </span>
        </div>
      </div>
    </div>
    <div class="card col-12 col-md-6 col-lg-6 my-3 res-margin">
      <!-- Image Box -->
      <div
        class="image-box text-left icon-1 p-5 wow fadeInUp"
        data-aos-duration="2s"
        data-wow-delay="0.2s"
      >
        <!-- Featured Image -->
        <div class="mb-3">
          <div class="row">
            <img
              class="avatar-sm"
              src="https://storage.onindonesia.id/ondelivery.id/phone2.svg"
              alt=""
              loading="lazy"
            />
            <span class="custom-text-title-description ml-2"
              >Customer Service</span
            >
          </div>
        </div>
        <!-- Icon Text -->
        <div class="icon-text">
          <span class="custom-text-description" style="text-align: justify">
            Hubungi Customer Service di nomor (021) 8063 0065 (Senin–Jumat pada
            09.00–18.00 WIB, kecuali hari libur).
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
