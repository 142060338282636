<div class="card">
  <form
    [formGroup]="basicProfileForm"
    (ngSubmit)="OnSubmit()"
    autocomplete="off"
  >
    <div class="contact-top">
      <h3 class="contact-title m-0">{{ translateArr.bp1 }}</h3>
      <h5 class="text-secondary fw-3 py-3">{{ translateArr.bp2 }}</h5>
    </div>
    <div>
      <div
        class="d-flex flex-row text-gray flex-wrap py-3 justify-content-center"
      >
        <div
          class="row col-lg-5 col-md-5 justify-content-center pb-3 text-center"
        >
          <div class="col-12 mb-3">
            <img
              class="mat-card-avatar"
              [src]="imageUrl"
              (error)="useDefaultProfileImg()"
              loading="lazy"
            />
          </div>
          <div class="col-12">
            <button type="button" class="btn-avatar" (click)="Image.click()">
              <input
                type="file"
                (change)="handleFileInput($event.target.files)"
                accept="image/*"
                #Image
                formControlName="profile_img"
              />
              <label style="cursor: pointer; margin: 0px">
                {{ translateArr.bp3 }}
              </label>
            </button>
          </div>
          <small class="small">{{ translateArr.bp4 }}</small>
        </div>

        <div class="row col-lg-7 col-md-7 justify-content-start pb-3 text-left">
          <div class="col-12 d-flex flex-row flex-wrap profile-list">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp5 }}
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 text-muted">
              <mat-form-field appearance="outline" style="pointer-events: none">
                <input
                  matInput
                  type="text"
                  value="{{ userinfo.username }}"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 d-flex flex-row flex-wrap profile-list">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp6 }}
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 text-muted">
              <mat-form-field appearance="outline" style="pointer-events: none">
                <input
                  matInput
                  type="text"
                  value="{{ userinfo.first_name }} {{ userinfo.last_name }}"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 d-flex flex-row flex-wrap profile-list">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp7 }}
            </div>
            <div class="col-8 col-md-8 col-sm-12 text-muted">
              <mat-form-field appearance="outline" style="pointer-events: none">
                <input
                  matInput
                  type="text"
                  value="{{ userinfo.email }}"
                  readonly
                  class="gray"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 d-flex flex-row flex-wrap profile-list">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp8 }}
            </div>
            <div class="col-8">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [matDatepicker]="picker"
                  type="text"
                  value="{{ userinfo.date_of_birth }}"
                  formControlName="date_of_birth"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                  style="color: gray"
                >
                </mat-datepicker-toggle>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 d-flex flex-row profile-list flex-wrap">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp9 }}
            </div>
            <div class="col-sm-8">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  value="{{ userinfo.phone_number }}"
                  formControlName="phone_number"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 d-flex flex-row flex-wrap profile-list">
            <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
              {{ translateArr.bp10 }}
            </div>
            <div class="col-8">
              <mat-form-field appearance="outline">
                <mat-select matInput formControlName="gender">
                  <mat-option *ngFor="let gender of genders" [value]="gender">
                    {{ gender }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="pt-3 d-flex row col-12 justify-content-end">
            <button type="submit" class="btn">{{ translateArr.bp11 }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
