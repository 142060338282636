<div class="d-flex row justify-content-between px-3">
    <h3 mat-dialog-title>{{translateArr.bp10}}</h3>
    <div class="d-flex ">
        <a mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </a>
    </div>
</div>
<hr/>
<div class="mat-typography d-flex flex-row pt-3 flex-wrap">
    <div class="col-lg-8 col-md-12 mb-3">
        <div #mapRef style="width:100%;height:400px"></div>
    </div>
    <div class="col-lg-4 col-md-12">
        <div>
            <h3>{{local_data.locationName}}</h3>
            <p class="pb-4">{{local_data.city}}</p>
            <p>{{local_data.address}}</p>
            <p><i class="fas fa-phone-alt mr-2"></i> {{local_data.phoneNumber}}</p>

        </div>
        <a mat-raised-button href="{{local_data.mapLinks}}" target="_blank" class="btn btn-lg mt-4 text-white">{{translateArr.bp11}}</a>
    </div>
</div>