<div class="main2">
  <div class="video-container">
    <video autoplay muted loop id="myVideo">
      <source
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/Overlay Screen Video Background.webm"
        type="video/mp4"
      />
      Your browser does not support HTML5 video.
    </video>
  </div>
  <section class="fixed-text">
    <div class="text-title">
      <h2>Events</h2>
    </div>
  </section>
  <div class="content">
    <app-navigation-list-bar></app-navigation-list-bar>
  </div>
</div>
