<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="line-spin-clockwise-fade"
  [fullScreen]="true"
>
  <p style="color: white">Mengirim Berkas...</p>
</ngx-spinner>

<div class="partner-page">
  <div class="main">
    <!-- <app-scrollup></app-scrollup> -->
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section
      style="background-color: #ffffff; display: flex; flex-direction: column"
      class="flex align-items-center justify-content-center"
    >
      <img
        loading="lazy"
        src="https://storage.onindonesia.id/ondelivery.id/bahan-Landing-Page-Join Us.png"
        alt="Banner of Agent Inquiry"
      />
    </section>

    <section
      #panel
      style="text-align: center; margin-top: 30px; margin-bottom: 10px"
    >
      <p
        class="blue-txt"
        style="
          color: #007bff;
          font-size: 30px;
          font-weight: 700;
          margin-top: 7px;
        "
      >
        {{ translateArr.bp1 }}
      </p>
      <p class="dark-grey-txt" style="font-size: 15px">
        {{ translateArr.bp2 }}
      </p>
    </section>

    <section class="partner-area bg-white" style="text-align: center">
      <div class="doc-row">
        <div class="doc-picker">
          <!-- SELFIE -->
          <div class="doc-column">
            <div class="row">
              <div class="text-con">
                <p>Upload Selfie</p>
              </div>
              <div class="doc-con">
                <div class="doc-con-row">
                  <div
                    #photo1
                    class="photo"
                    style="display: flex; flex-direction: column"
                  >
                    <div class="upload-btn-wrapper" *ngIf="!fileUploaded[0]">
                      <button class="picker" (click)="openDialog(0)">
                        <mat-icon> add_circle </mat-icon>
                        <p style="margin: 0">Upload</p>
                      </button>
                      <input
                        style="visibility: hidden"
                        type="file"
                        name="myfile"
                        #fileInput1
                        (change)="onFileSelected($event, 0, false)"
                        accept=".jpg, .jpeg, .png, .pdf"
                      />
                    </div>
                    <img
                      *ngIf="selectedImage[0]"
                      [src]="selectedImage[0]"
                      alt="Preview"
                      style="max-height: 100%; z-index: 0"
                    />
                  </div>
                  <div
                    class="control-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <button
                      class="delete_btn"
                      type="submit"
                      (click)="previewFile(0)"
                      style="
                        outline: 0 !important;
                        background-color: #007bff;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">remove_red_eye</mat-icon>
                      <div class="tooltip">
                        <span class="viewtext">view text</span>
                      </div>
                    </button>
                    <button
                      class="view_btn"
                      type="submit"
                      (click)="deleteFile(0)"
                      style="
                        outline: 0 !important;
                        background-color: #fb3b1e;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">close</mat-icon>
                      <div class="tooltip">
                        <span class="deletetext">delete text</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  #ket1
                  class="keterangan"
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 350px;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <!-- <span>{{selectedImage[0]}}</span> -->
                  <span>Status : {{ status[0] }}</span>
                  <span>File:{{ fileName[0] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="doc-picker">
          <!-- SELFIE -->
          <div class="doc-column">
            <div class="row">
              <div class="text-con">
                <p>Scan STNK</p>
              </div>
              <div class="doc-con">
                <div class="doc-con-row">
                  <div
                    #photo2
                    class="photo"
                    style="display: flex; flex-direction: column"
                  >
                    <div class="upload-btn-wrapper" *ngIf="!fileUploaded[1]">
                      <button class="picker" (click)="openDialog(1)">
                        <mat-icon> add_circle </mat-icon>
                        <p style="margin: 0">Upload</p>
                      </button>
                      <input
                        style="visibility: hidden"
                        type="file"
                        name="myfile"
                        #fileInput2
                        (change)="onFileSelected($event, 1, false)"
                        accept=".jpg, .jpeg, .png, .pdf"
                      />
                    </div>
                    <img
                      *ngIf="selectedImage[1]"
                      [src]="selectedImage[1]"
                      alt="Preview"
                      style="max-height: 100%; z-index: 0"
                    />
                  </div>
                  <div
                    class="control-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <button
                      class="delete_btn"
                      type="submit"
                      (click)="previewFile(1)"
                      style="
                        outline: 0 !important;
                        background-color: #007bff;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">remove_red_eye</mat-icon>
                      <div class="tooltip">
                        <span class="viewtext">view text</span>
                      </div>
                    </button>
                    <button
                      class="view_btn"
                      type="submit"
                      (click)="deleteFile(1)"
                      style="
                        outline: 0 !important;
                        background-color: #fb3b1e;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">close</mat-icon>
                      <div class="tooltip">
                        <span class="deletetext">delete text</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  #ket2
                  class="keterangan"
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 350px;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <!-- <span>{{selectedImage[0]}}</span> -->
                  <span>Status : {{ status[1] }}</span>
                  <span>File:{{ fileName[1] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="doc-picker">
          <!-- SELFIE -->
          <div class="doc-column">
            <div class="row">
              <div class="text-con">
                <p>Foto Motor</p>
              </div>
              <div class="doc-con">
                <div class="doc-con-row">
                  <div
                    #photo3
                    class="photo"
                    style="display: flex; flex-direction: column"
                  >
                    <div class="upload-btn-wrapper" *ngIf="!fileUploaded[2]">
                      <button class="picker" (click)="openDialog(2)">
                        <mat-icon> add_circle </mat-icon>
                        <p style="margin: 0">Upload</p>
                      </button>
                      <input
                        style="visibility: hidden"
                        type="file"
                        name="myfile"
                        #fileInput3
                        (change)="onFileSelected($event, 2, false)"
                        accept=".jpg, .jpeg, .png, .pdf"
                      />
                    </div>
                    <img
                      *ngIf="selectedImage[2]"
                      [src]="selectedImage[2]"
                      alt="Preview"
                      style="max-height: 100%; z-index: 0"
                    />
                  </div>
                  <div
                    class="control-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <button
                      class="delete_btn"
                      type="submit"
                      (click)="previewFile(2)"
                      style="
                        outline: 0 !important;
                        background-color: #007bff;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">remove_red_eye</mat-icon>
                      <div class="tooltip">
                        <span class="viewtext">view text</span>
                      </div>
                    </button>
                    <button
                      class="view_btn"
                      type="submit"
                      (click)="deleteFile(2)"
                      style="
                        outline: 0 !important;
                        background-color: #fb3b1e;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">close</mat-icon>
                      <div class="tooltip">
                        <span class="deletetext">delete text</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  #ket3
                  class="keterangan"
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 350px;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <!-- <span>{{selectedImage[0]}}</span> -->
                  <span>Status : {{ status[2] }}</span>
                  <span>File:{{ fileName[2] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="doc-picker">
          <!-- SELFIE -->
          <div class="doc-column">
            <div class="row">
              <div class="text-con">
                <p>Scan KTP</p>
              </div>
              <div class="doc-con">
                <div class="doc-con-row">
                  <div
                    #photo4
                    class="photo"
                    style="display: flex; flex-direction: column"
                  >
                    <div class="upload-btn-wrapper" *ngIf="!fileUploaded[3]">
                      <button class="picker" (click)="openDialog(3)">
                        <mat-icon> add_circle </mat-icon>
                        <p style="margin: 0">Upload</p>
                      </button>
                      <input
                        style="visibility: hidden"
                        type="file"
                        name="myfile"
                        #fileInput4
                        (change)="onFileSelected($event, 3, false)"
                        accept=".jpg, .jpeg, .png, .pdf"
                      />
                    </div>
                    <img
                      *ngIf="selectedImage[3]"
                      [src]="selectedImage[3]"
                      alt="Preview"
                      style="max-height: 100%; z-index: 0"
                    />
                  </div>
                  <div
                    class="control-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <button
                      class="delete_btn"
                      type="submit"
                      (click)="previewFile(3)"
                      style="
                        outline: 0 !important;
                        background-color: #007bff;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">remove_red_eye</mat-icon>
                      <div class="tooltip">
                        <span class="viewtext">view text</span>
                      </div>
                    </button>
                    <button
                      class="view_btn"
                      type="submit"
                      (click)="deleteFile(3)"
                      style="
                        outline: 0 !important;
                        background-color: #fb3b1e;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">close</mat-icon>
                      <div class="tooltip">
                        <span class="deletetext">delete text</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  #ket4
                  class="keterangan"
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 350px;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <!-- <span>{{selectedImage[0]}}</span> -->
                  <span>Status : {{ status[3] }}</span>
                  <span>File:{{ fileName[3] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="doc-picker">
          <!-- SELFIE -->
          <div class="doc-column">
            <div class="row">
              <div class="text-con">
                <p>Scan SIM</p>
              </div>

              <div class="doc-con">
                <div class="doc-con-row">
                  <div
                    #photo5
                    class="photo"
                    style="display: flex; flex-direction: column"
                  >
                    <div class="upload-btn-wrapper" *ngIf="!fileUploaded[4]">
                      <button class="picker" (click)="openDialog(4)">
                        <mat-icon> add_circle </mat-icon>
                        <p style="margin: 0">Upload</p>
                      </button>
                      <input
                        style="visibility: hidden"
                        type="file"
                        name="myfile"
                        #fileInput5
                        (change)="onFileSelected($event, 4, false)"
                        accept=".jpg, .jpeg, .png, .pdf"
                      />
                    </div>
                    <img
                      *ngIf="selectedImage[4]"
                      [src]="selectedImage[4]"
                      alt="Preview"
                      style="max-height: 100%; z-index: 0"
                    />
                  </div>
                  <div
                    class="control-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <button
                      class="delete_btn"
                      type="submit"
                      (click)="previewFile(4)"
                      style="
                        outline: 0 !important;
                        background-color: #007bff;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">remove_red_eye</mat-icon>
                      <div class="tooltip">
                        <span class="viewtext">view text</span>
                      </div>
                    </button>
                    <button
                      class="view_btn"
                      type="submit"
                      (click)="deleteFile(4)"
                      style="
                        outline: 0 !important;
                        background-color: #fb3b1e;
                        margin: 5px;
                        height: 24px;
                        width: 40px;
                        border-radius: 5px;
                        border: none;
                      "
                    >
                      <mat-icon style="color: white">close</mat-icon>
                      <div class="tooltip">
                        <span class="deletetext">delete text</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  #ket5
                  class="keterangan"
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 350px;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <!-- <span>{{selectedImage[0]}}</span> -->
                  <span>Status : {{ status[4] }}</span>
                  <span>File:{{ fileName[4] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="doc-submit">
          <div class="confirm-row">
            <mat-checkbox [(ngModel)]="isChecked"> </mat-checkbox>
            <p>
              Dengan menyelesaikan pendaftaran, maka saya setuju untuk mengikuti
              syarat dan ketentuan yang berlaku sesuai dengan
              <a
                style="color: blue"
                href="https://ondelivery.id/assets/doc/Persyaratan%20Keagenan%20OPA%20-%20OMA.pdf"
                >dokumen ini</a
              >.
            </p>
          </div>
          <div class="button-row">
            <button class="btn button" type="submit" (click)="refresh()">
              Reset
            </button>
            <button class="btn button" type="submit" (click)="confirmData()">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>
