<div class="card">
    <h3 class="contact-title m-0">{{translateArr.bp1}}</h3>
    <!-- <h5 class="text-secondary fw-3 py-3">Isi semua kolom agar kami dapat memperoleh info tentang Anda. Kami tidak
        akan mengirimi Anda spam</h5> -->

    <h5 class="text-secondary fw-3 py-3">{{translateArr.bp2}} </h5>
    <!-- <form id="contact-form">
        <div class="row">
            <div class="col-12 ">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user-alt"></i></span>
                        </div>
                        <input class="form-control" placeholder="Nama" required>
                    </div>
                    
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-envelope-open"></i></span>
                        </div>
                        <input type="email" placeholder="Email" class="form-control" required>
                    </div>
                    
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="No Hp" type="text" required>
                    </div>
                </div>
                <div class="form-group form-check pt-3">
                    <input class="checkbox-effect checkbox-effect-1" id="get-up-1" type="checkbox" value="get-up-1" name="get-up-1"/>
                    <label for="get-up-1">By signing up, you accept our <a href="/terms-and-conditions" style="color: #13AAD4;">Terms</a>
                        &amp; <a href="/privacy-policy" style="color: #13AAD4;">Privacy Policy</a></label>
                </div>
            </div>
            <div class="col-12">
                <button color="primary" class="login-button btn btn-lg mt-3 w-100" type="submit">Daftar</button>
            </div>
        </div>
    </form> -->
</div>