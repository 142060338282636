<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <app-breadcrumb-information></app-breadcrumb-information>
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section class="section faq-area ptb_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-7">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-capitalize">
                {{ translateArr.bp1 }} - ONDELIVERY
              </h2>
              <p class="d-none d-sm-block mt-4">{{ translateArr.bp2 }}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-5">
          <div class="col-12">
            <!-- FAQ Content -->
            <div class="faq-content">
              <!-- sApp Accordion -->
              <div class="accordion" id="sApp-accordion">
                <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-8">
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp3 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <ul>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp4 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp5 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp6 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp7 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp8 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp9 }}
                            </p>
                            <p class="indent">
                              <span class="material-icons list">done</span>
                              {{ translateArr.bp10 }}
                            </p>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp11 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <ul>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp12 }}
                            </p>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp13 }}
                            </p>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp14 }}
                            </p>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp15 }}
                            </p>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp16 }}
                            </p>
                            <p>
                              <span class="material-icons list"
                                >local_shipping</span
                              >
                              {{ translateArr.bp17 }}
                            </p>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp18 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>
                            {{ translateArr.bp19 }}
                            <a
                              href="https://ondelivery.id/"
                              style="color: #13aad4"
                              >www.ondelivery.id</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp20 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp21 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp22 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp23 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse6"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp24 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse6"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp25 }}</p>
                          <p>{{ translateArr.bp26 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse7"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp27 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse7"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <div>
                            <p>{{ translateArr.bp30 }}</p>
                            <p class="pl-2">{{ translateArr.bp31 }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse8"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp32 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse8"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp33 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse9"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp34 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse9"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp35 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse10"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp36 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse10"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>
                            {{ translateArr.bp37 }}
                            <a
                              href="https://ondelivery.id/"
                              style="color: #13aad4"
                              >www.ondelivery.id</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse11"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp38 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse11"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp39 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse12"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp40 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse12"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp41 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse13"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp42 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse13"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp43 }}</p>
                          <p>{{ translateArr.bp44 }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Single Accordion Item -->
                    <div
                      class="card border-top-0 border-left-0 border-right-0 border-bottom"
                    >
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button
                            class="btn collapsed px-0 py-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse14"
                          >
                            <span class="material-icons question-mark"
                              >contact_support</span
                            >{{ translateArr.bp45 }}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse14"
                        class="collapse"
                        data-parent="#sApp-accordion"
                      >
                        <!-- Card Body -->
                        <div class="card-body px-0 py-3 px-3">
                          <p>{{ translateArr.bp46 }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>
