import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/api.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from "rxjs/operators";
import blackpink from "./agent-inquiries-translate.json";

@Component({
  selector: "app-agent-inquiries",
  templateUrl: "./agent-inquiries.component.html",
  styleUrls: ["./agent-inquiries.component.scss"],
  providers: [ApiService],
})
export class AgentInquiriesComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  msg = "";
  form: FormGroup;
  errorRegister: string | null = null;
  translate = localStorage.getItem("translate");
  submitted: boolean = false;
  submissionFailed: boolean = false;
  agentTypes = [
    {
      value: "Perorangan",
      viewValue: this.translateArr.bp21,
    },
    {
      value: "Perusahaan",
      viewValue: this.translateArr.bp22,
    },
  ];
  selectedAgentType = "";
  locationTypes = [
    {
      value: "Ruko",
      viewValue: this.translateArr.bp23,
    },
    {
      value: "Mall/Pasar",
      viewValue: this.translateArr.bp24,
    },
    {
      value: "Perkantoran",
      viewValue: this.translateArr.bp25,
    },
    {
      value: "Perumahan",
      viewValue: this.translateArr.bp26,
    },
  ];
  selectedLocationType = "";
  buildingStatus = [
    {
      value: "Sewa",
      viewValue: this.translateArr.bp27,
    },
    {
      value: "Milik Sendiri",
      viewValue: this.translateArr.bp28,
    },
  ];
  selectedBuildingStatus = "";
  destinationSearchData: any = [];
  constructor(
    private service: ApiService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      full_name: [null, Validators.compose([Validators.required])],
      agent_type: [null, Validators.compose([Validators.required])],
      phone: [
        null,
        Validators.compose([
          Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"),
          Validators.required,
        ]),
      ],
      email: [
        null,
        Validators.compose([
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          Validators.required,
        ]),
      ],
      location_type: [null, Validators.compose([Validators.required])],
      building_status: [null, Validators.compose([Validators.required])],
      full_address: [null, Validators.compose([Validators.required])],
      location_coordinate: [null],
      osas_location: [null, Validators.compose([Validators.required])],
    });

    //////////////////Autocomplete Destination Start//////////////////
    this.destinationSearchData = this.form
      .get("osas_location")
      ?.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(""),
        switchMap((value) => this.destination_serv(value))
      );
    //////////////////Autocomplete Destination End//////////////////

    this.translateArr = blackpink[localStorage.getItem("translate")];
    this.translate = localStorage.getItem("translate");
  }

  ////////////////////////////////Autocomplete Start////////////////////////////////
  private destination_serv(value: string): any {
    return this.service
      .destinationSearchNew(value)
      .pipe(catchError((err) => of([])));
  }
  displayFnDest(consignee?: any): string | undefined {
    return consignee
      ? consignee.urban_name +
          ", " +
          consignee.sub_district_name +
          ", " +
          consignee.city_name +
          ", Prov. " +
          consignee.province_name +
          ", " +
          consignee.postal_code
      : undefined;
  }
  ////////////////////////////////Autocomplete End////////////////////////////////

  addInquiry() {
    const full_name = this.form.value.full_name;
    const agent_type = this.form.value.agent_type;
    const phone = this.form.value.phone;
    const email = this.form.value.email;
    const location_type = this.form.value.location_type;
    const building_status = this.form.value.building_status;
    const full_address = this.form.value.full_address;
    var location_coordinate;
    if (this.form.value.location_coordinate !== null) {
      if (
        this.form.value.location_coordinate.startsWith("https://goo.gl/maps/")
      ) {
        location_coordinate = {
          latitude: null,
          longitude: null,
          google_link: this.form.value.location_coordinate,
        };
      } else {
        var coordinate = this.form.value.location_coordinate.split(",");
        location_coordinate = {
          latitude: coordinate[0],
          longitude: coordinate[1],
          google_link: null,
        };
      }
    }
    const osas_location = this.form.value.osas_location;

    this.service
      .createAgentInquiry(
        full_name,
        agent_type,
        phone,
        email,
        location_type,
        building_status,
        full_address,
        location_coordinate,
        osas_location
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.submitted = true;
        },
        (e) => {
          // console.log(e)
          this.submissionFailed = true;
          this.errorRegister = "SERVICE_ERROR";
        }
      );
    this.refresh();
  }
  // refresh() {
  // this.router
  //   .navigateByUrl("/", { skipLocationChange: true })
  //     .then(() => this.router.navigate(["agent-inquiries"]));
  // }
  refresh() {
    setTimeout(() => {
      this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => this.router.navigate(["agent-inquiries"]));
    }, 3000);
  }

  downloadTermsAndCondition() {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "https://storage.onindonesia.id/ondelivery.id/bahan-Landing-Page-02.png"
    );
    link.setAttribute("download", `Agent_Terms_and_Conditions.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
