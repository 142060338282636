import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import dayjs from 'dayjs';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";
import {Title} from "@angular/platform-browser";
import { MatSnackBar } from '@angular/material/snack-bar';
@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NewsDetailComponent implements OnInit {

  blogDetails: any
  id: any
  params: ParamMap
  previewImage: any;
  content: string
  url:string
  durationInSeconds = 2;
  isLoading: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ApiService,
    private sanitized: DomSanitizer,
    private titleService:Title,
    private _snackBar: MatSnackBar
  ) {
    this.route.params.subscribe(e => {
      this.fetchDetails(e.type)
      // console.log(e.type)
    })
    
  }

  fetchDetails(id){
    this.isLoading = true
    this.service.blogDetail(id).subscribe(data => {
      // console.log(data)

      this.blogDetails = data
      this.titleService.setTitle(data.title + " | ONDELIVERY");
      // console.log(data.image)
      // console.log(data.category)
      this.content = data.content
      // console.log(data.content)
    }, e => {
      console.log(e)  
    })
  }

  ngOnInit(): void {
  }

  formatDate(date: any) {
    return dayjs(date).format('DD MMMM YYYY')
  }

  openSnackBar() {
    this._snackBar.open("Copied to clipboard", "OK", {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snack-bar']
    });
  }

}
