<div class="card">
  <h3 class="contact-title m-0">{{translateArr.bp1}}</h3>
  <h5 class="text-secondary fw-3 py-3">{{translateArr.bp2}}</h5>

  <div style="overflow-x:auto;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

      <ng-container matColumnDef="waybill_number">
        <th mat-header-cell *matHeaderCellDef> {{translateArr.bp3}} </th>
        <td mat-cell *matCellDef="let element"> {{element.waybill_number}} </td>
      </ng-container>

      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef> {{translateArr.bp4}} </th>
        <td mat-cell *matCellDef="let element"> {{element.invoice}} </td>
      </ng-container>

      <ng-container matColumnDef="booking_number">
        <th mat-header-cell *matHeaderCellDef> {{translateArr.bp5}} </th>
        <td mat-cell *matCellDef="let element"> {{element.booking_number}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{translateArr.bp6}} </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.created_at)}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Aksi </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex flex-row wrap">
            <button type="submit" class="btn btn-lg"
              (click)="traceWaybill(element.waybill_number)">{{element.status}}</button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsHistory"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsHistory"></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="isLoading">
          <div class="text-center col-12 w-100">
            <mat-spinner style="margin:0 auto;"></mat-spinner>
            <h4>{{translateArr.bp7}}</h4>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">

          <td class="mat-cell p-4" style="color: gray;" colspan="9999">
            No Data
          </td>
        </ng-container>
      </tr>

    </table>

  </div>

  <mat-paginator [pageSize]="bookingTable.page_size" [length]="totalData"
    (page)="onPaginateChange($event)"></mat-paginator>

</div>