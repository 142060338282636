<div class="main">
  <!-- <app-scrollup></app-scrollup> -->
  <!-- <app-main-header></app-main-header> -->
  <!-- <app-breadcrumb-track></app-breadcrumb-track> -->
  <!-- <app-whatsapp-corner></app-whatsapp-corner> -->

  <section style="margin-top: 10vh">
    <div style="display: flex; justify-content: center">
      <h2>Temukan Drop Point</h2>
    </div>
    <div class="row maps">
      <div class="col-md-12">
        <div class="card mt-3">
          <div class="card-body">
            <div id="map-container" style="position: relative">
              <div #mapRef class="map-custom"></div>
              <div id="search-container">
                <input
                  id="pac-input"
                  class="controls"
                  type="text"
                  placeholder=" Masukkan nama kecamatan atau kota"
                />
                <button id="search-button" onclick="performSearch()">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-navigation-list-bar></app-navigation-list-bar>
  <!-- <section id="blog" class="section blog-area ptb_100">
      <div class="container h-100 w-100">
        <div fxLayout="row wrap">
          <div fxFlex="100">
            <h3>{{ translateArr.bp1 }}</h3>
            <p class="mb-4 text-left">{{ translateArr.bp2 }}</p>
            <mat-form-field appearance="outline" class="example-full-width">
              <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="{{ translateArr.bp3 }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap">
          <div fxFlex="100">
            <div class="table-responsive" style="overflow-x: auto">
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="locationId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp4 }}</mat-header-cell
                  >
                  <mat-cell *matCellDef="let i = index">
                    {{
                      this.paginator.pageIndex == 0
                        ? i + 1
                        : 1 +
                          i +
                          this.paginator.pageIndex * this.paginator.pageSize
                    }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="city">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp5 }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.city }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="locationName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp6 }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.locationName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="locationSide">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp7 }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.locationSide }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp8 }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="text-left">
                    {{ element.address }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ translateArr.bp9 }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="d-flex flex-row wrap">
                      <a
                        mat-button
                        (click)="openDialog(element)"
                        class="btn-action"
                      >
                        <span class="material-icons">
                          <img src="/assets/img/marker2.png" alt="" />
                        </span>
                      </a>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell p-4" style="color: gray" colspan="9999">
                    No data found ...
                  </td>
                </tr>
              </mat-table>

              <mat-paginator
                [pageSizeOptions]="[5, 10, 15, 20]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one> -->
</div>
