import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { regionOptions, existValidation } from "../../../utility.service";
import { CostResultDialogComponent } from "./cost-result-dialog/cost-result-dialog.component";

import { MatSort } from "@angular/material/sort";
import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "src/app/api.service";
// import dataTracking from '../dummy-data/data.json'
// import { WaybillService } from '../services/waybill.service';
import blackpink from "./cost-translate.json";

@Component({
  selector: "app-cost",
  templateUrl: "./cost.component.html",
  styleUrls: ["./cost.component.scss"],
})
export class CostComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit() {

  }

  ////////////////////////////////Autocomplete End////////////////////////////////
}
