import { Component, OnInit } from '@angular/core';
import blackpink from './register-user-translate.json'

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
