<section class="section service-area overflow-hidden">
  <div class="image-container ptb_150">
    <img
      src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/banner-vip.png"
      alt="ONDELIVERY Logistics"
      class="background-image"
      loading="lazy"
    />

    <div class="text-overlay">
      <h1 class="text-white">VIP Cust</h1>
      <h3 class="text-white">
        Bersinergi untuk Mewujudkan <br />
        Pertumbuhan Bisnis bersama ONDELIVERY
      </h3>
      <div class="button-custom-padding">
        <a href="/vip-customer">
          <button mat-raised-button color="primary">Daftar</button>
        </a>
      </div>
    </div>
  </div>
  <div class="container">
    <app-scrollup></app-scrollup>

    <!-- <div
      class="d-flex row col-sm-12 col-lg-12 col-md-12"
      style="padding-bottom: 50px"
    >
      <div
        class="col-sm-6 col-md-4 col-lg-6 column"
        style="display: flex; align-items: center"
      >
        <div class="row" style="padding: 10px 20px">
          <h2>
            Bersinergi untuk Mewujudkan Pertumbuhan Bisnis bersama ONDELIVERY
          </h2>
          <a href="/vip-customer">
            <button
              mat-flat-button
              style="
                color: white;
                background: #1254a0;
                border-radius: 8px;
                padding: 8px 15px;
              "
            >
              Daftar
            </button>
          </a>
        </div>
      </div>

      <div class="col-sm-6 col-md-8 col-lg-6">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIP dashboard-min.png"
          style="width: 100%; height: 100%; border-radius: 8px"
          loading="lazy"
          alt=""
        />
      </div>
    </div> -->
    <div class="d-flex row col-sm-12 col-lg-12 col-md-12 padding-desc-custom">
      <h2>Kemitraan VIP Customer Bagi Korporasi dan Socio Seller</h2>
      <h4>
        ONDELIVERY siap membantu perusahaan dan socio seller di seluruh
        Indonesia yang membutuhkan layanan pengiriman via kurir, trucking,
        hingga menjadi Partner bisnis mulai dari perencanaan hingga last mile
        menggunakan berbagai tipe armada, dilengkapi dengan dashboard management
        VIP dan biaya pengiriman yang aman, cepat, dan hemat
        #LebihHematSemakinCepat. <br />Terdapat opsi COD dan Non COD tanpa
        marketplace yang langsung ditangani oleh ONDELIVERY. Membuat proses
        pengiriman barang lebih efektif dan efisien.
      </h4>
    </div>

    <div class="d-flex row col-sm-12 col-lg-12 col-md-12 ptb_100">
      <div class="col-sm-6 col-md-8 col-lg-6" style="padding: 10px 30px">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIP dashboard-min.png"
          style="width: 100%; height: 100%; border-radius: 8px"
          loading="lazy"
          alt=""
        />
      </div>
      <div
        class="col-sm-6 col-md-4 col-lg-6 column"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="row">
          <h1>Dashboard VIP</h1>
        </div>
      </div>
    </div>

    <div class="d-flex row col-sm-12 col-lg-12 col-md-12 pt-5">
      <div
        class="col-sm-6 col-md-4 col-lg-6 column"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="row">
          <h1>Integrasi Dashboard</h1>
        </div>
      </div>

      <div class="col-sm-6 col-md-8 col-lg-6">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/Integrasi API.png"
          style="width: 100%; height: 100%; border-radius: 8px"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <div class="ptb_100">
      <div class="row col-sm-12 col-lg-12 col-md-12 m-auto">
        <div class="d-flex justify-content-center col-12">
          <h2>Keuntungan Bergabung Menjadi VIP Customer</h2>
        </div>
        <div class="row col-sm-12 col-lg-12 col-md-12" style="padding: 50px 0">
          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Diskon Spesial</h3>
                <h4>Biaya kirim terhemat dibanding yang lain</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer-2.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Tim Berdedikasi</h3>
                <h4>
                  Tim yang berdedikasi untuk memantau setiap kiriman paket
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer-4.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Pasti di Pick Up</h3>
                <h4>
                  Fasilitas GRATIS Pick Up yang fleksibel sesuai kebutuhan
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer-1.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Cashback & Reward</h3>
                <h4>
                  Semakin besar jumlah kiriman, Maka kamu berkesempatan
                  mendapatkan cashback dan reward menarik
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer-3.svg"
                  alt=""
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Bisa COD</h3>
                <h4>Sistem COD menambah potensi penjualan yang lebih tinggi</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom">
            <div style="display: flex; flex-direction: row">
              <div class="col-sm-4 col-md-4 col-lg-2">
                <img
                  src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VIPCustomer-5.svg"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div class="pl-3 col-sm-8 col-md-6 col-lg-10">
                <h3>Realtime Tracking</h3>
                <h4>Kemudahan pelacakan kiriman melalui website ONDELIVERY</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center col-12"
      style="padding-bottom: 50px"
    >
      <h2>Syarat Mudah Menjadi VIP Customer</h2>
    </div>
    <div class="d-flex row">
      <div
        class="col-sm-4 col-md-4 col-lg-4 flex-column spacer-top-bottom"
        style="padding: 0 50px"
      >
        <div class="d-flex justify-content-center">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/ISI-FORMULIR-ICON.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="d-flex justify-content-center pt-4">
          <h3>Isi Formulir</h3>
        </div>
        <div class="text-center">
          <h4>
            Mengisi form kerjasama bisnis sebagai perusahaan atau bisa langsung
            menghubungi kontak bisnis OnDelivery
          </h4>
        </div>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-4 flex-column spacer-top-bottom"
        style="padding: 0 50px"
      >
        <div class="d-flex justify-content-center">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/VERIFIKASI-FORMULIR-ICON.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="d-flex justify-content-center pt-4">
          <h3>Verifikasi Kerjasama</h3>
        </div>
        <div class="text-center">
          <h4>Tim Sales ONDELIVERY akan menindaklanjuti permintaan Anda</h4>
        </div>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-4 flex-column spacer-top-bottom"
        style="padding: 0 50px"
      >
        <div class="d-flex justify-content-center">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/vip-customer-info/PARTNER-FORMULIR-ICON.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="d-flex justify-content-center pt-4">
          <h3 style="text-align: center">Konfirmasi Kerjasama</h3>
        </div>
        <div class="text-center">
          <h4>
            Melengkapi keperluan administrasi seperti KTP, NPWP, dan dokument
            lain jika diperlukan
          </h4>
        </div>
      </div>
    </div>
    <div class="row col-sm-12 col-lg-12 col-md-12 m-auto ptb_100">
      <div class="d-flex justify-content-center col-12">
        <h2>VIP Customer yang Telah Mempercayai Kami</h2>
      </div>
      <div class="row">
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content">
            <ng-container *ngFor="let image of imagesPartners">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Brand Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content">
            <ng-container *ngFor="let image of imagesPartners2">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Brand Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <section class="center-content" style="padding: 50px 0">
      <div class="card-section">
        <div class="description-content">
          <div class="padding-description">
            <div class="first-paragraph">Mau Menjadi Seperti Mereka?</div>
            <div class="second-paragraph">
              Ayo Gabung Customer VIP ONDELIVERY Sekarang!
              <span style="visibility: hidden"
                >Semua dapat memulai pengiriman layaknya .</span
              >
            </div>
            <div style="padding-top: 20px">
              <a href="/vip-customer">
                <button
                  mat-flat-button
                  style="
                    color: white;
                    background: #1254a0;
                    border-radius: 8px;
                    padding: 8px 15px;
                  "
                >
                  Gabung Sekarang
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="image-content">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/about-us/logo-banner-download-kami.png"
            alt="OnApps Banner"
            class="image-responsive"
            loading="lazy"
          />
        </div>
      </div>
    </section>
    <div></div>
  </div>
</section>
<app-footer-one></app-footer-one>
