<div class="blog" appNoRightClick>
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-main-header></app-main-header>
        <app-breadcrumb-training></app-breadcrumb-training>
        <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
        <section class="section faq-area ptb_100 bg-gray">
            <div class="container">
                <div class="blog-thumb">
                </div>
                <div class="meta-info d-flex flex-wrap align-items-center py-3">
                    <ul>
                        <li class="d-inline-block p-2"><a class="blue fw-6"> {{ trainingDetails ? trainingDetails.title
                                : ''}}</a> | ONDELIVERY | {{trainingDetails ? formatDate(trainingDetails.createdAt) :
                            ''}}</li>
                    </ul>
                </div>
                <hr class="m-0" />
                <div class="row justify-content-center pt-5">
                    <div class="col-12 col-md-10 col-lg-10">
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">{{ trainingDetails ? trainingDetails.title : ''}}</h2>
                            <p class="pt-4" style="font-weight: 600;">{{ trainingDetails ? trainingDetails.target : ''}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-lg-4 mt-sm-0 py-3 card">
                    <div class="col-12">
                        <div class="faq-content">
                            <div class="accordion" id="sApp-accordion">
                                <div class="row justify-content-center">
                                    <div class="col-12 col-md-10 col-lg-11"
                                        *ngIf="trainingDetails?.youtube_url != null">
                                        <iframe *ngIf="trainingDetails?.youtube_url != null" ngif width="100%"
                                            height="480" [src]="trainingDetails?.youtube_url | safeUrl"
                                            title="YouTube video player"></iframe>
                                    </div>
                                    <div *ngIf="trainingDetails?.video.url != null" class="col-12 col-md-10 col-lg-11">
                                        <vg-player *ngIf="trainingDetails?.video.url != null">
                                            <vg-buffering></vg-buffering>
                                            <vg-controls [vgAutohide]="true" [vgAutohideTime]="5">
                                                <vg-play-pause></vg-play-pause>
                                                <vg-volume></vg-volume>
                                            </vg-controls>
                                            <video class="video-thumb" #media [vgMedia]="$any(media)"
                                                [src]="trainingDetails?.video.url" id="singleVideo" preload="auto">
                                            </video>
                                        </vg-player>
                                    </div>
                                    <div class="col-12 col-md-10 col-lg-11">
                                        <div class="test-iframe">
                                            <iframe loading="lazy" style="width: 100%; height: 576px;"
                                                [src]="trainingDetails?.ppt_url | safeUrl">
                                            </iframe>
                                            <div class="hide-bar"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>