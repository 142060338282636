<div class="pt_100">
  <section class="container center-content" style="padding-top: 50px">
    <div class="card-section">
      <div class="image-content">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/about-us/logo-banner-download-kami.png"
          alt="OnApps Banner"
          class="image-responsive"
          loading="lazy"
        />
      </div>
      <div class="description-content">
        <div class="padding-description">
          <div class="first-paragraph">
            Ayo, Mulai Pengirimanmu dari Sekarang
          </div>
          <div class="second-paragraph">
            Semua dapat memulai pengiriman layaknya profesional dengan
            ONDELIVERY. Tanpa perlu pengalaman. Tanpa harus bingung. Tinggal
            terima beres.
          </div>
          <div class="button-download">
            <div class="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
                target="_blank"
              >
                <img
                  style="cursor: pointer"
                  src="https://storage.onindonesia.id/ondelivery.id/Badges-google-play.svg"
                  alt="Google Play"
                  loading="lazy"
                />
              </a>
              <a
                href="https://apps.apple.com/id/app/onapps-indonesia/id1587666667"
                target="_blank"
              >
                <img
                  style="cursor: pointer"
                  src="https://storage.onindonesia.id/ondelivery.id/Badges-app-store.svg"
                  alt="App Store"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-navigation-list-bar></app-navigation-list-bar>
