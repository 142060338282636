import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";
import blackpink from "./create-booking-dialog-translate.json";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-create-booking-dialog",
  templateUrl: "./create-booking-dialog.component.html",
  styleUrls: ["./create-booking-dialog.component.css"],
})

export class CreateBookingDialogComponent implements OnInit {
  bookingData: any[] = [];
  bookingInfo: any[] = [];
  translateArr = blackpink[localStorage.getItem("translate")];

  destination_id: any;
  destination_idPengirim: any;
  service_id: any;
  sender_name: string;
  sender_phone: string;
  sender_address: string;
  receiver_name: string;
  receiver_phone: string;
  receiver_address: string;
  goods_id: any;
  goods_desc: string;
  goods_weight: any;
  length: number;
  width: number;
  height: number;
  notes_instructions: string;
  insurance_exist: boolean;
  goods_value: any;
  freight_charge: any;
  service: any;
  sender_pre_address: any;
  consignee_name: any;
  consignee_address: any;
  description: any;
  insChecked: any;
  consignee_phone: any;
  goods_desc_remark: any;
  metode_pengiriman: any;
  phone_number: string;

  oneReceiver: boolean; s

  constructor(
    private apiService: ApiService,
    private routes: Router,
    private spinner: NgxSpinnerService
  ) { }
  @ViewChild("panel") public panel: ElementRef;

  ngOnInit(): void {

    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);

    this.translateArr = blackpink[localStorage.getItem("translate")];

    if (this.apiService.bookingData !== undefined) {
      this.bookingData = this.apiService.bookingData;
      if (this.bookingData.length === 1) {
        this.oneReceiver = true
      } else {
        this.oneReceiver = false
      }
    } else {
      this.routes.navigate(["/user/create-book"]);
    }
  }

  async orderNow() {
    this.spinner.show();


    for (let i = 0; i < this.bookingData.length; i++) {
      this.bookingInfo[i] = {
        package_info: [
          {
            destination_id: this.bookingData[i].destination_id,
            origin_id: this.bookingData[i].origin_id,
            service_id: this.bookingData[i].service_id,

            sender_name: this.bookingData[i].sender_name,
            sender_phone: this.bookingData[i].sender_phone.toString(),
            sender_address: this.bookingData[i].sender_address,

            receiver_name: this.bookingData[i].receiver_name,
            receiver_phone: this.bookingData[i].receiver_phone.toString(),
            receiver_address: this.bookingData[i].receiver_address,

            goods_id: this.bookingData[i].goods_id,
            goods_desc: this.bookingData[i].goods_desc,
            goods_weight: parseInt(this.bookingData[i].goods_weight),
            koli: this.bookingData[i].koli,
            length: parseInt(this.bookingData[i].length),
            width: parseInt(this.bookingData[i].width),
            height: parseInt(this.bookingData[i].height),
            notes: this.bookingData[i].notes,
            insurance_exist: this.bookingData[i].insurance_exist,
            goods_value: parseInt(this.bookingData[i].goods_value),
            estimate_price: this.bookingData[i].estimate_price,
            // voucher:"",
            // packing_fee:0,
            // destination_city:0
          }
        ],
        mode: this.bookingData[i].mode,
        relation_mode: "1",
        phone_number: this.bookingData[i].sender_phone.toString(),
        pickup_loc: {
          lat: this.bookingData[i].lat,
          long: this.bookingData[i].long,
          full_address: this.bookingData[i].sender_address,
          // urban_id: 0,
          // sub_district_id: 0,
          // city_id: this.bookingData.city_id_sender,
        },
        // department: 'ONDELIVERY'
      };
    }

    // console.log(this.bookingInfo);

    this.makeRequest();
  }

  async makeRequest() {


    let successBookingCount = 0;
    let failedBookingCount = 0;


    for (let i = 0; i < this.bookingInfo.length; i++) {
      this.spinner.show();
      try {
        await this.apiService.createBooking(this.bookingInfo[i]).toPromise();
        successBookingCount++;
      } catch (e) {
        failedBookingCount++;
      }
    }

    await this.spinner.hide();

    if (failedBookingCount === 0) {
      const result = await Swal.fire({
        title: "Succes!",
        text: `Semua booking berhasil dikirim!,\n 
          Total pesanan berhasil : ${successBookingCount},\n
          Silahkan cek pesanan anda.`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "success",
      });

      if (result.isConfirmed || result.isDismissed) {
        setTimeout(() => {
          this.panel.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        });
        this.routes.navigate(["/user/order"]);
      }
    } else {
      const result = await Swal.fire({
        title: "Warning!",
        text: `Beberapa booking gagal!,\n
          Jumlah pesanan berhasil : ${successBookingCount},\n
          Jumlah pesanan gagal : ${failedBookingCount},\n
          Silahkan cek pesanan anda.`
        ,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "warning",
      });

      if (result.isConfirmed || result.isDismissed) {
        setTimeout(() => {
          this.panel.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        });
        this.routes.navigate(["/user/order"]);
      }
    }
  }

  cancel() {
    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    });
    this.routes.navigate(["/user/create-book"]);
  }

  toString(value) {
    value.toString();
    return value;
  }
}
