import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessNewPasswordComponent } from './success-new-password/success-new-password.component';
import { FailedNewPasswordComponent } from './failed-new-password/failed-new-password.component'
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import blackpink from './new-password-translate.json'

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})

export class NewPasswordComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  dialogRef: any
  validLink:boolean;
  token:string;
  form: FormGroup
  
  constructor(public dialog: MatDialog, private api:ApiService, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, private routes: Router) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('key')
    this.api.verifyLinkResetPwd(this.token).subscribe((r) => {
      this.validLink = true

      this.form = this.fb.group({
        password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
        confirmPassword: [null, Validators.compose([Validators.required])]
      }, {validators: sameValueValidation});

    }, (e) => {
      this.validLink = false
    })

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  NewPassword() {
    this.api.resetPwd(this.form.value.password, this.token).subscribe((r) => {
      this.dialogRef = this.dialog.open(SuccessNewPasswordComponent)
      this.dialogRef.afterClosed().subscribe(() => {
        this.routes.navigate(['/'])
      })
    }, (e) => {
      this.dialogRef = this.dialog.open(FailedNewPasswordComponent)
    })
    
  }
}

//Custom Validation
function sameValueValidation(form: FormGroup): {[key: string]: boolean } | null {
  return (form.value.password == form.value.confirmPassword)?  null : {'samevalue': true}
}

