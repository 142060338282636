<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <app-breadcrumb-profile></app-breadcrumb-profile>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3">
            <aside class="sidebar">
              <div class="single-widget">
                <div class="accordions widget post-widget" id="post-accordion">
                  <div class="single-accordion">
                    <h5>
                      <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse"
                        href="#accordion2">{{ translateArr.bp1 }} {{ loggedinUser }}
                      </a>
                    </h5>
                    <div id="accordion2" class="accordion-content widget-content collapse show"
                      data-parent="#post-accordion">
                      <ul class="widget-items">
                        <li>
                          <a routerLink="profile" routerLinkActive="active"
                            class="single-post align-items-center align-items-lg-start media p-3">
                            <div class="post-content media-body pl-3">
                              <p class="m-0">
                                <span class="material-icons pr-3">person</span>{{ translateArr.bp2 }}
                              </p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a routerLink="change-password" routerLinkActive="active"
                            class="single-post align-items-center align-items-lg-start media p-3">
                            <div class="post-content media-body pl-3">
                              <p class="m-0">
                                <span class="material-icons pr-3">vpn_key</span>{{ translateArr.bp3 }}
                              </p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a routerLink="create-book" routerLinkActive="active"
                            class="single-post align-items-center align-items-lg-start media p-3">
                            <div class="post-content media-body pl-3">
                              <p class="m-0">
                                <span class="material-icons pr-3">add_shopping_cart</span>{{ translateArr.bp4 }}
                              </p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a routerLink="my-booking" routerLinkActive="active"
                            class="single-post align-items-center align-items-lg-start media p-3">
                            <div class="post-content media-body pl-3">
                              <p class="m-0">
                                <span class="material-icons pr-3">list_alt</span>{{ translateArr.bp5 }}
                              </p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <!-- <a routerLink="history" routerLinkActive="active"
                            class="single-post align-items-center align-items-lg-start media p-3">
                            <div class="post-content media-body pl-3">
                              <p class="m-0">
                                <span class="material-icons pr-3">history</span>{{ translateArr.bp6 }}
                              </p>
                            </div>
                          </a>
                        </li> -->
                          <!-- <li>
                                                    <a routerLink="agent" routerLinkActive="active" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <div class="post-content media-body pl-3">
                                                            <p class="m-0"><span class="material-icons pr-3">assignment_ind</span>{{translateArr.bp7}}</p>
                                                        </div>
                                                    </a>
                                                </li>   -->
                        <li>
                          <a (click)="logout()" class="single-post align-items-center align-items-lg-start media p-3"
                            style="cursor: pointer">
                            <div class="post-content media-body pl-3" style="color: red">
                              <span class="material-icons pr-3">logout</span>{{ translateArr.bp8 }}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <div class="col-12 col-lg-9">
            <article class="single-blog-details">
              <div class="blog-content App-blog">
                <!-- <div class="meta-info d-flex flex-wrap align-items-center py-2">
                                    <ul>
                                        <li class="d-inline-block p-2">By <a href="#">Anna Swford</a></li>
                                        <li class="d-inline-block p-2"><a href="#">05 Feb, 2018</a></li>
                                        <li class="d-inline-block p-2"><a href="#">2 Comments</a></li>
                                    </ul>
                                    <div class="blog-share ml-auto d-none d-sm-block">
                                        <div class="social-icons d-flex justify-content-center">
                                            <a class="bg-white facebook" href="#">
                                                <i class="fab fa-facebook-f"></i>
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a class="bg-white twitter" href="#">
                                                <i class="fab fa-twitter"></i>
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a class="bg-white google-plus" href="#">
                                                <i class="fab fa-google-plus-g"></i>
                                                <i class="fab fa-google-plus-g"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                <div class="blog-details">
                  <router-outlet></router-outlet>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>