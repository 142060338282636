<div class="contact-page">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section
      id="contact"
      class="contact-area bg-white ptb_100"
      style="text-align: center"
    >
      <div
        class="container"
        style="border: 1px solid black; border-radius: 10px"
      >
        <p
          class="blue-txt"
          style="font-size: 30px; font-weight: 700; margin-top: 7px"
        >
          {{ translateArr.bp1 }}
        </p>
        <p class="dark-grey-txt" style="font-size: 15px; margin-top: 17px">
          {{ translateArr.bp2 }}
        </p>
        <div class="parent">
          <!-- Contact Box -->
          <form id="contact-form">
            <div class="row">
              <div
                class="col-12"
                style="text-align: center; padding-top: 30px; width: 400px"
              >
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp3 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['full_name']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['full_name'].hasError('required') &&
                        form.controls['full_name'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp10 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp4 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      matInput
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['phone']"
                      maxlength="15"
                      numbersOnly
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['phone'].hasError('required') &&
                        form.controls['phone'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp11 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['phone'].invalid &&
                        form.controls['phone'].touched &&
                        !form.controls['phone'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp11 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp5 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      type="email"
                      class="form-control"
                      required
                      [formControl]="form.controls['email']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['email'].hasError('required') &&
                        form.controls['email'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp12 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['email'].invalid &&
                        form.controls['email'].touched &&
                        !form.controls['email'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp17 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp6 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['business_name']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['business_name'].hasError('required') &&
                        form.controls['business_name'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp13 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp7 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['product_sold']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['product_sold'].hasError('required') &&
                        form.controls['product_sold'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp14 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp8 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      [formControl]="form.controls['shipment_quantity']"
                      numbersOnly
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['shipment_quantity'].hasError(
                          'required'
                        ) && form.controls['shipment_quantity'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp15 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['shipment_quantity'].invalid &&
                        form.controls['shipment_quantity'].touched &&
                        !form.controls['shipment_quantity'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp15 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp9 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      [formControl]="form.controls['business_link']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['business_link'].hasError('required') &&
                        form.controls['business_link'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp16 }}</small
                    >
                  </mat-hint>
                </div>
              </div>
              <div
                class="col-12"
                style="text-align: center; padding-bottom: 42px"
              >
                <button
                  style="width: 150px !important"
                  mat-rised-button
                  class="login-button btn btn-xs w-100"
                  type="submit"
                  [disabled]="!form.valid"
                  (click)="addInquiry()"
                >
                  <div
                    class="row"
                    style="justify-content: center; align-items: center"
                  >
                    <span style="font-size: 14px">
                      {{ translateArr.bp18 }}
                    </span>
                    <span>
                      <mat-icon>arrow_forward</mat-icon>
                    </span>
                  </div>
                </button>

                <ng-container *ngIf="submitted">
                  <div class="col-12">
                    <span
                      class="d-block pt-2 mt-4 border-top dark-grey-txt"
                      style="text-align: center; font-size: 12px"
                      >{{ translateArr.bp19 }}</span
                    >
                    <span
                      class="d-block pt-2 blue-txt"
                      style="
                        text-align: center;
                        font-size: 20px;
                        font-weight: bold;
                      "
                      >{{ translateArr.bp20 }}</span
                    >
                  </div>
                </ng-container>
                <ng-container *ngIf="submissionFailed">
                  <div class="col-12">
                    <span
                      class="d-block pt-2 mt-4 border-top dark-grey-txt"
                      style="text-align: center"
                      >{{ translateArr.bp21 }}</span
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <div class="container p-0">
      <app-footer-one></app-footer-one>
    </div>
  </div>
</div>
