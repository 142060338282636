<div class="container-lock">
  <div class="menu">
    <div *ngIf="!isActive('/cost-estimation')" class="menu-item">
      <a href="/cost-estimation">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/home-asset/Shipping-rates.svg"
          alt="Shipping Rates"
        />
      </a>
      <span>SHIPPING RATES</span>
    </div>
    <div
      *ngIf="isActive('/cost-estimation')"
      class="menu-item"
      [class.active]="isActive('/cost-estimation')"
    >
      <img
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/Shipping-rates-active.svg"
        alt="Shipping Rates"
      />
      <span>SHIPPING RATES</span>
    </div>
    <div *ngIf="!isActive('/track')" class="menu-item">
      <a href="/track">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/home-asset/Tracking-zones.svg"
          alt="Tracking Zones"
        />
      </a>
      <span>TRACKING ZONES</span>
    </div>
    <div
      *ngIf="isActive('/track')"
      [class.active]="isActive('/track')"
      class="menu-item"
    >
      <img
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/Tracking-zones-active.svg"
        alt="Tracking Zones"
      />
      <span>TRACKING ZONES</span>
    </div>

    <div
      *ngIf="isActive('/drop-point')"
      [class.active]="isActive('/drop-point')"
      class="menu-item"
    >
      <img
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/Drop-point-active.svg"
        alt="Drop Point"
      />
      <span>TEMUKAN DROP POINT</span>
    </div>
    <div *ngIf="!isActive('/drop-point')" class="menu-item">
      <a href="/drop-point">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/home-asset/Drop-point.svg"
          alt="Temukan Drop Point"
        />
      </a>
      <span>TEMUKAN DROP POINT</span>
    </div>
    <div class="menu-item" *ngIf="!isActive('/events')">
      <a href="/events">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/home-asset/Events.svg"
          alt="Events"
        />
      </a>
      <span>EVENTS</span>
    </div>

    <div
      *ngIf="isActive('/events')"
      [class.active]="isActive('/events')"
      class="menu-item"
    >
      <img
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/Events-active.svg"
        alt="Events"
      />
      <span>EVENTS</span>
    </div>
    <div *ngIf="!isActive('/onapps')" class="menu-item">
      <a href="/onapps">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/home-asset/ONAPPS-logo.svg"
          alt="Onapps"
        />
      </a>
      <span>ONAPPS</span>
    </div>
    <div *ngIf="isActive('/onapps')" class="menu-item">
      <img
        src="https://storage.onindonesia.id/ondelivery.id/home-asset/ONAPPS-logo-active.svg"
        alt="Onapps"
      />
      <span>ONAPPS</span>
    </div>
  </div>
</div>
