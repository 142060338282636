import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Optional } from '@angular/core';
import blackpink from './failed-new-password-translate.json'

@Component({
  selector: 'app-failed-new-password',
  templateUrl: './failed-new-password.component.html',
  styleUrls: ['./failed-new-password.component.css']
})
export class FailedNewPasswordComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(public dialogRef: MatDialogRef<FailedNewPasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

    OK(): void{
      this.dialogRef.close()
    }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  
}
 