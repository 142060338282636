<section class="section blog-area ptb_100">
  <div class="container h-100">
    <div class="px-3">
      <div
        class="row ptb_50"
        *ngIf="waybillBasicTableDisplay"
        style="overflow-x: auto"
      >
        <table mat-table [dataSource]="dataSourceWaybillBasic">
          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp7 }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.index }}</td>
          </ng-container>

          <ng-container matColumnDef="waybill_number">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp8 }}
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- <a [routerLink]="" (click)="traceWaybillDetail(element.index -1)">{{element.waybill_number}}</a> -->
              <a
                [routerLink]=""
                (click)="traceWaybillDetaill(element.index - 1)"
                class="awb-number"
                >{{ element.waybill_number }}</a
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp9 }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.service }}
            </td>
          </ng-container>

          <ng-container matColumnDef="consignee_district">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp10 }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.destination }}
            </td>
          </ng-container>

          <ng-container matColumnDef="consignee_name">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp11 }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.consignee_name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="received_at">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp12 }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ formatDate(element.tanggal_kirim) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp13 }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.tracking_element[0].category ==
                "SHIPMENT STATUS
                  DELIVERED"
                  ? "DELIVERED"
                  : element.tracking_element[0].category
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ translateArr.bp21 }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div type="submit" class="btn">
                <a
                  style="color: #ffffff; font-size: 12px"
                  (click)="traceWaybillDetaill(element.index - 1)"
                  >{{ translateArr.bp22 }}</a
                >
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsWaybillBasic"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsWaybillBasic"
          ></tr>
        </table>
      </div>

      <div class="col ptb_50" style="text-align: center" *ngIf="loadingMode">
        <mat-spinner style="margin: 0 auto"></mat-spinner><br />
        <h4>{{ translateArr.bp14 }}</h4>
      </div>

      <div class="row">
        <div class="col">
          <div
            [ngbCollapse]="!detailWaybillDisplay"
            class="collapse multi-collapse"
            id="tracking-detail"
          >
            <div class="card p-4 p-lg-5 m-0">
              <div class="row">
                <div class="col-12 pb-2 awb-number">
                  {{ translateArr.bp15 }}
                  {{ waybillDetailDataSource.waybill_number }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-2 pb-2 bold">
                  {{ translateArr.bp16 }}
                </div>
                <div class="col pb-2">
                  {{ waybillDetailDataSource.sender_name }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-2 pb-2 bold">
                  {{ translateArr.bp17 }}
                </div>
                <div class="col pb-2">
                  {{ waybillDetailDataSource.sender_city }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-2 pb-2 bold">
                  {{ translateArr.bp18 }}
                </div>
                <div class="col pb-2">
                  {{ waybillDetailDataSource.consignee_name }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-2 pb-2 bold">
                  {{ translateArr.bp19 }}
                </div>
                <div class="col pb-2">
                  {{ waybillDetailDataSource.destination }}
                </div>
              </div>
            </div>

            <div class="card card-body p-3 mt-4">
              <div class="p-3">
                <mat-vertical-stepper [linear]="isLinear" #stepper>
                  <ng-template matStepperIcon="place">
                    <mat-icon>place</mat-icon>
                  </ng-template>
                  <mat-step
                    completed="true"
                    state="place"
                    label="{{ formatDate(historyItem.updated_at) }}"
                    *ngFor="
                      let historyItem of waybillDetailDataSource.tracking_element
                    "
                  >
                    {{ historyItem.status }}
                  </mat-step>
                </mat-vertical-stepper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
