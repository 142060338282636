<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <app-breadcrumb-information></app-breadcrumb-information>
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section class="section faq-area ptb_100 bg-gray">
      <div class="container">
        <div class="blog-thumb">
          <img src="{{ blogDetails.image.url }}" alt="" loading="lazy" />
        </div>
        <div class="meta-info d-flex flex-wrap align-items-center py-3">
          <ul>
            <li class="d-inline-block p-2">
              <a
                class="blue fw-6"
                href="/article/{{ blogDetails.category | lowercase }}"
              >
                {{ blogDetails.category | uppercase }}</a
              >
              | {{ blogDetails.dept }} |
              {{ formatDate(blogDetails.createdAt) }}
            </li>
          </ul>
          <div class="blog-share ml-auto d-none d-sm-block">
            <!-- Social Icons -->
            <div class="social-icons d-flex justify-content-center">
              <a
                class="bg-white facebook"
                href="https://www.facebook.com/sharer.php?u={{ url }}"
                target="_blank"
              >
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-facebook-f"></i>
              </a>
              <a class="bg-white twitter" href="#">
                <i class="fab fa-twitter"></i>
                <i class="fab fa-twitter"></i>
              </a>
              <a
                class="bg-white share"
                href="javascript:void(0)"
                [cdkCopyToClipboard]="url"
                (click)="openSnackBar()"
              >
                <i class="fab fas fa-share-alt"></i>
                <i class="fab fas fa-share-alt"></i>
              </a>
            </div>
          </div>
        </div>
        <hr class="m-0" />
        <div class="row justify-content-center pt-5">
          <div class="col-12 col-md-10 col-lg-10">
            <div class="section-heading text-center">
              <h2 class="text-capitalize">{{ blogDetails.title }}</h2>
              <p class="pt-4" style="font-weight: 600">
                {{ blogDetails.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-lg-4 mt-sm-0 py-3 card">
          <div class="col-12">
            <div class="faq-content">
              <div class="accordion" id="sApp-accordion">
                <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-11">
                    <div
                      class="blog-details py-4"
                      [innerHTML]="blogDetails.content | safeHtml"
                    >
                      <!-- <p class="py-3" align="justify"></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>
