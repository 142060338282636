<section class="section subscribe-area bg-gray ptb_50">
  <div class="container">
    <div class="row justify-content-center py-md-4">
      <div class="d-flex flex-row col-12">
        <!-- <div class="col-12 text-center">
                    <img src="https://storage.onindonesia.id/ondelivery.id/register.png" alt="">
                    <button (click)="openDialog()" class="button btn btn-lg" >DAFTAR</button>
                </div> -->
        <div class="img-wrapper">
          <img
            class="img-responsive"
            src="https://storage.onindonesia.id/ondelivery.id/design.png"
          />
          <div class="img-overlay">
            <button
              class="button btn btn-md btn-success"
              (click)="openDialog()"
            >
              {{ translateArr.bp1 }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
