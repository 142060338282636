import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import blackpink from './delete-booking.json'

@Component({
  selector: 'app-detail-booking',
  templateUrl: './detail-booking.component.html',
  styleUrls: ['./detail-booking.component.css']
})
export class DetailBookingComponent implements OnInit {

  isLoading: boolean;
  detailBooking: any;

  translateArr = blackpink[localStorage.getItem("translate")]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ApiService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]

    this.route.params.subscribe(e => {
      this.fetchDetails(e.type)
    })
    console.log(this.detailBooking)
  }

  fetchDetails(booking_id) {
    this.isLoading = true
    this.service.detailUserBooking(booking_id).subscribe(data => {
      // console.log(data)
      // console.log(data.status)
      switch (data.type) {
        case "WALK_IN":
          data.type = 'WALK IN'
          break;
        case "PICK_UP":
          data.type = 'PICK UP'
          break;
        default:
          break;
      }

      switch (data.status) {
        case "WAITING_AGENT_CONFIRMATION":
          data.status = 'Waiting Agent Confirmation'
          break;
        case "WAITING_PICKUP":
          data.status = 'Paket Menunggu Penjemputan'
          break;
        case "ON_PICKUP":
          data.status = 'Paket Sudah dalam Penjemputan'
          break;
        default:
          break;
      }

      // console.log(data.status)
      this.detailBooking = data

      this.isLoading = false

    }, e => {
      console.log(e)
    })
  }

  cancel(booking_id: string) {
    const dialogRef = this.dialog.open(DeleteBooking, {
      data: booking_id
    });
  }
}

@Component({
  selector: 'delete-booking',
  templateUrl: 'delete-booking.html',
  providers: [ApiService],
  styleUrls: ['./detail-booking.component.css']
})

export class DeleteBooking {
  local_data: any;
  translateArr = blackpink[localStorage.getItem("translate")]

  @ViewChild('panel') public panel: ElementRef;

  constructor(
    private service: ApiService,
    private routes: Router,
    public dialogRef: MatDialogRef<DeleteBooking>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = data;
    // console.log(this.local_data);
  }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  doAction(data: string) {
    this.service.cancelBooking(data).subscribe(data => {
      // console.log(data)
      // console.log("sukses")
      this.dialogRef.close({ event: 'Cancel', data: this.local_data });
      Swal.fire({
        title: "Sukses!",
        text: "Booking berhasil dihapus!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "success"
      })
      setTimeout(() => {
        this.panel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      });
      this.routes.navigate(['/user/order'])
    }, (e) => {
      // console.log(e)
      Swal.fire({
        title: "Gagal!",
        text: "Pesanan gagal dihapus",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "warning"
      })
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}