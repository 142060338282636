<div class="p-3" style="text-align: center">
  <h3 class="py-4">{{ translateArr.bp1 }}</h3>
  <div *ngIf="loadingMode">
    <mat-spinner style="margin: 0 auto; align-items: center"></mat-spinner
    ><br />
    <h4>{{ translateArr.bp2 }}</h4>
  </div>

  <ng-container *ngIf="!loadingMode">
    <div style="overflow-x: auto">
      <table mat-table [dataSource]="dataSourcePricing">
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp3 }}</th>
          <td mat-cell *matCellDef="let element">{{ element.index }}</td>
        </ng-container>

        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp4 }}</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp5 }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.price | currency : "Rp. " : "symbol" : ".0" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="eta">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp6 }}</th>
          <td mat-cell *matCellDef="let element">{{ element.eta }}</td>
        </ng-container>

        <ng-container matColumnDef="minKg">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp7 }}</th>
          <td mat-cell *matCellDef="let element">{{ element.minKg }}</td>
        </ng-container>

        <ng-container matColumnDef="maxKg">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp11 }}</th>
          <td mat-cell *matCellDef="let element">{{ element.maxKg }}</td>
        </ng-container>

        <ng-container matColumnDef="weightCalculated">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp13 }}</th>
          <td mat-cell *matCellDef="let element">{{ weightCount }}</td>
        </ng-container>

        <ng-container matColumnDef="totalKg">
          <th mat-header-cell *matHeaderCellDef>{{ translateArr.bp10 }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.total | currency : "Rp. " : "symbol" : ".0" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsPricing"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPricing"></tr>
      </table>
    </div>
    <br />
    <span class="text-left text-muted blockcss">{{ translateArr.bp8 }}</span>
    <span class="text-left text-muted blockcss">{{ translateArr.bp12 }}</span>
    <button
      class="btn btn-lg my-4 w-100"
      color="primary"
      (click)="closeDialog()"
    >
      {{ translateArr.bp9 }}
    </button>
  </ng-container>
</div>
