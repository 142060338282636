import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { regionOptions, existValidation } from "../../../../utility.service";
import { CostResultDialogComponent } from "../cost-result-dialog/cost-result-dialog.component";

import { MatSort } from "@angular/material/sort";
import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "src/app/api.service";
// import dataTracking from '../dummy-data/data.json'
// import { WaybillService } from '../services/waybill.service';
import blackpink from "../cost-translate.json";

@Component({
  selector: 'app-express',
  templateUrl: './express.component.html',
  styleUrls: ['./express.component.scss']
})
export class ExpressComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  regionOptions = regionOptions;
  form: FormGroup;
  originSearchData: any = [];
  destinationSearchData: any = [];
  dimensionCheck: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.form = this.fb.group(
      {
        origin: [null, Validators.compose([Validators.required])],
        dest: [null, Validators.compose([Validators.required])],
        weight: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        long: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        width: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        height: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        dimension: [false, Validators.compose([Validators.required])],
      },
      { validators: existValidation }
    );

    //////////////////Autocomplete Destination Start//////////////////
    this.originSearchData = this.form.get("origin")?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      startWith(""),
      switchMap((value) => this.origin_serv(value))
    );

    this.destinationSearchData = this.form.get("dest")?.valueChanges.pipe(
      // debounceTime(250),
      distinctUntilChanged(),
      startWith(""),
      switchMap((value) => this.destination_serv(value))
    );
    //////////////////Autocomplete Destination End//////////////////

    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  checkDimension(value): void {
    console.log(value);
    if (value.checked === true) {
      this.dimensionCheck = true;
    } else {
      this.dimensionCheck = false;
      this.form.get("long").setValue(1);
      this.form.get("width").setValue(1);
      this.form.get("height").setValue(1);
    }
  }

  checkPrice() {
    // console.log("asdasd")
    // console.log(this.destinationSearchData.subscribe(val => console.log(val)))
    // console.log("hi hi")

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    console.log(this.form.valid, "vald");

    const costResultDialog = this.dialogRef.open(CostResultDialogComponent, {
      data: this.form.value,
      width: "800px",
      height: "490px",
    });
  }

  ////////////////////////////////Autocomplete Start////////////////////////////////
  private origin_serv(value: string): any {
    return this.apiService
      .originSearchNew(value)
      .pipe(catchError((err) => of([])));
  }

  displayFnOrigin(sender?: any): string | undefined {
    return sender
      ? sender.urban_name +
      ", " +
      sender.sub_district_name +
      ", " +
      sender.city_name +
      ", Prov. " +
      sender.province_name +
      ", " +
      sender.postal_code
      : undefined;
  }

  // private destination_serv(value2: string): any {
  //     return this.apiService.destinationSearch(value2).pipe(catchError(err => of([])))
  // }

  // displayFnDest(consignee?: any): string | undefined {
  //     return consignee ? consignee.district_name : undefined;
  // }

  private destination_serv(value: string): any {
    return this.apiService
      .destinationSearchNew(value)
      .pipe(catchError((err) => of([])));
  }
  displayFnDest(consignee?: any): string | undefined {
    return consignee
      ? consignee.urban_name +
      ", " +
      consignee.sub_district_name +
      ", " +
      consignee.city_name +
      ", Prov. " +
      consignee.province_name +
      ", " +
      consignee.postal_code
      : undefined;
  }
  /////////////////////

}
