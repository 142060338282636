import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { ApiService } from "src/app/api.service";
import { QueryTable } from "src/app/driver.model";
import { MatSort } from "@angular/material/sort";
import blackpink from "./news-promos-translate.json";

declare var $: any;
@Component({
  selector: "app-news-promos",
  templateUrl: "./news-promos.component.html",
  styleUrls: ["./news-promos.component.scss"],
})
export class NewsPromosComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  news: any;

  @ViewChild(MatPaginator) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild(MatSort) sort: MatSort = Object.create(null);

  searchText: any;
  displayedColumns: string[] = ["recipient"];

  dataSource = new MatTableDataSource();

  title = "angular-material-tab-router";
  navLinks: any[];

  newsHighlightIndex: number = 0;

  newNews: any = [];
  isNewBlogLoading: boolean = true;

  newsList: any = [];
  activeLinkIndex = -1;
  type: string = "";
  isLoading: boolean = true;
  // params: ParamMap
  totalData: number = 0;
  form: FormGroup;
  filename: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.navLinks = this.navLinkGen;
    this.route.params.subscribe((e) => {
      this.type = e.type;
      if (!(this.type === "all")) {
        if (
          this.queryTable.filters.findIndex((e) => e.field === "category") ===
          -1
        ) {
          this.queryTable.filters.push({
            field: "category",
            keyword: this.type.toUpperCase(),
          });
        } else {
          this.queryTable.filters[
            this.queryTable.filters.findIndex((e) => e.field === "category")
          ] = {
            field: "category",
            keyword: this.type.toUpperCase(),
          };
        }
      } else {
        if (
          this.queryTable.filters.findIndex((e) => e.field === "category") !==
          -1
        ) {
          this.queryTable.filters.splice(
            this.queryTable.filters.findIndex((e) => e.field === "category"),
            1
          );
        }
      }
      this.fetchBlogs();
    });
  }
  get navLinkGen() {
    if (localStorage.getItem("translate") === "idn") {
      return [
        {
          label: "All News",
          link: ["/article", "all"],
          index: 0,
        },
        {
          label: "Promo",
          link: ["/article", "promo"],
          index: 1,
        },
        {
          label: "News",
          link: ["/article", "news"],
          index: 2,
        },
      ];
    }
    if (localStorage.getItem("translate") === "eng") {
      return [
        {
          label: "ALL ARTICLES",
          link: ["/article", "all"],
          index: 0,
        },
        {
          label: "PROMO",
          link: ["/article", "promo"],
          index: 1,
        },
        {
          label: "NEWS",
          link: ["/article", "news"],
          index: 2,
        },
      ];
    }
    if (localStorage.getItem("translate") === "cn") {
      return [
        {
          label: "所有文章",
          link: ["/article", "all"],
          index: 0,
        },
        {
          label: "促销",
          link: ["/article", "Promo"],
          index: 1,
        },
        {
          label: "新闻",
          link: ["/article", "News"],
          index: 2,
        },
      ];
    }
  }
  queryTable: QueryTable = {
    sort: {
      field: "publishedAt",
      order: "descend",
    },
    filters: [
      {
        field: "dept",
        keyword: "ONDELIVERY",
      },
    ],
    pageSize: 6,
    pageIndex: 0,
  };

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(
        this.navLinks.find((tab) => tab.link === "." + this.router.url)
      );
    });

    this.translateArr = blackpink[localStorage.getItem("translate")];
    this.fetchNewBlogs();

    setInterval(() => {
      this.newsHighlightIndex++;
      if (this.newsHighlightIndex >= this.newNews.length)
        this.newsHighlightIndex = 0;
      // console.log(this.newsHighlightIndex)
    }, 3500);
  }

  resetPageIndex() {
    this.queryTable.pageIndex = 0;
  }

  fetchBlogs() {
    this.isLoading = true;
    // console.log(this.queryTable)
    this.apiService.listAllBlog(this.queryTable).subscribe(
      (data) => {
        this.dataSource.data = data.rows;
        this.dataSource.sort = this.sort;
        this.totalData = data.count;
        this.newsList = data.rows;
        // console.log(this.dataSource.data)
        this.isLoading = false;
      },
      (e) => {
        console.log(e);
      }
    );
  }

  fetchNewBlogs() {
    this.isNewBlogLoading = true;
    // console.log(this.queryTable)
    const params = {
      sort: {
        field: "publishedAt",
        order: "descend",
      },
      filters: [
        {
          field: "dept",
          keyword: "ONDELIVERY",
        },
      ],
      pageSize: 4,
      pageIndex: 0,
    };
    this.apiService.listAllBlog(params).subscribe(
      (data) => {
        this.newNews = data.rows;
        console.log("Console News", data);
        this.reinitializeCarousel();
        this.isNewBlogLoading = false;
      },
      (e) => {
        console.log(e);
      }
    );
  }

  ngAfterViewInit() {
    this.initializeCarousel();
  }

  reinitializeCarousel() {
    setTimeout(() => {
      $(".owl-one").owlCarousel("destroy"); // Destroy previous initialization if any
      this.initializeCarousel();
    }, 0); // Timeout to ensure DOM is updated
  }

  initializeCarousel() {
    $(".owl-one").owlCarousel({
      loop: true,
      margin: 10,
      // nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 2,
        },
      },
    });
  }

  applyFilter() {
    if (this.queryTable.filters.findIndex((e) => e.field === "title") === -1) {
      this.queryTable.filters.push({
        field: "title",
        keyword: this.searchText,
      });
    } else {
      this.queryTable.filters[
        this.queryTable.filters.findIndex((e) => e.field === "title")
      ] = {
        field: "title",
        keyword: this.searchText,
      };
    }

    this.fetchBlogs();
  }

  onPaginateChange(event: any) {
    // console.log(event)
    if (event.pageSize != this.queryTable.pageSize) {
      this.queryTable.pageIndex = 0;
      this.queryTable.pageSize = event.pageSize;
    } else {
      this.queryTable.pageIndex = event.pageIndex;
    }
    // this.dataSource.data = []
    this.fetchBlogs();
  }
}
