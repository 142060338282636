import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jwt_decode from "jwt-decode";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import blackpink from './login-translate.json'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ApiService]
})
export class LoginComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  hide = true
  form: FormGroup
  errorLogin: string | null = null

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService,
    private routes: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  onSubmit() {
    const username = this.form.value.username
    const password = this.form.value.password

    this.service.getUser(username, password).subscribe(data => {
      const decodedToken: any = jwt_decode(data.token)
      //Set Expiration on Local Storage
      localStorage.setItem('username', decodedToken.user)
      localStorage.setItem('jwt', data.token)
      if (data.agent != undefined) {
        localStorage.setItem('agent', data.agent)
      }

      this.dialogRef.close()
      this.routes.navigate(['/'])
    }, e => {
      if (e.status == 422) {
        this.errorLogin = "INVALID_CREDENTIAL"
      } else if (e.status == 403) {
        switch (e.error.message) {
          case "Account is Disabled":
            this.errorLogin = "ACCOUNT_DISABLED"
            break
          case "Account is Not Confirmed":
            this.errorLogin = "ACCOUNT_NOT_CONFIRMED"
            break
          case "OTP Not Confirmed":
            this.errorLogin = "OTP_NOT_CONFIRMED"
            break
        }
      } else {
        this.errorLogin = "SERVICE_ERROR"
      }
    })
  }
}

