import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BookingTable, QueryTable } from "./driver.model";
// import axios from 'axios'

export class Waybill {
  authorization: string;
  barcodeScan: string;
  waybill_number: string;
  origin: string;
  destination: string;
  service: string;
  created_at: string;
  sender_name: string;
  sender_phone: string;
  sender_city: string;
  sender_province: string;
  sender_zipcode: string;
  consignee_name: string;
  consignee_phone: string;
  consignee_address: string;
  consignee_city: string;
  consignee_province: string;
  consignee_zipcode: string;
  goods_koli: string;
  goods_weight: string;
  goods_desc: string;
  goods_value: string;
  goods_insurance: string;
  goods_handling: string;
  notes_instructions: string;
  payment_type: string;
  freight_charge: string;
  discount: string;
  insurance_fee: string;
  packing_fee: string;
  surcharge: string;
  total_fee: string;
  agent_id: string;
  agent_name: string;
  pickup_name: string;
}

@Injectable()
export class ApiService {
  //PROD
  API_USERSTATE_SERVER = "https://usapi.onindonesia.id/";
  API_SUNSHINE_SERVER = "https://sunshineapi.onindonesia.id/";
  API_USERONDELIVERY_SERVER = "https://onapps-api.onindonesia.id/";
  API_OSAS_SERVER = "https://api.ondelivery.id";
  DOMAIN_SERVER = "https://ondelivery.id";
  API_APISAT = "https://apisat.ondelivery.id";

  //DEMO
  // API_USERSTATE_SERVER = "https://demousapi.onindonesia.id/";
  // API_SUNSHINE_SERVER = "https://demosunshineapi.onindonesia.id/";
  // API_USERONDELIVERY_SERVER = "https://demo-onapps-api.onindonesia.id/";
  // API_OSAS_SERVER = "https://testapi.ondelivery.id";
  // DOMAIN_SERVER = "https://ondelivery.id";
  // API_APISAT = "https://demoapisat.ondelivery.id";

  bookingData: any[] = [];

  constructor(private http: HttpClient) {}

  setBooking(data) {
    this.bookingData = data;
  }

  getBooking() {
    return this.bookingData;
  }

  getUser(uname: string, pwd: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      options: {
        nativeApp: true,
      },
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "auth",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  register(
    uname: string,
    pwd: string,
    email: string,
    fname: string,
    lname: string,
    phone_number: string,
    mode: string
  ) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      email: email,
      first_name: fname,
      last_name: lname,
      phone_number: phone_number,
      mode: "LINK",
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "signup",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verify(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify", {
      headers: customHeaders,
    });
  }

  verifySignUp(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-sign-up", {
      headers: customHeaders,
    });
  }

  logOff(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "logoff", {
      headers: customHeaders,
    });
  }

  requestResetPwd(email: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      email: email,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "request-reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verifyLinkResetPwd(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-reset-pwd", {
      headers: customHeaders,
    });
  }

  resetPwd(password: string, token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const payload = {
      password: password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  checkDeliveryPrice(
    dest: string,
    origin: string,
    weight: number,
    dimension: any
  ) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    var payload: any = {
      dest: dest,
      origin: origin,
    };

    if (weight != null) {
      payload.weight = weight;
    }

    if (dimension != null) {
      payload.dimension = dimension;
    }

    return this.http.post<any>(
      this.API_USERONDELIVERY_SERVER + "check-delivery-price",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  waybillBasic(waybill: Array<string>) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!,
    });
    const payload = {
      searchMethod: "trackingID",
      searchTrackID: waybill,
    };
    // return this.http.post<any>(this.API_USERONDELIVERY_SERVER+"waybill-basic", JSON.stringify(payload), {headers: customHeaders})
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/waybill/new-tracking",
      payload,
      { headers: customHeaders }
    );
  }

  waybillDetail(waybill: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      waybill: waybill,
    };
    return this.http.post<any>(
      this.API_USERONDELIVERY_SERVER + "waybill-detail",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  waybillHistory(token, cursor, mode) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });

    var payload;
    payload = { cursor: 0, mode: "forward", page_size: 35 };

    return this.http.post<any>(
      this.API_USERONDELIVERY_SERVER + "waybill-history",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  originSearch(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    const body = {
      origin_search: content,
    };
    // console.log(body)
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/check-fee/origin-auto-search-pcp",
      body,
      { headers: customHeaders }
    );
  }

  destinationSearch(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    const body = {
      searchDest: content,
    };
    // console.log(body)
    return this.http.post<any>(this.API_OSAS_SERVER + "/pcp/auto-list", body, {
      headers: customHeaders,
    });
  }

  originSearchNew(origin: String) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    //const params = new HttpParams().set("search_origin")
    return this.http.get<any>(
      this.API_OSAS_SERVER +
        "/check-fee/origin/searchlanding?search_origin=" +
        origin,
      { headers: customHeaders }
    );
  }

  destinationSearchNew(destination: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    //const params = new HttpParams().set("search_origin")
    return this.http.get<any>(
      this.API_OSAS_SERVER +
        "/check-fee/destination/searchlanding?search_dest=" +
        destination,
      { headers: customHeaders }
    );
  }

  checkFee(content: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });

    const body = {
      weight: content.weight,
      longDim: content.dimension.long,
      widthDim: content.dimension.width,
      heightDim: content.dimension.height,
      destination: content.destination,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/check-fee-landing",
      body,
      { headers: customHeaders }
    );
  }

  checkFeeNew(content: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });

    const body = {
      originId: content.origin.id,
      destinationId: content.destination.id,
      // goodsValue: content.goodsValue,
      // packingCost: content.packingCost,
      weight: Number(content.weight),
      dimension: {
        length: content.dimension.long,
        width: content.dimension.width,
        height: content.dimension.height,
      },
    };
    // console.log(body);
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/check-fee/service/availability/landing",
      body,
      { headers: customHeaders }
    );
  }

  checkFeeNewNew(content: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    // console.log(content, "content");
    const body = {
      originId: content.origin.id,
      destinationId: content.destination.id,
      goodsValue: content.goodsValue == undefined ? 0 : content.goodsValue,
      packingCost: content.packingCost == undefined ? 0 : content.packingCost,
      weight: Number(content.weight),
      dimension: {
        length: content.dimension.long,
        width: content.dimension.width,
        height: content.dimension.height,
      },
    };
    // console.log(body);
    return this.http.post<any>(this.API_OSAS_SERVER + "/check-fee", body, {
      headers: customHeaders,
    });
  }

  // create waybill / booking
  agentLocationSidenote(loc_id: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      location_id: loc_id,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/waybill/agent_loc_side",
      body,
      { headers: customHeaders }
    );
  }

  findThisDest(content1: string, content2: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      type: content1,
      dest: content2,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/find-destination",
      body,
      { headers: customHeaders }
    );
  }

  ////////////////////////////////////////Autocompletes Starts////////////////////////////////////////
  autoCity(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      city_search: content,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/auto-city",
      body,
      { headers: customHeaders }
    );
  }
  autoProvince(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      province_search: content,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/auto-province",
      body,
      { headers: customHeaders }
    );
  }
  autoDistrict(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      district_search: content,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/auto-district",
      body,
      { headers: customHeaders }
    );
  }
  ////////////////////////////////////////Autocompletes Ends////////////////////////////////////////

  priceListTest(loc_id: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      destination_id_pcp: loc_id.destination.destination_id,
      service: loc_id.service,
      shipment_type: loc_id.shipment_type,
      goods_weight: loc_id.goods_weight,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/price-list-test",
      body,
      { headers: customHeaders }
    );
  }

  createManifestPcp(content: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/open-manifest",
      content,
      { headers: customHeaders }
    );
  }

  closeManifestPcp(content: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/close-manifest",
      content,
      { headers: customHeaders }
    );
  }

  viewManifestPcp(content: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      agent_id: content,
    };
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/view-manifest",
      body,
      { headers: customHeaders }
    );
  }

  createTransactionPcp(content: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/pcp/waybill/add-transaction",
      content,
      { headers: customHeaders }
    );
  }

  findDestWaybill(content: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // authorization: localStorage.getItem("jwt")!,
    });
    // const body = {
    //   destination_id: content,
    // };
    return this.http.post<any>(this.API_OSAS_SERVER + "/check-fee", content, {
      headers: customHeaders,
    });
  }

  newAutoDistrict(content: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      search_dest: content,
    };
    return this.http.get<any>(
      this.API_OSAS_SERVER + "/check-fee/destination/search",
      {
        headers: customHeaders,
        params: body,
      }
    );
  }

  newPriceList(content: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(this.API_OSAS_SERVER + "/check-fee", content, {
      headers: customHeaders,
    });
  }

  calculateService(content: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/check-fee/calculatelanding",
      content,
      {
        headers: customHeaders,
      }
    );
  }

  priceList(loc_id: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      destination_id_pcp: loc_id.destination.destination_id,
      service: loc_id.service,
      shipment_type: loc_id.shipment_type,
      goods_weight: loc_id.goods_weight,
    };
    return this.http.post<any>(this.API_OSAS_SERVER + "/pcp/price-list", body, {
      headers: customHeaders,
    });
  }

  sendCreateWayBill(waybill: Waybill): Observable<Waybill> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    waybill.pickup_name = "manual agent";
    return this.http.post<Waybill>(
      this.API_OSAS_SERVER + "/waybill/create",
      waybill,
      { headers: customHeaders }
    );
  }

  createTransactionLc(content: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/lc/create-waybill",
      content,
      { headers: customHeaders }
    );
  }

  createBooking(content) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    return this.http.post<any>(
      this.API_APISAT + "/booking/delivery/user/create",
      content,
      { headers: customHeaders }
    );
  }

  getUserBooking(size, page) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const params = new HttpParams().set("size", size).set("page", page);
    return this.http.get<any>(this.API_APISAT + "/booking/delivery/user/list", {
      headers: customHeaders,
      params: params,
    });
  }

  bookingHistory(bookingTable: BookingTable): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const params = {
      page_index: bookingTable.page_index,
      page_size: bookingTable.page_size,
    };
    // console.log(params)
    return this.http.post(
      this.API_USERONDELIVERY_SERVER + "waybill-history",
      params,
      { headers: customHeaders }
    );
  }

  detailUserBooking(booking_id) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const params = new HttpParams().set("booking_id", booking_id);
    return this.http.get<any>(
      this.API_APISAT + "/booking/delivery/user/detail",
      { headers: customHeaders, params: params }
    );
  }

  cancelBooking(booking_id: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const body = {
      booking_id: booking_id,
    };
    return this.http.post<any>(
      this.API_APISAT + "/booking/delivery/user/cancel",
      body,
      { headers: customHeaders }
    );
  }

  content_list() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: localStorage.getItem("jwt")!,
    });
    return this.http.get<any>(this.API_OSAS_SERVER + "/waybill/content_list", {
      headers: customHeaders,
    });
  }

  ChangeUserPassword(old_password: string, new_password: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const payload = {
      old_password: old_password,
      new_password: new_password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-pwd",
      payload,
      { headers: customHeaders }
    );
  }

  updateBasicProfile(payload, token: string) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-basic-profile",
      payload,
      { headers: customHeaders }
    );
  }

  retrieveBasicProfile() {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "basic-profile", {
      headers: customHeaders,
    });
  }

  agentRegister(name: string, email: string, phone_number: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const body = {
      name: name,
      email: email,
      phone_number: phone_number,
    };
    return this.http.post<any>(
      this.API_USERONDELIVERY_SERVER + "register-agent",
      body,
      { headers: customHeaders }
    );
  }

  waybillLottery(waybill_number: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const body = {
      waybill_number: waybill_number,
    };
    return this.http.post<any>(
      this.API_USERONDELIVERY_SERVER + "waybill-lottery/create",
      body,
      { headers: customHeaders }
    );
  }

  // ----- CMS BLOG -----

  viewAddreessDetail() {
    return this.http.get<any>(
      this.API_SUNSHINE_SERVER + "api/cms/droppoint/list/public"
    );
  }

  blogDetail(id) {
    const params = new HttpParams().set("id", id);
    return this.http.get<any>(
      this.API_SUNSHINE_SERVER + "api/cms/blog/detail/public",
      { params: params }
    );
  }

  listAllBlog(queryTable: QueryTable): Observable<any> {
    var params = {
      pageIndex: queryTable.pageIndex.toString(),
      pageSize: queryTable.pageSize.toString(),
    };
    queryTable.filters.forEach((e: any) => {
      params[e.field] = e.keyword;
    });

    return this.http.get(
      this.API_SUNSHINE_SERVER + "api/cms/blog/list/public",
      { params: params }
    );
  }

  listAllTraining(queryTable: QueryTable, token: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    var params = {
      pageIndex: queryTable.pageIndex.toString(),
      pageSize: queryTable.pageSize.toString(),
    };
    queryTable.filters.forEach((e: any) => {
      params[e.field] = e.keyword;
    });

    return this.http.get(
      this.API_SUNSHINE_SERVER + "api/cms/training/list/public",
      { headers: customHeaders, params: params }
    );
  }

  trainingDetail(id, token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const params = new HttpParams().set("id", id);
    return this.http.get<any>(
      this.API_SUNSHINE_SERVER + "api/cms/training/detail/public",
      { headers: customHeaders, params: params }
    );
  }

  loggingTraining(
    token: string,
    title: string,
    training_id: string,
    agent_id: string,
    full_name: string,
    email: string
  ) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const body = {
      title: title,
      training_id: training_id,
      agent_id: agent_id,
      full_name: full_name,
      email: email,
    };
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + "api/cms/training/views/create",
      body,
      { headers: customHeaders }
    );
  }

  // ----- CMS BLOG ----

  vacancyDetail(id) {
    const params = new HttpParams().set("vacancyKey", id);
    return this.http.get<any>(
      this.API_SUNSHINE_SERVER + "api/cms/vacancy/details/public",
      { params: params }
    );
  }

  listAllVacancies(queryTable: QueryTable): Observable<any> {
    const params = new HttpParams().set(
      queryTable.filters[
        queryTable.filters.findIndex((e) => e.field === "departmentKey")
      ].field,
      queryTable.filters[
        queryTable.filters.findIndex((e) => e.field === "departmentKey")
      ].keyword
    );
    return this.http.get(
      this.API_SUNSHINE_SERVER + "api/cms/vacancy/list/public",
      {
        params: params,
      }
    );
  }

  DepartmentDetail(id) {
    const params = new HttpParams().set("id", id);
    return this.http.get<any>(
      this.API_SUNSHINE_SERVER + "api/cms/department/detail",
      { params: params }
    );
  }

  listAllDepartment(queryTable: QueryTable): Observable<any> {
    return this.http.get(
      this.API_SUNSHINE_SERVER + "api/cms/department/list/public",
      {
        params: {
          pageIndex: queryTable.pageIndex.toString(),
          pageSize: queryTable.pageSize.toString(),
        },
      }
    );
  }

  // ----- CMS BANNER ---- //

  listAllBanner(type): Observable<any> {
    return this.http.get(this.API_SUNSHINE_SERVER + "api/cms/ads/public", {
      params: {
        limit: "15",
        dept: type,
      },
    });
  }

  // ----- CTA MANAGEMENT ---- //

  createAgentInquiry(
    full_name: string,
    agent_type: string,
    phone: string,
    email: string,
    location_type: string,
    building_status: string,
    full_address: string,
    location_coordinate,
    osas_location
  ): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'Authorization': 'Bearer ' + token
    });
    const body = {
      full_name: full_name,
      agent_type: agent_type,
      phone: phone,
      email: email,
      location_type: location_type,
      building_status: building_status,
      full_address: full_address,
      location_coordinate: location_coordinate,
      osas_location: osas_location,
    };
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + "api/cta/agent-inquiry/create",
      body,
      { headers: customHeaders }
    );
  }

  createBusinessInquiry(
    full_name: string,
    phone: string,
    email: string,
    business_name: string,
    product_sold: string,
    shipment_quantity: number,
    business_link: string
  ): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'Authorization': 'Bearer ' + token
    });
    const body = {
      full_name: full_name,
      phone: phone,
      email: email,
      business_name: business_name,
      product_sold: product_sold,
      shipment_quantity: shipment_quantity,
      business_link: business_link,
    };
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + "api/cta/business-inquiry/create",
      body,
      { headers: customHeaders }
    );
  }

  searchLocation(params: any): Observable<any> {
    return this.http.get<any>(
      this.API_OSAS_SERVER + "/courierPartner/searchLocation"
    );
  }

  courierPartnerSignUp(formData: any): Observable<any> {
    let customHeaders = new HttpHeaders();
    customHeaders.append("Content-Type", "multipart/form-data");

    // const formData = new FormData();
    // formData.append('images', images[5]);
    // formData.append('data', JSON.stringify(data));
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/courierPartner/signup",
      formData,
      {
        headers: customHeaders,
      }
    );
  }

  signupOTP(params: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    });
    return this.http.post<any>(this.API_USERSTATE_SERVER + "signup", params, {
      headers: customHeaders,
    });
  }

  resendOTP(token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "resend-otp-register",
      {
        headers: customHeaders,
      }
    );
  }

  verifyOTP(params: any, token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "verify-sign-up",
      params,
      {
        headers: customHeaders,
      }
    );
  }
}
