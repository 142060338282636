<div class="d-none d-lg-block"></div>
<footer class="footer-section text-white" style="padding: 0 40px">
  <!-- Footer Top -->
  <div class="footer-top pt-2 pb-5">
    <div class="mt-5">
      <div class="row d-flex">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12"
          style="padding-bottom: 20px"
        >
          <a class="navbar-brand" href="index.html">
            <img
              class="navbar-brand-regular"
              src="https://storage.onindonesia.id/ondelivery.id/Ondelivery Logo White.png"
              alt="brand-logo"
              style="width: 180px"
          /></a>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-2 mb-5">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title">{{ translateArr.bp1 }}</h3>
            <ul>
              <li class="py-1">
                <a href="/vip-customer-info">{{ translateArr.bp4 }}</a>
              </li>
              <li class="py-1">
                <a href="/keagenan-info">{{ translateArr.bp7 }}</a>
              </li>
              <li class="py-1">
                <a href="/news-and-promos">{{ translateArr.bp5 }}</a>
              </li>
              <li class="py-1">
                <a href="/tentang-kami">{{ translateArr.bp2 }} </a>
              </li>
              <li class="py-1">
                <a href="/pusat-bantuan">{{ translateArr.bp6 }} </a>
              </li>
              <!-- <li class="py-1">
                <a href="/privacy-policy">{{ translateArr.bp8 }}</a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-2">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-3">{{ translateArr.bp10 }}</h3>
            <ul>
              <li class="py-1">
                <a href="/vip-customer-info">{{ translateArr.bp12 }}</a>
              </li>
              <li class="py-1">
                <a href="/ondelivery-express">ONDELIVERY EXPRESS</a>
              </li>
              <li class="py-1">
                <a href="/ondelivery-trucking">ONDELIVERY TRUCKING</a>
              </li>
              <li class="py-1">
                <a href="/ondelivery-logistics">ONDELIVERY LOGISTICS</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-4">
          <!-- Footer Items -->
          <ul class="service-list">
            <!-- Single Service -->
            <div class="footer-items">
              <h3 class="footer-title mb-3">{{ translateArr.bp14 }}</h3>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">location_city</span>
                </div>
                <div class="service-text media-body">
                  <p>
                    Rukan SCBR, Jalan Boulevard Raya Jalan Agung Sedayu City
                    No.2
                    <br />
                    Cakung Barat, Kec. Cakung
                    <br />
                    Jakarta Timur, ID 14140
                  </p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">local_phone</span>
                </div>
                <div class="service-text media-body">
                  <p>(021) 50555007</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">email</span>
                </div>
                <div class="service-text media-body">
                  <a href="mailto:businessdevelopment@ondelivery.id"
                    >businessdevelopment&#64;ondelivery.id</a
                  >
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 mb-4 pl-5">
          <h3 class="footer-title">{{ translateArr.bp16 }}</h3>
          <!-- Social Icons -->
          <div class="d-flex mb-3">
            <a
              href="https://instagram.com/ondeliveryid?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <i class="fab fa-instagram fa-lg mr-2"></i>
            </a>
            <a href="https://twitter.com/ONDeliveryID" target="_blank">
              <i class="fab fa-twitter fa-lg mr-2"></i>
            </a>
            <a href="https://www.tiktok.com/@ondelivery.id" target="_blank">
              <img
                class="tiktok-icons"
                src="https://storage.onindonesia.id/ondelivery.id/tiktok-brands.svg"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCbI1oe0LiH5PQXng6m6E7hQ"
              target="_blank"
            >
              <i class="fab fa-youtube fa-lg mr-2"></i>
            </a>
          </div>
          <!-- <h3 class="footer-title pt-5 pb-0">{{ translateArr.bp17 }}</h3> -->
          <!-- Store Buttons -->
          <div class="button-group store-buttons store-black d-flex flex-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
              target="_blank"
            >
              <img
                src="https://storage.onindonesia.id/ondelivery.id/google-play-black.png"
                alt=""
              />
            </a>
            <a
              href="https://apps.apple.com/id/app/onapps-indonesia/id1587666667"
              target="_blank"
            >
              <img
                src="https://storage.onindonesia.id/ondelivery.id/app-store-black.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="row">
      <div class="col-12">
        <div
          class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4"
        >
          <div class="copyright-left">&copy; {{ translateArr.bp18 }}</div>
        </div>
      </div>
    </div>
  </div>
</footer>
