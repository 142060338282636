import { Component, OnInit } from "@angular/core";
declare var $: any;
@Component({
  selector: "app-business-inquiries-info",
  templateUrl: "./business-inquiries-info.component.html",
  styleUrls: ["./business-inquiries-info.component.scss"],
})
export class BusinessInquiriesInfoComponent implements OnInit {
  imagesPartners: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Anteraja-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Aroma Prima Livindo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Blibli-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/BPJS-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Foom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/InHarmony-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Jubelio-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Kaisar-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/LOGO-LKPP-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monarre-min.png",

    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Monologiz-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monotaro-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Nature market-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/nozomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/OT_Logo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Pacific city-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Quantum-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Relx-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sigmatech-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sinarmas-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
  ];

  imagesPartners2: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Monologiz-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monotaro-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Nature market-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/nozomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/OT_Logo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Pacific city-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Quantum-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Relx-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sigmatech-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sinarmas-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sociolla-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/SPX-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Tempo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Vitabiotics-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/wings-min.png",

    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Anteraja-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Aroma Prima Livindo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Blibli-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/BPJS-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Foom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/InHarmony-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Jubelio-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Kaisar-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/LOGO-LKPP-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monarre-min.png",
  ];
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initializeCarousel();
  }

  reinitializeCarousel() {
    setTimeout(() => {
      $(".owl-one").owlCarousel("destroy");
      this.initializeCarousel();
    }, 0);
  }

  initializeCarousel() {
    $(".owl-one").owlCarousel({
      loop: true,
      margin: 10,
      // nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 2,
        },
      },
    });
  }
}
