import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, OnInit } from "@angular/core";
import translationLib from "./tracking-dialog-translate.json";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/api.service";
import dayjs from "dayjs";

@Component({
  selector: "app-tracking-dialog",
  templateUrl: "./tracking-dialog.component.html",
  styleUrls: ["./tracking-dialog.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
      },
    },
  ],
})
export class TrackingDialogComponent implements OnInit {
  dataSourceWaybillBasic: any;
  displayedColumnsWaybillBasic: string[] = [
    "index",
    "waybill_number",
    "service",
    "consignee_district",
    "consignee_name",
    "received_at",
    "status",
    "action",
  ];
  waybillBasicTableDisplay: boolean = false;
  detailWaybillDisplay: boolean = false;
  detailWaybillData: any;
  waybillDetailDataSource: any = {
    waybill_number: null,
    service: null,
    sender_name: null,
    sender_province: null,
    consignee_name: null,
    consignee_province: null,
    consignee_district: null,
    date_of_shipment: null,
    status: null,
    search_tracks: {},
  };
  loadingMode: boolean = false;
  isLinear = false;
  getParams: string[] = [];

  translateArr = translationLib[localStorage.getItem("translate")];
  constructor(private routes: ActivatedRoute, private service: ApiService) {}

  ngOnInit(): void {
    this.fetchDataQuery();
  }

  fetchDataQuery() {
    this.routes.queryParamMap.subscribe((params) => {
      this.getParams = JSON.parse(atob(params.get("listWaybill")));
    });
    this.detailWaybillDisplay = false;
    this.waybillBasicTableDisplay = false;
    this.loadingMode = true;
    this.service.waybillBasic(this.getParams).subscribe(
      (res) => {
        var i = 1;
        res.forEach((item) => {
          item.index = i;
          i++;
          item.waybill_number = item.waybill_number;
          // console.log(item.waybill_number.substring(0,19))
        });

        // console.log(r)

        this.dataSourceWaybillBasic = res;

        // console.log(this.dataSourceWaybillBasic);

        this.loadingMode = false;
        this.waybillBasicTableDisplay = true;
      },
      (e) => {
        this.dataSourceWaybillBasic = [{ index: "Not Found" }];
        this.loadingMode = false;
        this.waybillBasicTableDisplay = true;
      }
    );
  }

  traceWaybillDetaill(index: string | number) {
    this.waybillDetailDataSource = this.dataSourceWaybillBasic[index];
    // console.log(this.waybillDetailDataSource.tracking_element)
    this.loadingMode = false;
    this.detailWaybillDisplay = true;
  }

  formatDate(date: any) {
    return dayjs(date).format("DD-MM-YYYY - HH:mm WIB");
  }
}
