<ngx-spinner
  type="square-jelly-box"
  size="medium"
  color="#ffffff"
  bdColor="rgba(0, 0, 0, 0.8)"
  [fullScreen]="true"
  >Loading ....
</ngx-spinner>

<div class="card" #panel>
  <h3 class="contact-title m-0">{{ translateArr.bp1 }}</h3>

  <mat-accordion #accordion="matAccordion" [multi]="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Metode Pengiriman </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form1">
        <!-- PANEL METODE PENGIRIMAN -->
        <!-- METODE PENGIRIMAN -->
        <div class="row col-12 pl-0 mr-3">
          <div class="col-sm-12 pb-4 pl-0">
            <label class="label"
              >Pilih metode pengiriman<span style="color: red">*</span></label
            >
            <mat-form-field class="input-group yeye mb-3" appearance="none">
              <mat-select [formControl]="form1.controls['mode']">
                <mat-option *ngFor="let food of foods" [value]="food.value">
                  {{ food.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-hint
            *ngIf="
              form1.controls['mode'].hasError('required') &&
              form1.controls['mode'].touched
            "
            class="text-danger"
            >Mohon dipilih metode pengiriman
          </mat-hint>
        </div>
      </form>
    </mat-expansion-panel>

    <!-- PANEL INFORMASI PENGIRIM -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ translateArr.bp3 }}</mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form1">
        <!-- FORM INFORMASI PENGIRIM -->
        <div class="row">
          <!-- NAMA PENGIRIM -->
          <div class="row col-6 pl-0 mr-3">
            <div class="col-sm-12">
              <label class="label"
                >{{ translateArr.bp4 }}<span style="color: red">*</span></label
              >
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="basic-addon2"
                  [formControl]="form1.controls['sender_name']"
                />
              </div>
              <mat-hint
                *ngIf="
                  form1.controls['sender_name'].hasError('required') &&
                  form1.controls['sender_name'].touched
                "
                class="text-danger"
                >Mohon di isi
              </mat-hint>
            </div>
          </div>
          <!-- NOMOR TELEPON -->
          <div class="row col-6 pl-0">
            <div class="col-sm-12 pb-2 w-100">
              <label class="label"
                >{{ translateArr.bp5 }}<span style="color: red">*</span></label
              >
              <div class="input-group mb-2">
                <input
                  type="text"
                  maxlength="15"
                  numbersOnly
                  class="form-control"
                  aria-describedby="basic-addon2"
                  [formControl]="form1.controls['sender_phone']"
                />
              </div>
              <mat-hint
                *ngIf="
                  form1.controls['sender_phone'].hasError('required') &&
                  form1.controls['sender_phone'].touched
                "
                class="text-danger"
                >Mohon di isi
              </mat-hint>
              <mat-hint
                *ngIf="
                  form1.controls['sender_phone'].invalid &&
                  form1.controls['sender_phone'].touched &&
                  !form1.controls['sender_phone'].hasError('required')
                "
                class="text-danger support-text"
                >Format salah (No. Telp harus angka dengan minimal 5 karakter)
              </mat-hint>
            </div>
          </div>
          <!-- KOTA KECAMATAN PENGIRIM -->
          <div class="row col-12 pl-0 mr-3">
            <div class="col-sm-12 pb-4">
              <label class="label"
                >{{ translateArr.bp10 }}<span style="color: red">*</span></label
              >
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="basic-addon2"
                  [matAutocomplete]="autoDestPengirim"
                  [formControl]="form1.controls['destinationPengirim']"
                />
                <mat-autocomplete
                  #autoDestPengirim="matAutocomplete"
                  [displayWith]="displayFnSender"
                  (optionSelected)="originOptionSelected($event)"
                >
                  <mat-option
                    class="complete-list"
                    *ngFor="let option1 of senderListRev | async"
                    [value]="option1"
                  >
                    {{ option1.urban_name }}, {{ option1.sub_district_name }},
                    {{ option1.city_name }}, Prov. {{ option1.province_name }},
                    {{ option1.postal_code }}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <mat-hint
                *ngIf="
                  form1.controls['destinationPengirim'].hasError('required') &&
                  form1.controls['destinationPengirim'].touched
                "
                class="text-danger"
                >Mohon dipilih Kota/Kecamatan penerima
              </mat-hint>
            </div>
          </div>

          <!-- TITIK PENJEMPUTAN -->
          <div class="row col-12 pl-0">
            <div class="col-sm-12 pb-2">
              <label class="label"
                >Titik Penjemputan<span style="color: red">*</span></label
              >
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="basic-addon2"
                  [formControl]="form1.controls['sender_loc']"
                />
                <div class="input-group-append">
                  <button
                    class="btn search-button"
                    type="button"
                    (click)="searchPickUpPosition()"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </div>
              <mat-hint
                *ngIf="
                  form1.controls['sender_loc'].hasError('required') &&
                  form1.controls['sender_loc'].touched
                "
                class="text-danger"
                >Mohon di isi
              </mat-hint>
            </div>
          </div>

          <!-- MAP PENGIRIM -->
          <div class="row col-12 pl-0 mr-3">
            <div class="col-sm-12 pb-4">
              <div class="origin-maps" id="map"></div>
              <label class="map-label"
                ><strong>NOTE</strong>: Click pada titik spesific untuk lokasi
                penjemputan<span style="color: red">*</span></label
              >
            </div>
          </div>

          <!-- ALAMAT LENGKAP PENGIRIM -->
          <div class="row col-12 pl-0">
            <div class="col-sm-12 pb-2">
              <label class="label">{{ translateArr.bp6 }}</label>
              <div class="input-group mb-2">
                <textarea
                  class="form-control"
                  rows="4"
                  matInput
                  aria-describedby="basic-addon2"
                  [formControl]="form1.controls['sender_address']"
                >
                </textarea>
              </div>
              <mat-hint
                *ngIf="
                  form1.controls['sender_address'].hasError('required') &&
                  form1.controls['sender_address'].touched
                "
                class="text-danger"
                >Mohon di isi
              </mat-hint>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>

    <!-- PANEL INFORMASI PENERIMA-->
    <form [formGroup]="form">
      <div formArrayName="users">
        <div *ngFor="let user of users.controls; let i = index">
          <div *ngIf="showFormGroup[i]">
            <div [formGroupName]="i">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title *ngIf="oneReceiver; else manyReceiver1">
                    {{ translateArr.bp7 }}</mat-panel-title
                  >
                  <ng-template #manyReceiver1
                    ><mat-panel-title
                      >{{ translateArr.bp7 }} {{ i + 1 }}</mat-panel-title
                    ></ng-template
                  >
                </mat-expansion-panel-header>
                <div class="row">
                  <!-- NAMA PENERIMA -->
                  <div class="row col-6 pl-0 mr-3">
                    <div class="col-sm-12 pb-2">
                      <label class="label"
                        >{{ translateArr.bp8
                        }}<span style="color: red">*</span></label
                      >
                      <div class="input-group mb-2">
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['receiver_name']"
                        />
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['receiver_name'].hasError('required') &&
                          user.controls['receiver_name'].touched
                        "
                        class="text-danger"
                        >Mohon masukkan nama penerima
                      </mat-hint>
                    </div>
                  </div>

                  <!-- NOMOR TELEPON PENERIMA  -->
                  <div class="row col-6 pl-0 mr-2">
                    <div class="col-sm-12 pb-2">
                      <label class="label"
                        >{{ translateArr.bp9
                        }}<span style="color: red">*</span></label
                      >
                      <div class="input-group mb-2">
                        <input
                          type="text"
                          maxlength="15"
                          numbersOnly
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['receiver_phone']"
                        />
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['receiver_phone'].hasError(
                            'required'
                          ) && user.controls['receiver_phone'].touched
                        "
                        class="text-danger"
                        >Mohon di isi
                      </mat-hint>
                      <mat-hint
                        *ngIf="
                          user.controls['receiver_phone'].invalid &&
                          user.controls['receiver_phone'].touched &&
                          !user.controls['receiver_phone'].hasError('required')
                        "
                        class="text-danger support-text"
                        >Format salah (No. Telp harus angka dengan minimal 5
                        karakter)
                      </mat-hint>
                    </div>
                  </div>

                  <!-- KOTA KECAMATAN PENERIMA -->
                  <div class="row col-12 pl-0 mr-3">
                    <div class="col-sm-12 pb-4">
                      <label class="label"
                        >{{ translateArr.bp10
                        }}<span style="color: red">*</span></label
                      >
                      <div class="input-group mb-2">
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [matAutocomplete]="autoDest"
                          [formControl]="user.controls['destination']"
                        />
                        <mat-autocomplete
                          #autoDest="matAutocomplete"
                          [displayWith]="displayFnreceiver"
                          (optionSelected)="
                            destinationOptionSelected($event, i)
                          "
                        >
                          <mat-option
                            class="complete-list"
                            *ngFor="
                              let option2 of destinationListRev[i] | async
                            "
                            [value]="option2"
                          >
                            {{ option2.urban_name }},
                            {{ option2.sub_district_name }},
                            {{ option2.city_name }}, Prov.
                            {{ option2.province_name }},
                            {{ option2.postal_code }}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['destination'].hasError('required') &&
                          user.controls['destination'].touched
                        "
                        class="text-danger"
                        >Mohon dipilih Kota/Kecamatan penerima
                      </mat-hint>
                    </div>
                  </div>

                  <!-- ALAMAT LENGKAP PENERIMA -->
                  <div class="row col-12 pl-0 mr-3">
                    <div class="col-sm-12 pb-2">
                      <label class="label"
                        >{{ translateArr.bp11
                        }}<span style="color: red">*</span></label
                      >
                      <div class="input-group mb-2">
                        <textarea
                          class="form-control"
                          rows="4"
                          matInput
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['receiver_address']"
                        >
                        </textarea>
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['receiver_address'].hasError(
                            'required'
                          ) && user.controls['receiver_address'].touched
                        "
                        class="text-danger"
                        >Mohon masukkan alamat penerima
                      </mat-hint>
                    </div>
                  </div>
                </div>

                <!-- INFORMASI LENGKAP PAKET -->
                <mat-panel-title *ngIf="oneReceiver; else manyReceiver2">
                  {{ translateArr.bp12 }}</mat-panel-title
                >
                <ng-template #manyReceiver2
                  ><mat-panel-title
                    >{{ translateArr.bp12 }} {{ i + 1 }}</mat-panel-title
                  ></ng-template
                >
                <hr class="solid" style="width: 125%" />

                <div class="row">
                  <!-- LAYANAN PENGIRIMAN -->
                  <div class="row col-12 pl-0 mr-3">
                    <div class="col-sm-12 pb-2">
                      <label class="label"
                        >{{ translateArr.bp33 }}<span style="color: red">*</span
                        ><a
                          class="question-tag"
                          href="/ondelivery-express"
                          target="_blank"
                          >?</a
                        ></label
                      >
                      <mat-form-field
                        class="input-group yeye mb-2"
                        appearance="none"
                      >
                        <mat-select
                          [formControl]="user.controls['service']"
                          required
                        >
                          <mat-option
                            *ngFor="let serviceOption of serviceOptionData[i]"
                            [value]="serviceOption"
                          >
                            {{ serviceOption.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- ESTIMASI DAN DETAIL PAKET  -->
                  <div class="row col-6 pl-0 mr-3">
                    <!-- BERAT BARANG -->
                    <div class="col-sm-12 pb-2">
                      <label class="label"
                        >{{ translateArr.bp13
                        }}<span style="color: red">*</span></label
                      >
                      <div class="input-group mb-3">
                        <input
                          numbersOnly
                          class="form-control"
                          [formControl]="user.controls['goods_weight']"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">kg</span>
                        </div>
                        <mat-hint
                          *ngIf="
                            user.controls['goods_weight'].hasError(
                              'required'
                            ) && user.controls['goods_weight'].touched
                          "
                          class="text-danger"
                          >Mohon masukkan berat barang (berat minimum 1kg)
                        </mat-hint>
                      </div>
                    </div>

                    <!-- TOTAL BERAT BARANG  -->
                    <div class="row wrap">
                      <div class="pb-3 pl-4 fw-6" style="font-size: 13px">
                        {{ translateArr.bp35 }} :
                        <strong>{{ volumeSum[i] }} Kg</strong>
                      </div>
                      <div class="pb-3 pl-4 fw-6" style="font-size: 13px">
                        {{ translateArr.bp21 }} :
                        <strong>{{ weightSum[i] }} Kg</strong>
                      </div>
                      <div class="pb-3 pl-4 fw-6" style="font-size: 13px">
                        {{ translateArr.bp34 }} :
                        <strong>{{ inputSum[i] }} Kg</strong>
                      </div>
                    </div>

                    <!-- CHECKBOX FITUR VOLUME DAN ASURANSI -->
                    <div class="col-12 pb-2 row ml-0 pb-3 pt-2">
                      <!-- Checkbox Pakai volume -->
                      <div class="form-check mr-4">
                        <mat-checkbox
                          class="example-margin"
                          formControlName="volChecked"
                        >
                          <label class="label" for="exampleCheck1">{{
                            translateArr.bp15
                          }}</label>
                        </mat-checkbox>
                      </div>
                      <!-- Checkbox Asuransi -->
                      <div class="form-check mr-4">
                        <mat-checkbox
                          class="example-margin"
                          formControlName="insChecked"
                        >
                          <label class="label" for="exampleCheck2">{{
                            translateArr.bp16
                          }}</label>
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>

                  <!-- KUANTITI KOLI -->
                  <div class="row col-6 pl-0">
                    <div class="col-sm-12 pb-2">
                      <div>
                        <label class="label">{{ translateArr.bp14 }}</label>
                        <input
                          type="number"
                          class="form-control"
                          [formControl]="user.controls['goods_koli']"
                        />
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['goods_koli'].hasError('required') &&
                          user.controls['goods_koli'].touched
                        "
                        class="text-danger"
                        >Mohon masukkan jumlah barang (jumlah minimum 1)
                      </mat-hint>
                    </div>
                  </div>

                  <!-- FORM HARGA BARANG UNTUK ASURANSI -->
                  <div class="row col-12 pl-0" *ngIf="showInsForm[i]">
                    <div class="col-sm-6 pb-4">
                      <label class="label">{{ translateArr.bp17 }}</label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2"
                            >Rp.</span
                          >
                        </div>
                        <input
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['goods_value']"
                          placeholder="Harga Barang"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- FORM PANJANG, LEBAR, TINGGI UNTUK BERAT VOLUMETRIK -->
                  <div class="row col-12 pl-0 pb-3" *ngIf="showDimForm[i]">
                    <div class="col-sm-4 pb-2">
                      <label class="label">{{ translateArr.bp18 }}</label>
                      <div class="input-group mb-2">
                        <input
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['goods_length']"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >cm</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 pb-2">
                      <label class="label">{{ translateArr.bp19 }}</label>
                      <div class="input-group mb-2">
                        <input
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['goods_width']"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >cm</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 pb-2">
                      <label class="label">{{ translateArr.bp20 }}</label>
                      <div class="input-group mb-2">
                        <input
                          class="form-control"
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['goods_heigth']"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >cm</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- FORM JENIS BARANG, JUMLAH KOLI, DAN KETERANGAN UNTUK KURIR-->
                  <div class="row col-12 pl-0">
                    <!-- JENIS BARANG -->
                    <div class="col-sm-6 pb-3">
                      <div>
                        <label class="label"
                          >{{ translateArr.bp22
                          }}<span style="color: red">*</span></label
                        >
                        <mat-form-field
                          class="input-group yeye mb-3"
                          appearance="none"
                        >
                          <mat-select
                            [formControl]="user.controls['content_type']"
                          >
                            <mat-option
                              *ngFor="let content_this of contentList"
                              [value]="content_this"
                            >
                              {{ content_this.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <mat-hint
                        *ngIf="
                          user.controls['content_type'].hasError('required') &&
                          user.controls['content_type'].touched
                        "
                        class="text-danger"
                        >Mohon dipilih jenis barang
                      </mat-hint>
                    </div>
                    <!-- JUMLAH KOLI -->
                    <div class="row col-6 pl-0">
                      <div class="col-sm-12 pb-2">
                        <div>
                          <label class="label">{{ translateArr.bp23 }}</label>
                          <input
                            type="text"
                            class="form-control"
                            [formControl]="user.controls['goods_desc']"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- KETERANGAN UNTUK KURIR -->
                  <div class="row col-12 pl-0">
                    <div class="col-sm-12 pb-2">
                      <label class="label">{{ translateArr.bp25 }}</label>
                      <div class="input-group mb-2">
                        <textarea
                          class="form-control"
                          rows="3"
                          matInput
                          aria-describedby="basic-addon2"
                          [formControl]="user.controls['notes_instructions']"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-accordion>

  <!-- BUTTON TAMBAH TUJUAN -->
  <div
    class="mr-3 mt-sm-3 w-500"
    style="display: flex; flex-direction: column; align-items: flex-end"
  >
    <div *ngIf="!showAddButton">
      <p>Masukan maksimal 10 pesanan</p>
    </div>
    <div *ngIf="!showDeleteButton">
      <p>Masukan minimal 1 pesanan</p>
    </div>
  </div>
  <div #receiverPanel class="addRec-row">
    <div *ngIf="showDeleteButton">
      <button
        class="btn button deleteRec-button mr-3 mt-sm-3 w-500"
        type="button"
        (click)="deleteReceiver()"
      >
        <mat-icon>delete_outline</mat-icon>
        Hapus Penerima
      </button>
    </div>
    <div *ngIf="showAddButton">
      <button
        class="btn button addRec-button mr-3 mt-sm-3 w-500"
        type="button"
        (click)="addReceiver()"
      >
        <mat-icon>note_add</mat-icon>
        Tambah Penerima
      </button>
    </div>
  </div>
  <form [formGroup]="form">
    <div formArrayName="users">
      <div *ngFor="let user of users.controls; let i = index">
        <div *ngIf="showFormGroup[i]">
          <div [formGroupName]="i">
            <!-- ESTIMASI PER PENERIMA -->
            <div class="multi-estimate">
              <div class="col-12 pt-5 text-right">
                <div class="col-12 row pr-0 pb-2">
                  <div class="col-12 row pr-0 pb-2">
                    <div class="col-12 text-right pr-0">
                      <h4>
                        <strong> Penerima {{ i + 1 }} : </strong>
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp26 }} :
                  </div>
                  <div class="col-4 text-right">{{ minimalWeight[i] }} Kg</div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">Berat Maximal :</div>
                  <div class="col-4 text-right">{{ maximalWeight[i] }} Kg</div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp34 }} :
                  </div>
                  <div class="col-4 text-right">{{ weightSum[i] }} Kg</div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp35 }} :
                  </div>
                  <div class="col-4 text-right">{{ volumeSum[i] }} Kg</div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp27 }} :
                  </div>
                  <div class="col-4 text-right">
                    {{ serviceCost[i] | currency : "Rp. " }}
                  </div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp28 }} :
                  </div>
                  <div class="col-4 text-right">
                    {{ insuranceCost[i] | currency : "Rp. " }}
                  </div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">Estimasi Biaya :</div>
                  <div class="col-4 text-right">
                    {{ totalCostPerOnce[i] | currency : "Rp. " }}
                  </div>
                </div>

                <div class="col-12 row pr-0 pb-2">
                  <div class="col-8 text-right pr-0">
                    {{ translateArr.bp29 }} :
                  </div>
                  <div class="col-4 text-right">
                    {{ serviceLeadTime[i] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- ESTIMASI TOTAL -->
  <div class="col-12 pt-5 text-right">
    <div class="col-12 row pr-0 pb-2">
      <div class="col-12 text-right pr-0">
        <h4><strong> Estimasi Biaya Keseluruhan : </strong></h4>
      </div>
    </div>
    <div class="text-right pb-2">
      <div class="col-12 row pr-0">
        <div class="col-8 pr-0 total-booking"></div>
        <div class="col-4 text-right total-booking">
          {{ finalTotalCost | currency : "Rp. " }}
        </div>
      </div>
    </div>

    <!-- CHECK BOX PERIKSA DATA -->
    <div class="data-check mr-3">
      <mat-checkbox
        class="verification-check"
        type="checkbox"
        [(ngModel)]="verChecked"
        required
      >
        <label class="ver-label"
          >Verifikasi pemesanan<span style="color: red">*</span></label
        >
      </mat-checkbox>
      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>

    <!-- BUTTON RESET BOOK -->
    <button
      class="btn button cancel-button mr-3 mt-sm-3 w-500"
      type="submit"
      (click)="onReset()"
    >
      {{ translateArr.bp31 }}
    </button>

    <!-- BUTTON BOOK NOW -->
    <button
      class="btn button mt-sm-3 w-500 mr-3"
      type="submit"
      (click)="bookNow()"
    >
      {{ translateArr.bp32 }}
    </button>
  </div>
</div>
