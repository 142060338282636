<div style="text-align: center;" *ngIf="isLoading">
  <br>
  <mat-spinner style="margin:0 auto;"></mat-spinner><br>
  <h4>Load detail booking ...</h4>
</div>

<div class="card" *ngIf="!isLoading" #panel>
  <a href="/user/my-booking">
    <h3 class="contact-title"><span class="material-icons">arrow_back</span>{{translateArr.bp1}}</h3>
  </a>
  <h5 class="text-secondary fw-3 pt-3">{{translateArr.bp2}} : <b>{{detailBooking.booking_id}}</b></h5>
  <h5 class="text-secondary fw-3 pb-3">{{translateArr.bp3}} : {{detailBooking.type}}</h5>
  <div class="status mb-4">{{translateArr.bp4}} :
    <span style="color: red;">{{detailBooking.status}}
      <span class=" question-tag ml-1" mat-raised-button #tooltip="matTooltip" matTooltipPosition="right"
        matTooltipClass="example-tooltip"
        [matTooltip]="detailBooking.status == 'Waiting Agent Confirmation' ? 'Pengirim diharap melakukan konfirmasi pengiriman paket di cabang ONDELIVERY terdekat, daftar pesanan dengan status Walk In akan hilang dalam 24 jam' : detailBooking.status == 'Paket Menunggu Penjemputan' ? 'Harap menunggu, paket akan segera di konfirmasi dan di pick up oleh kurir kurang dari 1 jam dari pesanan booking' : detailBooking.status == 'Paket Sudah dalam Penjemputan' ? 'Harap tunggu, Paket sudah dalam penjemputan oleh kurir. Paket dalam status ini tidak dapat melakukan CANCEL.': 'null' ">?</span>
    </span>
  </div>
  <div>
    <div class="pb-5">
      <h4>{{translateArr.bp5}}</h4>
      <hr />
      <div class="col-12 row pr-0">
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp6}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].sender_name}}</p>
          </div>
        </div>

        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp7}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].sender_phone}}</p>
          </div>
        </div>

        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp8}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].sender_address}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-5">
      <h4>{{translateArr.bp9}}</h4>
      <hr />
      <div class="col-12 row pr-0">
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp10}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].receiver_name}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp11}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].receiver_phone}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp12}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].receiver_address}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-5">
      <h4>{{translateArr.bp13}}</h4>
      <hr />
      <div class="col-12 row pr-0">
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp14}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].goods_weight}} Kg</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp15}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].length}} cm x {{detailBooking.package_info[0].width}} cm x
              {{detailBooking.package_info[0].height}} cm</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp16}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].goods_desc}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3">
      <h4>{{translateArr.bp17}}</h4>
      <hr />
      <div class="col-12 row pr-0">
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp18}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].estimate_price}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-6 pr-0">
            <p>{{translateArr.bp19}}</p>
          </div>
          <div class="col-6 pr-0">
            <p>: {{detailBooking.package_info[0].notes}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-5 text-right">
      <a class="btn mt-sm-3" style="color: #fff;" type="submit" (click)="cancel(detailBooking.booking_id)"
        [ngClass]="{'disabled' : detailBooking.status === 'Paket Sudah dalam Penjemputan' }">{{translateArr.bp20}}</a>
    </div>
  </div>
</div>