import { Component, OnInit } from '@angular/core';
import blackpink from './feature-one-translate.json'

@Component({
  selector: 'app-feature-one',
  templateUrl: './feature-one.component.html',
  styleUrls: ['./feature-one.component.css']
})
export class FeatureOneComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
