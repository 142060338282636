import { Component, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Inject, Optional } from '@angular/core'
import blackpink from './success-new-password-translate.json'

@Component({
  selector: 'app-success-new-password',
  templateUrl: './success-new-password.component.html',
  styleUrls: ['./success-new-password.component.css']
})
export class SuccessNewPasswordComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(public dialogRef: MatDialogRef<SuccessNewPasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

    OK(): void{
      this.dialogRef.close()
    }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  
}
 