import { Component, OnInit } from '@angular/core';
// import blackpink from './contact-translate.json'

@Component({
  selector: 'app-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.css']
})
export class ReviewPageComponent implements OnInit {
  // translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    // this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
