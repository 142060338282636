<!-- <section class="section breadcrumb-area bg-overlay d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="breadcrumb-content d-flex flex-column align-items-center text-center"
        >
          <h2 class="text-white text-capitalize">{{ translateArr.bp1 }}</h2>
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item">
              <a class="text-uppercase text-white" href="index.html">{{
                translateArr.bp2
              }}</a>
            </li>
            <li class="breadcrumb-item text-white active">
              {{ translateArr.bp3 }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section> -->

<div class="main">
  <app-scrollup></app-scrollup>
  <!-- <app-main-header></app-main-header> -->
  <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
  <section class="section service-area overflow-hidden">
    <div class="image-container">
      <img
        src="https://storage.onindonesia.id/ondelivery.id/about-us/thumbnail about us-min.png"
        alt="ONDELIVERY Express"
        class="background-image"
        loading="lazy"
      />
      <div class="text-overlay">
        <h1 class="text-white">
          Indonesia's Logistics <br />
          Service Provider
        </h1>
        <!-- <div class="button-custom-padding">
            <a href="/cost-estimation">
              <button mat-raised-button color="primary">Learn More</button>
            </a>
          </div> -->
      </div>
    </div>
  </section>
  <section class="discover-area overflow-hidden">
    <div class="container">
      <div class="text-center" style="padding-bottom: 20px; padding-top: 20px">
        <h2>{{ translateArr.bp20 }}</h2>
        <div>
          <p>{{ translateArr.bp31 }}</p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container m-auto">
      <div
        class="row col-sm-12 m-auto"
        style="justify-content: space-evenly; gap: 10px"
      >
        <mat-card
          class="col-sm-3"
          style="
            background-color: #1254a0;
            color: white;
            height: 150px;
            border-radius: 12px;
          "
        >
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              <h2 class="text-card-about">10.000+</h2>
            </div>
            <div>
              <h4 class="text-card-about" style="font-weight: 600">
                Pengguna Terintegrasi
              </h4>
            </div>
          </div>
        </mat-card>
        <mat-card
          class="col-sm-3"
          style="
            background-color: #1254a0;
            color: white;
            height: 150px;
            border-radius: 12px;
          "
        >
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              <h2 class="text-card-about">500+</h2>
            </div>
            <div>
              <h4 class="text-card-about" style="font-weight: 600">
                Partner Brand Lokal
              </h4>
            </div>
          </div>
        </mat-card>
        <mat-card
          class="col-sm-3"
          style="
            background-color: #1254a0;
            color: white;
            height: 150px;
            border-radius: 12px;
          "
        >
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              <h2 class="text-card-about">100+</h2>
            </div>
            <div>
              <h4 class="text-card-about" style="font-weight: 600">
                Mitra Keagenan
              </h4>
            </div>
          </div>
        </mat-card>
      </div>
      <div
        class="row col-sm-12 m-auto ptb_150"
        style="display: flex; justify-content: center; gap: 20px"
      >
        <mat-card
          class="col-sm-5 col-md-4 col-lg-3"
          style="
            background-color: #1254a0;
            height: 400px;

            border-radius: 12px;
          "
        >
          <div style="height: 100%; display: flex; flex-direction: column">
            <div>
              <h2 style="color: white">Visi</h2>
            </div>
            <div>
              <h4 class="text-white">
                Menjadi solusi logistik No.1 di Indonesia terpercaya dan dapat
                diandalkan dalam ketepatan waktu serta keamanan barang
              </h4>
            </div>
            <img
              src="https://storage.onindonesia.id/ondelivery.id/about-us/visi-icon 1.png"
              alt=""
              class="icon-visi-misi"
              loading="lazy"
            />
          </div>
        </mat-card>
        <mat-card
          class="col-sm-5 col-md-4 col-lg-3"
          style="background-color: #1254a0; height: 400px; border-radius: 12px"
        >
          <div style="height: 100%; display: flex; flex-direction: column">
            <div>
              <h2 style="color: white">Misi</h2>
            </div>
            <div>
              <h4 class="text-white">
                Memberikan inovasi dalam setiap pengembangan layanan perusahaan
                yang terpercaya, aman, dan terjangkau guna menciptakan bisnis
                yang berkelanjutan
              </h4>
            </div>
            <img
              src="https://storage.onindonesia.id/ondelivery.id/about-us/visi-icon 1.png"
              alt=""
              class="icon-visi-misi"
            />
          </div>
        </mat-card>
      </div>
    </div>
  </section>
  <section class="discover-area overflow-hidden section section ptb_150">
    <div class="container m-auto">
      <div class="text-center" style="padding-bottom: 20px">
        <h2>{{ translateArr.bp32 }}</h2>
      </div>
      <div
        class="row col-sm-12 m-auto d-flex"
        style="justify-content: space-evenly; gap: 10px; flex-wrap: nowrap"
      >
        <div class="col-sm-6">
          <img
            style="border-radius: 20px; width: 100%"
            src="https://storage.onindonesia.id/ondelivery.id/about-us/Serve ( company Value )-min.png"
            alt=""
          />
          <h3 class="pt-4">Serve</h3>
          <h4>
            Dedikasi memberikan layanan sepenuh hati yang berkualitas dan
            memuaskan
          </h4>
        </div>
        <div class="col-sm-6">
          <img
            style="border-radius: 20px; width: 100%"
            src="https://storage.onindonesia.id/ondelivery.id/about-us/Agile (company value)-min.png"
            alt=""
            loading="lazy"
          />
          <h3 class="pt-4">Agile</h3>
          <h4>
            Fleksibilitas dan responsivitas yang tinggi dalam pemenuhan
            kebutuhan unik pelanggan
          </h4>
        </div>
      </div>
      <div
        class="row col-sm-12 m-auto d-flex pt-4"
        style="justify-content: space-evenly; gap: 10px; flex-wrap: nowrap"
      >
        <div class="col-sm-6">
          <img
            style="border-radius: 20px; width: 100%"
            src="https://storage.onindonesia.id/ondelivery.id/about-us/Affordable (company value)-min.png"
            alt=""
            loading="lazy"
          />
          <h3 class="pt-4">Affordable</h3>
          <h4>Biaya pengiriman yang terjangkau berlaku semua layanan</h4>
        </div>
        <div class="col-sm-6">
          <img
            style="border-radius: 20px; width: 100%"
            src="https://storage.onindonesia.id/ondelivery.id/about-us/Adaptive (company value)-min.png"
            alt=""
            loading="lazy"
          />
          <h3 class="pt-4">Adaptive</h3>
          <h4>
            Perusahaan terus berevolusi seiring waktu mengikuti tren dan
            kebutuhan pasar
          </h4>
        </div>
      </div>
    </div>
  </section>
  <section class="section discover-area overflow-hidden">
    <div class="container">
      <div class="text-center">
        <h2>Partner Bisnis</h2>
        <h3>Lebih dari 500+ Lokal Brand Aktif</h3>
      </div>
      <div class="row">
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content">
            <ng-container *ngFor="let image of imagesPartners">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Brand Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content">
            <ng-container *ngFor="let image of imagesPartners2">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Brand Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="section discover-area overflow-hidden"
    style="padding: 80px 0"
  >
    <div class="container">
      <div class="text-center">
        <h2>Publikasi Media</h2>
      </div>
      <div class="row">
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content2">
            <ng-container *ngFor="let image of imagesPublications">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Publication Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="marquee-container" style="padding: 20px 0">
          <div class="marquee-content2">
            <ng-container *ngFor="let image of imagesPublications2">
              <div class="marquee-item">
                <img
                  style="width: 100px"
                  [src]="image"
                  alt="Publication Image"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container" style="padding-top: 50px">
    <div class="card-section">
      <div class="image-content">
        <img
          src="https://storage.onindonesia.id/ondelivery.id/about-us/logo-banner-download-kami.png"
          alt="OnApps Banner"
          class="image-responsive"
          loading="lazy"
        />
      </div>
      <div class="description-content">
        <div style="padding: 40px 10px">
          <div class="first-paragraph">
            Ayo, Mulai Pengirimanmu dari Sekarang
          </div>
          <div class="second-paragraph">
            Semua dapat memulai pengiriman layaknya profesional dengan
            ONDELIVERY. Tanpa perlu pengalaman. Tanpa harus bingung. Tinggal
            terima beres.
          </div>
          <div class="button-download">
            <div class="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
                target="_blank"
              >
                <img
                  style="cursor: pointer"
                  src="https://storage.onindonesia.id/ondelivery.id/Badges-google-play.svg"
                  alt="Google Play"
                  loading="lazy"
                />
              </a>
              <a
                href="https://apps.apple.com/id/app/onapps-indonesia/id1587666667"
                target="_blank"
              >
                <img
                  style="cursor: pointer"
                  src="https://storage.onindonesia.id/ondelivery.id/Badges-app-store.svg"
                  alt="App Store"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="section discover-area overflow-hidden ptb_100">
      <div class="container">
        <div class="section-heading text-center" style="padding-bottom: 20px">
          <h2>{{ translateArr.bp20 }}</h2>
        </div>
        <ng-container *ngIf="translate === 'idn'">
          <div class="h-100 d-flex align-items-center justify-content-center">
            <img
              src="assets/img/Asset_Competitive Advantages Indonesia.png"
              alt=""
              style="max-width: 100%"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="translate === 'eng'">
          <div class="h-100 d-flex align-items-center justify-content-center">
            <img
              src="assets/img/Asset_Competitive Advantages English.png"
              alt=""
              style="max-width: 100%"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="translate === 'cn'">
          <div class="h-100 d-flex align-items-center justify-content-center">
            <img
              src="assets/img/Asset_Competitive Advantages English.png"
              alt=""
              style="max-width: 100%"
            />
          </div>
        </ng-container>

      </div>
    </section> -->
  <!-- <section class="section discover-area overflow-hidden ptb_100">
      <div class="container">
        <div class="pb-lg-5 pb-md-1 pb-sm-0 col-12">
          <div class="section-heading text-center">
            <h2>{{ translateArr.bp8 }}</h2>
          </div>
        </div>
        <div class="row justify-content-center align-items-start">
          <div class="col-12 col-md-8 col-lg-6 order-2 order-lg-1">
      
            <div
              class="service-thumb discover-thumb mx-auto text-center pt-5 pt-lg-0"
            >
              <img src="assets/img/about1.jpg" alt="" class="image-about" />
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
         
            <div class="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
              <ul class="service-text">
          
                <li class="single-service py-2">
                  <div class="service-text">
                    <p>{{ translateArr.bp9 }}</p>
                  </div>
                </li>
     
                <li class="single-service py-2">
                  <div class="service-text">
                    <p>{{ translateArr.bp10 }}</p>
                  </div>
                </li>
        
                <li class="single-service py-2">
                  <div class="service-text">
                    <p>{{ translateArr.bp11 }}</p>
                  </div>
                </li>
       
                <li class="single-service py-2">
                  <div class="service-text">
                    <p>{{ translateArr.bp12 }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  <!-- <section>
      <div class="container">
        <div class="section-heading text-center" style="padding-bottom: 10px">
          <h2>{{ translateArr.bp21 }}</h2>
        </div>
        <div class="text-center">
          <p>{{ translateArr.bp23 }}</p>
        </div>
        <div class="h-100 d-flex align-items-center justify-content-center">
          <img
            src="assets/img/Asset_Brand Partner.png"
            alt=""
            style="max-width: 100%"
          />
        </div>
      </div>
    </section> -->
  <!-- <section>
      <div class="container">
        <div
          class="section-heading text-center"
          style="padding-bottom: 10px; padding-top: 20px"
        >
          <h2>{{ translateArr.bp22 }}</h2>
        </div>
        <div class="h-100 d-flex align-items-center justify-content-center">
          <img
            src="assets/img/Asset_Media.png"
            alt=""
            style="max-width: 100%; padding-bottom: 10px"
          />
        </div>
      </div>
    </section> -->
  <div style="padding: 50px 0 0 0">
    <app-footer-one></app-footer-one>
  </div>
</div>
