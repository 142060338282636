<section class="section service-area overflow-hidden">
  <div class="image-container ptb_100">
    <img
      src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/thumbnail keagenan.png"
      alt="ONDELIVERY Logistics"
      class="background-image"
      loading="lazy"
    />

    <div class="text-overlay">
      <h1 class="text-white">
        Peluang Bisnis <br />
        Mitra Keagenan
      </h1>
      <div class="button-custom-padding">
        <a href="/keagenan">
          <button mat-raised-button color="primary">Gabung Sekarang</button>
        </a>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- <div class="d-flex row col-sm-12 col-lg-12 col-md-12 m-auto">
      <div
        class="col-sm-6 col-md-4 col-lg-6 column"
        style="display: flex; align-items: center"
      >
        <div class="row m-auto">
          <h1>Peluang Bisnis Mitra Keagenan</h1>

          <a href="/keagenan" style="padding-bottom: 10px">
            <button
              mat-flat-button
              style="
                color: white;
                background: #1254a0;
                border-radius: 8px;
                padding: 8px 15px;
              "
            >
              <mat-icon>people</mat-icon> Gabung Sekarang
            </button>
          </a>
        </div>
      </div>

      <div class="col-sm-6 col-md-8 col-lg-6">
        <img
          src="assets/img/agent-inquiries/thumbnail keagenan.png"
          style="width: 100%; height: 100%; border-radius: 8px"
          alt=""
          loading="lazy"
        />
      </div>
    </div> -->
    <div class="d-flex row col-sm-12 col-lg-12 col-md-12 m-auto">
      <div style="padding: 10px 30px">
        <h2>Pilihan Menguntungkan Gabung Keagenan ONDELIVERY</h2>
      </div>
      <div style="padding: 10px 30px">
        <h4>
          Agen ONDELIVERY menjadi peluang bisnis menarik dengan potensi
          keuntungan yang signifikan, stabil dan berkelanjutan. Kirim paket
          dengan tarif hemat yang mendukung pertumbuhan belanja dan pedagangan
          elektronik yang pesat
        </h4>
      </div>
    </div>

    <div class="row col-sm-12 col-lg-12 col-md-12 m-auto">
      <div
        class="d-flex justify-content-center col-12"
        style="padding-bottom: 50px"
      >
        <h2>Keuntungan Menjadi Agen ONDELIVERY</h2>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo keuntungan besar.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Keuntungan yang Besar</h3>
          <h4>Dapatkan keuntungan sebesar 10% untuk agen setiap bulannya</h4>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo harga terjangkau.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Harga Kompetitif dan Terjangkau</h3>
          <h4>
            Biaya kiriman semua layanan lebih murah dibandingkan kompetitor lain
          </h4>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo syarat bergabung.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Syarat Bergabung Mudah</h3>
          <h4>Kemitraan dengan proses mudah anti ribet dan fleksible</h4>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo reward.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Reward</h3>
          <h4>Banyak program reward dengan berbagai macam hadiah menarik</h4>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo jangkauan area luas.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Jangkauan Area yang Luas</h3>
          <h4>
            ONDELIVERY menjangkau ke seluruh Indonesia dan memiliki tim handal
            di bidang logistik
          </h4>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 spacer-top-bottom row">
        <div class="col-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/agent-inquiries/Logo komisi.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="pl-3 col-10">
          <h3>Komisi Pick up & Delivery</h3>
          <h4>
            Komisi fee dari Pick up & Delivery untuk agen dari setiap kiriman
            penjualan yang menarik hingga 35% dari pendapatan tiap bulannya.
          </h4>
        </div>
      </div>
    </div>

    <div
      class="row col-sm-12 col-lg-12 col-md-12 m-auto"
      style="padding: 60px 0"
    >
      <div
        class="d-flex justify-content-center col-12"
        style="padding-bottom: 50px"
      >
        <h2>4 Langkah Mudah, Menjadi Agent ONDELIVERY</h2>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 spacer-top-bottom row">
      <div class="col-1">
        <h1>1</h1>
      </div>
      <div class="pl-3 col-10">
        <h3>Pengajuan Formulir</h3>
        <h4>
          Komisi fee dari Pick up & Delivery untuk agen dari setiap kiriman
          penjualan yang menarik hingga 35% dari pendapatan tiap bulannya.
        </h4>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 spacer-top-bottom row">
      <div class="col-1">
        <h1>2</h1>
      </div>
      <div class="pl-3 col-10">
        <h3>Persetujuan & Pembayaran Administrasi</h3>
        <h4>
          Kelengkapan administrasi seperti fotocopy KTP, NPWP dan dokumen
          lainnya
        </h4>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 spacer-top-bottom row">
      <div class="col-1">
        <h1>3</h1>
      </div>
      <div class="pl-3 col-10">
        <h3>Persediaan SDM</h3>
        <h4>Menyediakan SDM minimal 1 orang untuk admin outlet</h4>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 spacer-top-bottom row">
      <div class="col-1">
        <h1>4</h1>
      </div>
      <div class="pl-3 col-10">
        <h3>Persediaan Alat Transportasi</h3>
        <h4>
          Menyiapkan alat transportasi berupa motor atau lainnya untuk menunjang
          proses operasional
        </h4>
      </div>
    </div>

    <div class="col-sm-12 col-lg-12 col-md-12 ptb_100">
      <div
        class="d-flex justify-content-center col-12"
        style="padding-bottom: 50px"
      >
        <h2>Apa Kata Mereka Agen ONDELIVERY?</h2>
      </div>
      <div
        class="row col-sm-12 col-md-12 col-lg-12"
        style="
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: center;
        "
      >
        <mat-card class="col-sm-12 col-md-3 col-lg-3 card-comment">
          <div style="display: flex; justify-content: center">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/avatar.svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="text-center pt-3">
            <h3>OMA Cakung</h3>
            <h4>Michael Wijaya</h4>
            <h4>
              “Menjadi mitra agen ONDELIVERY adalah keputusan terbaik bagi
              bisnis saya. Gerai layanan kami selalu ramai dan pelanggan sangat
              puas dengan layanan yang kami tawarkan”
            </h4>
          </div>
        </mat-card>

        <mat-card class="col-sm-12 col-md-3 col-lg-3 card-comment">
          <div style="display: flex; justify-content: center">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/avatar.svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="text-center pt-3">
            <h3>OMA Kelapa Gading</h3>
            <h4>Heru Sutiono</h4>
            <h4>
              “Membuka gerai ONDELIVERY memberi kami kesempatan untuk
              mengembangkan bisnis dengan dukungan penuh dari tim yang
              profesional. Sangat direkomendasikan bagi siapa pun yang ingin
              memulai usaha logistik.”
            </h4>
          </div>
        </mat-card>
        <mat-card class="col-sm-12 col-md-3 col-lg-3 card-comment">
          <div style="display: flex; justify-content: center">
            <img
              loading="lazy"
              src="https://storage.onindonesia.id/ondelivery.id/svgs/avatar.svg"
              alt=""
            />
          </div>
          <div class="text-center pt-3">
            <h3>OMA Tanjung Duren</h3>
            <h4>Dinda Tanizah</h4>
            <h4>
              “Sebagai mitra agen ONDELIVERY, saya merasa didukung sepenuhnya.
              Sistem yang efisien dan tim yang responsif membuat operasional
              sehari-hari berjalan lancar dan menguntungkan.”
            </h4>
          </div>
        </mat-card>
        <mat-card class="col-sm-12 col-md-3 col-lg-3 card-comment">
          <div style="display: flex; justify-content: center">
            <img
              loading="lazy"
              src="https://storage.onindonesia.id/ondelivery.id/svgs/avatar.svg"
              alt=""
            />
          </div>
          <div class="text-center pt-3">
            <h3>OMA Tambora</h3>
            <h4>Muhammad Rizky</h4>
            <h4>
              “Layanan pengiriman ONDELIVERY yang cepat dan handal telah membuat
              pelanggan kami puas dan terus kembali.”
            </h4>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- <div class="row col-sm-12 col-lg-12 col-md-12 m-auto">
      <div class="d-flex justify-content-center col-12">
        <h2>Mau Menjadi Seperti Mereka?</h2>
      </div>
      <div class="d-flex justify-content-center col-12">
        <h2>Ayo Gabung Keagenan ONDELIVERY Sekarang!</h2>
      </div>
      <div class="d-flex justify-content-center col-12">
        <a href="/keagenan">
          <button
            mat-flat-button
            style="
              color: white;
              background: #1254a0;
              border-radius: 8px;
              padding: 8px 15px;
            "
          >
            <mat-icon>people</mat-icon> Gabung Sekarang
          </button>
        </a>
      </div>
      <div class="column">
        <h4 style="font-weight: 500">Informasi Keagenan</h4>
        <div style="display: flex; flex-direction: row">
          <mat-icon>phone</mat-icon>
          <h4>+62 812-1005-3430</h4>
        </div>
      </div>
    </div> -->

    <section class="center-content" style="padding: 50px 0">
      <div class="card-section">
        <div class="description-content">
          <div class="padding-description">
            <div class="first-paragraph">Mau Menjadi Seperti Mereka?</div>
            <div class="second-paragraph">
              Ayo Gabung Keagenan ONDELIVERY Sekarang!
              <span style="visibility: hidden"
                >Semua dapat memulai pengiriman layaknya .</span
              >
            </div>
            <div style="padding-top: 20px">
              <a href="/keagenan">
                <button
                  mat-flat-button
                  style="
                    color: white;
                    background: #1254a0;
                    border-radius: 8px;
                    padding: 8px 15px;
                  "
                >
                  Gabung Sekarang
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="image-content">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/about-us/logo-banner-download-kami.png"
            alt="OnApps Banner"
            class="image-responsive"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  </div>
  <app-footer-one></app-footer-one>
</section>
