
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { MatDialog } from '@angular/material/dialog';
import blackpink from './courier-partner-translate.json';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { Subscription } from "rxjs";
import { distinctUntilChanged, } from "rxjs/operators";
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-business-inquiries',
  templateUrl: './courier-partner.component.html',
  styleUrls: ['./courier-partner.component.scss'],
  providers: [
    ApiService,
  ]
})
export class CourierPartnerComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  registerData: any;
  dataAgent: any;
  operation_city_subs: Subscription;
  form: FormGroup;
  dataLocation: any;
  filteredData: object[];
  formattedDate: any;
  isCityValid: boolean = true;

  errorRegister: string | null = null

  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }

  @ViewChild("panel") public panel: ElementRef;

  ngOnInit(): void {

    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    }, 500);

    // Retrieve the register data from local storage
    const storedData = localStorage.getItem('registerData');
    this.registerData = storedData ? JSON.parse(storedData) : [];
    this.translateArr = blackpink[localStorage.getItem("translate")]
    this.service.searchLocation("").subscribe(response => {
      this.dataLocation = response
      // console.log(response)
    })

    if (typeof this.registerData.operation_city !== "object" && this.registerData.operation_city !== null && this.registerData.operation_city !== undefined) {
      this.isCityValid = false
    }

    this.form = this.fb.group({
      name: [this.registerData.name, Validators.compose([Validators.required])],
      email: [this.registerData.email, Validators.compose([Validators.required])],
      no_ktp: [this.registerData.no_ktp, Validators.compose([Validators.pattern("^[0-9]+$"), Validators.required])],
      birth_date: [this.registerData.birth_date, Validators.compose([Validators.required])],
      phone: [this.registerData.phone,
      Validators.compose([
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$'),
        Validators.required,
        Validators.required,
        Validators.minLength(5)
      ])
      ],
      address: [this.registerData.address, Validators.required],
      operation_city: [this.registerData.operation_city, Validators.compose([Validators.pattern("^ [a - zA - Z] + $"), Validators.required])],
      no_sim: [this.registerData.no_sim, Validators.compose([Validators.pattern("^[0-9]+$"), Validators.required])],
      no_stnk: [this.registerData.no_stnk, Validators.compose([Validators.pattern("^[0-9]+$"), Validators.required])],
      no_npwp: [this.registerData.no_npwp, Validators.compose([Validators.pattern("^[0-9]+$"), Validators.required])],
    });


    this.form.get("operation_city")!.valueChanges.pipe(distinctUntilChanged())
      .subscribe((term) => {
        if (term !== "") {
          this.filteredData = this.dataLocation.filter(item => {
            const searchTerm = typeof term === 'string' ? term.toLowerCase() : '';
            return item.location_name.toLowerCase().includes(searchTerm);
          });
        }

        if (typeof term !== "object") {
          this.isCityValid = false
        } else {
          this.isCityValid = true
        }
      });

    console.log(this.form.value.operation_city)
  }


  async addRegisterData() {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    const formValues = this.form.value;
    const allFieldsFilled = Object.values(formValues).every(value => value !== null && value !== '' && value !== undefined);

    // console.log(this.registerData.birth_date)
    // console.log(formValues.birth_date)
    if (!regex.test(formValues.birth_date)) {
      const year = formValues.birth_date.getFullYear();
      const month = String(formValues.birth_date.getMonth() + 1).padStart(2, "0");
      const day = String(formValues.birth_date.getDate()).padStart(2, "0");
      this.formattedDate = `${year}-${month}-${day}`;
    } else {
      this.formattedDate = this.registerData.birth_date
    }
    // Create an object array to store the form data
    this.registerData =
    {
      //For Payload Data
      name: formValues?.name,
      no_ktp: formValues?.no_ktp,
      birth_date: this.formattedDate,
      phone: formValues?.phone,
      address: formValues?.address,
      operation_city: formValues?.operation_city,
      no_sim: formValues?.no_sim,
      no_stnk: formValues?.no_stnk,
      no_npwp: formValues?.no_npwp,
      email: formValues?.email,
      username: formValues?.email,
      password: formValues?.email
    };

    if (typeof formValues.operation_city !== "object") {
      if (this.filteredData.length === 1) {
        this.registerData.operation_city = this.filteredData[0]
        this.isCityValid = true
      }
    }
    // Save the registerData to local storage
    localStorage.setItem('registerData', JSON.stringify(this.registerData));
    // console.log(this.registerData)

    if (this.isCityValid) {
      if (allFieldsFilled) {
        // console.log(this.registerData)
        const storedData = localStorage.getItem('registerData');
        this.registerData = storedData ? JSON.parse(storedData) : [];

        let last_name: string;
        const nameParts = this.registerData.name.split(" ");

        if (nameParts.length > 1) {
          last_name = nameParts.slice(1, nameParts.length).join(" ");
        } else {
          last_name = nameParts[0];
        }

        const params = {
          username: this.registerData.email.split("@")[0],
          password: this.registerData.email,
          email: this.registerData.email,
          first_name: this.registerData.name.split(" ")[0],
          last_name: last_name,
          phone_number: "62" + this.registerData.phone.substring(1),
          mode: "OTP"
        }

        const localJwt = localStorage.getItem('jwt');
        if (localJwt) {
          this.spinner.show();

          setTimeout(() => {
            this.spinner.hide();
            this.router.navigate(["/upload-document"])
          }, 1500);
        } else {
          await this.service.signupOTP(JSON.stringify(params)).subscribe(response => {
            // console.log(response)
            const token = response
            localStorage.setItem('token', JSON.stringify(token));
            this.spinner.show();

            setTimeout(() => {
              this.spinner.hide();
              this.router.navigate(["courier-partner/otp"])
            }, 1500);
          },
            error => {
              Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: `${error.error.message}`,
              });
              if (error.error.message === "OTP still active, please wait 10 minutes") {

                this.spinner.show();

                setTimeout(() => {
                  this.spinner.hide();
                  this.router.navigate(["courier-partner/otp"])
                }, 1500);
              }
            }
          )
        }
      } else {
        // Display Modal ERROR
        Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: 'Pastikan semua data sudah lengkap.',
        });
      }
    } else {
      // Display Modal ERROR
      Swal.fire({
        icon: 'error',
        title: 'Gagal',
        text: 'Pastikan kota domisili valid.',
      });
    }
  }

  refresh() {
    // Clear the registerData from local storage
    localStorage.removeItem('registerData');
    location.reload();
  }

  displayFnLoc(locationOption: any): string {
    return locationOption?.location_name;
  }
}