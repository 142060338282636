<div #panel class="main">
  <!-- <app-scrollup></app-scrollup> -->
  <!-- <section>
    <app-banner></app-banner>
    <div class="buttons-section">
      <div class="row-dropdown">
        <div class="panel-wrapper">
          <mat-expansion-panel
            [expanded]="step === 1"
            (opened)="setStep(1)"
            hideToggle
          >
            <mat-expansion-panel-header #header1>
              <mat-panel-title>
                <div style="display: flex; flex-direction: column">
                  <span class="menu-title">SEARCH TRACK</span>
                  <span>Waybill Number</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form (ngSubmit)="searchAirwayBill()">
              <textarea
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Input Your Waybill Number"
                [formControl]="waybillForm.controls['waybill']"
                [ngStyle]="{ height: '130px' }"
              >
              </textarea>
              <h6>
                *Maksimum 10 Resi (Bedakan dengan koma (,) atau baris baru)
              </h6>
              <div class="d-flex justify-content-center gap-2">
                <button
                  mat-flat-button
                  type="submit"
                  class="btn"
                  [ngStyle]="{ color: 'white' }"
                >
                  Trace
                </button>
              </div>
            </form>
          </mat-expansion-panel>
        </div>

        <div class="panel-wrapper">
          <mat-expansion-panel
            [expanded]="step === 2"
            (opened)="setStep(2)"
            hideToggle
          >
            <mat-expansion-panel-header #header2>
              <mat-panel-title>
                <div style="display: flex; flex-direction: column">
                  <span class="menu-title">CHECK SHIPPING FEE</span>
                  <span>Check Your Shipping Fee</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form (ngSubmit)="checkPrice()">
              <div>
                <div class="row align-items-center mb-2">
                  <div class="col-md-2"><label for="origin">From</label></div>
                  <div class="col">
                    <input
                      matInput
                      [matAutocomplete]="autoDist"
                      [formControl]="costForm.controls['origin']"
                      class="form-control"
                      aria-describedby="destinationFrom"
                      placeholder="Kelurahan, kecamatan, kabupaten, provinsi"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      #autoDist="matAutocomplete"
                      [displayWith]="displayFnOrigin"
                    >
                      <mat-option
                        [ngStyle]="{ 'font-size': '10px' }"
                        *ngFor="let option of originSearchData | async"
                        [value]="option"
                      >
                        {{ option.urban_name }}, {{ option.sub_district_name }},
                        {{ option.city_name }}, Prov.{{ option.province_name }},
                        {{ option.postal_code }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-md-2"><label for="origin">To</label></div>
                  <div class="col">
                    <input
                      matInput
                      [matAutocomplete]="autoDistt"
                      [formControl]="costForm.controls['dest']"
                      class="form-control"
                      aria-describedby="destinationTo"
                      placeholder="Kelurahan, kecamatan, kabupaten, provinsi"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      #autoDistt="matAutocomplete"
                      [displayWith]="displayFnDest"
                    >
                      <mat-option
                        [ngStyle]="{ 'font-size': '10px' }"
                        *ngFor="let option of destinationSearchData | async"
                        [value]="option"
                      >
                        {{ option.urban_name }}, {{ option.sub_district_name }},
                        {{ option.city_name }}, Prov.{{ option.province_name }},
                        {{ option.postal_code }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-md-2"><label for="weight">Weight</label></div>
                  <div class="col d-flex flex-row align-items-center">
                    <input
                      matInput
                      type="text"
                      numbersOnly
                      [formControl]="costForm.controls['weight']"
                      class="form-control"
                      aria-describedby="weight"
                      [ngStyle]="{ width: '32%' }"
                    />
                    <span
                      matSuffix
                      [ngStyle]="{ position: 'absolute', left: '23%' }"
                      >Kg</span
                    >
                  </div>
                </div>
              </div>

              <div class="row align-items-center mb-1">
                <div class="col-md-2"><label for="volume">Volume</label></div>
                <div class="col">
                  <div class="d-flex flex-row">
                    <div class="mr-1 d-flex flex-row align-items-center">
                      <input
                        matInput
                        type="text"
                        numbersOnly
                        [formControl]="costForm.controls['long']"
                        class="form-control"
                        aria-describedby="length"
                        [ngStyle]="{ width: '100%' }"
                      />
                      <span
                        matSuffix
                        [ngStyle]="{ position: 'absolute', right: '70%' }"
                        >Cm</span
                      >
                    </div>
                    <div class="mr-1 d-flex flex-row align-items-center">
                      <input
                        matInput
                        type="text"
                        numbersOnly
                        [formControl]="costForm.controls['width']"
                        class="form-control"
                        aria-describedby="lebar"
                        [ngStyle]="{ width: '100%' }"
                      />
                      <span
                        matSuffix
                        [ngStyle]="{ position: 'absolute', right: '40%' }"
                        >Cm</span
                      >
                    </div>
                    <div class="mr-1 d-flex flex-row align-items-center">
                      <input
                        matInput
                        type="text"
                        numbersOnly
                        [formControl]="costForm.controls['height']"
                        class="form-control"
                        aria-describedby="tinggi"
                        [ngStyle]="{ width: '100%' }"
                        maxlength="4"
                      />
                      <span
                        matSuffix
                        [ngStyle]="{ position: 'absolute', right: '10%' }"
                        >Cm</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center gap-2 mt-3">
                <button
                  mat-flat-button
                  type="submit"
                  class="btn"
                  [ngStyle]="{ color: 'white' }"
                >
                  Search
                </button>
              </div>
            </form>
          </mat-expansion-panel>
        </div>

        <button class="drop-point" (click)="gotoDropPoint()">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="display: flex; flex-direction: column">
              DROP POINT
              <span>Check Drop Point Here</span>
            </div>s
            <mat-icon>keyboard_arrow_right</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </section> -->

  <section class="container-homepage">
    <div class="homepage-section">
      <div class="container-description">
        <h2>Indonesia’s Logistics</h2>
        <h2>Service Provider</h2>
        <div style="display: flex; flex-direction: row">
          <h3 style="display: flex; align-items: center">
            Solusi Pengiriman Untuk
          </h3>
          <h3 style="color: #1254a0; padding-left: 5px">
            <u>
              <div class="v-slider-frame col-sm-12 p-0">
                <ul class="v-slides text-left">
                  <li class="v-slide">B2B</li>
                  <li class="v-slide">B2C</li>
                  <li class="v-slide">D2C</li>
                  <li class="v-slide">F2C</li>
                  <li class="v-slide">Entrepreneur</li>
                  <li class="v-slide">Jastiper</li>
                </ul>
              </div>
            </u>
          </h3>
        </div>
        <h4>
          Tingkatkan Produktivitas Bisnismu dengan <br />Beragam Layanan
          Transportasi & Pengiriman Barang
        </h4>

        <div style="display: flex; flex-direction: row">
          <div style="padding-right: 10px">
            <a href="/tentang-kami">
              <button class="button-custom">Learn More</button></a
            >
          </div>
          <div>
            <button class="button-custom2" (click)="openVideoDialog()">
              Watch Video
            </button>
          </div>
        </div>
      </div>
      <div class="container-video">
        <img
          loading="lazy"
          src="https://storage.onindonesia.id/ondelivery.id/gif/Animasi.gif"
          alt=""
        />
      </div>
    </div>
  </section>

  <!-- <app-onservice-front></app-onservice-front> -->

  <app-navigation-list-bar></app-navigation-list-bar>
  <!-- 
  <section class="benefits-section">
    <div class="benefits-container">
      <div class="block-1">
        <div class="text-content">
          <h2>THE BENEFITS FOR YOU.</h2>
          <h3>Lebih Hemat Semakin Cepat</h3>
        </div>
      </div>
      <div class="block-2">
        <div class="card-container">
          <div class="top-content">
            <div
              id="banner-carousel"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#banner-carousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#banner-carousel" data-slide-to="1"></li>
                <li data-target="#banner-carousel" data-slide-to="2"></li>
                <li data-target="#banner-carousel" data-slide-to="3"></li>
              </ol>

              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  *ngFor="let screen of imagesScreen; let i = index"
                  [class.active]="i === 0"
                >
                  <img
                    [src]="screen"
                    class="d-block w-100"
                    alt="Slide {{ i + 1 }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-3">
        <div class="button-content">
          <button
            (mouseover)="hoverButton1()"
            (mouseout)="outButton()"
            class="ben-button-1"
          >
            <img src="assets\img\home-asset\autopickuplogo.png" alt="" />
          </button>
          <button
            (mouseover)="hoverButton2()"
            (mouseout)="outButton()"
            class="ben-button-2"
          >
            <img src="assets\img\home-asset\GeraiLogo.png" alt="" />
          </button>
          <button
            (mouseover)="hoverButton3()"
            (mouseout)="outButton()"
            class="ben-button-3"
          >
            <img src="assets\img\home-asset\PromoLogo.png" alt="" />
          </button>
        </div>
        <div class="text-content">
          <h2>{{ benefitContent.header }}</h2>
          <p>{{ benefitContent.content }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="partners-section download-area overlay-dark ptb_100">
    <div class="partners-container">
      <div class="block-1">
        <div class="text-content">
          <h2>OUR PARTNER</h2>
        </div>
      </div>
      <div class="row">
        <div class="owl-one owl-carousel owl-theme">
          <div class="item" *ngFor="let image of imagesPartner">
            <img class="carousel-image" [src]="image" alt="Brand Image" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="media-section">
    <div class="media-container">
      <div class="block-1">
        <div class="text-content-2">
          <h2>MEDIA COVERAGE</h2>
        </div>
      </div>
      <div class="block-2">
        <div class="media-art">
          <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/cnn.png" alt="" />
          <div>
            <a href="https://www.cnnindonesia.com/">
              <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/medcov1.png" alt="" />
            </a>
          </div>
          <div>
            <h4>ONDELIVERY Hadir Untuk Kita Semua</h4>
          </div>
        </div>

        <div class="media-art">
          <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/cnbc.png" alt="" />
          <div>
            <a href="https://www.cnbcindonesia.com/">
              <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/medcov2.png" alt="" />
            </a>
          </div>
          <div>
            <h4>ONDELIVERY Hadir Untuk Kita Semua</h4>
          </div>
        </div>

        <div class="media-art">
          <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/liputan6.png" alt="" />
          <div>
            <a href="https://www.liputan6.com/">
              <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/medcov3.png" alt="" />
            </a>
          </div>
          <div>
            <h4>ONDELIVERY Hadir Untuk Kita Semua</h4>
          </div>
        </div>

        <div class="media-art">
          <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/okezone.png" alt="" />
          <div>
            <a href="https://www.okezone.com/">
              <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/medcov4.png" alt="" />
            </a>
          </div>
          <div>
            <h4>ONDELIVERY Hadir Untuk Kita Semua</h4>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="join-section download-area overlay-dark ptb_100">
    <div class="container-join">
      <div class="content-left">
        <img src="https://storage.onindonesia.id/ondelivery.id/home-asset/loc.png" alt="" />
        <button (click)="gabungClick()">GABUNG</button>
      </div>
      <div class="content-right">
        <h1>DIMANAPUN KAMU BERADA</h1>
        <h3>ONDELIVERY Temani Pengiriman Paketmu</h3>
      </div>
    </div>
  </section> -->
</div>
