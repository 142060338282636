<div class="contact-page">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section
      id="contact"
      class="contact-area bg-white ptb_100"
      style="text-align: center"
    >
      <div
        class="container"
        style="border: 1px solid black; border-radius: 10px"
      >
        <p
          class="blue-txt"
          style="font-size: 30px; font-weight: 700; margin-top: 7px"
        >
          {{ translateArr.bp14 }}
        </p>
        <p class="dark-grey-txt" style="font-size: 15px; margin-top: 17px">
          {{ translateArr.bp15 }}
        </p>
        <div class="parent">
          <form id="contact-form">
            <div class="row">
              <div
                class="col-12"
                style="text-align: center; padding-top: 30px; width: 400px"
              >
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp1 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['full_name']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['full_name'].hasError('required') &&
                        form.controls['full_name'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp29 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp2 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <select
                      [(ngModel)]="selectedAgentType"
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['agent_type']"
                    >
                      <option value="" disabled selected>
                        {{ translateArr.bp3 }}
                      </option>
                      <option
                        *ngFor="let types of agentTypes"
                        [value]="types.value"
                      >
                        {{ types.viewValue }}
                      </option>
                    </select>
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['agent_type'].hasError('required') &&
                        form.controls['agent_type'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp30 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['agent_type'].invalid &&
                        form.controls['agent_type'].touched &&
                        !form.controls['agent_type'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp30 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp4 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      matInput
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['phone']"
                      maxlength="15"
                      numbersOnly
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['phone'].hasError('required') &&
                        form.controls['phone'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp31 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['phone'].invalid &&
                        form.controls['phone'].touched &&
                        !form.controls['phone'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp31 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp5 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      type="email"
                      class="form-control"
                      required
                      [formControl]="form.controls['email']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['email'].hasError('required') &&
                        form.controls['email'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp32 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['email'].invalid &&
                        form.controls['email'].touched &&
                        !form.controls['email'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp38 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp6 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <select
                      [(ngModel)]="selectedLocationType"
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['location_type']"
                    >
                      <option value="" disabled selected>
                        {{ translateArr.bp7 }}
                      </option>
                      <option
                        *ngFor="let types of locationTypes"
                        [value]="types.value"
                      >
                        {{ types.viewValue }}
                      </option>
                    </select>
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['location_type'].hasError('required') &&
                        form.controls['location_type'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp33 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp8 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <select
                      [(ngModel)]="selectedBuildingStatus"
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['building_status']"
                    >
                      <option value="" disabled selected>
                        {{ translateArr.bp9 }}
                      </option>
                      <option
                        *ngFor="let types of buildingStatus"
                        [value]="types.value"
                      >
                        {{ types.viewValue }}
                      </option>
                    </select>
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['building_status'].hasError('required') &&
                        form.controls['building_status'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp34 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp10 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      required
                      [formControl]="form.controls['full_address']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['full_address'].hasError('required') &&
                        form.controls['full_address'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp35 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500">
                    <mat-label>{{ translateArr.bp11 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      [matAutocomplete]="autoDist"
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      placeholder="{{ translateArr.bp12 }}"
                      class="form-control"
                      required
                      minlength="30"
                      [formControl]="form.controls['osas_location']"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      #autoDist="matAutocomplete"
                      [displayWith]="displayFnDest"
                    >
                      <mat-option
                        *ngFor="let option of destinationSearchData | async"
                        [value]="option"
                      >
                        {{ option.urban_name }}, {{ option.sub_district_name }},
                        {{ option.city_name }}, Prov.{{ option.province_name }},
                        {{ option.postal_code }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['osas_location'].hasError('required') &&
                        form.controls['osas_location'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp36 }}</small
                    >
                  </mat-hint>
                </div>
                <div
                  style="
                    text-align: initial;
                    font-size: 15px;
                    padding-bottom: 10px;
                  "
                >
                  {{ translateArr.bp36 }}
                </div>
                <div
                  class="form-group"
                  style="text-align: initial; font-size: 17px"
                >
                  <div style="font-weight: 500; padding-bottom: 3px">
                    <mat-label>{{ translateArr.bp13 }}</mat-label>
                  </div>
                  <div class="input-group">
                    <input
                      style="
                        border: 1px solid #414143;
                        height: 40px;
                        font-size: 15px;
                      "
                      class="form-control"
                      placeholder="{{ translateArr.bp42 }}"
                      [formControl]="form.controls['location_coordinate']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['location_coordinate'].hasError(
                          'required'
                        ) && form.controls['location_coordinate'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp37 }}</small
                    >
                  </mat-hint>
                </div>

                <ng-container *ngIf="translate === 'cn'; else notChinesse">
                  <div
                    class="d-inline-block py-3 dark-grey-txt"
                    style="font-size: 14px; font-weight: 500; text-align: left"
                  >
                    {{ translateArr.bp16 }}
                    <a
                      (click)="downloadTermsAndCondition()"
                      style="color: #13aad4"
                      >{{ translateArr.bp19 }}</a
                    >{{ translateArr.bp20 }}
                  </div>
                </ng-container>
                <ng-template #notChinesse>
                  <span
                    class="d-inline-block py-3 dark-grey-txt"
                    style="font-size: 14px; font-weight: 500; text-align: left"
                    >{{ translateArr.bp16 }}
                    <a
                      href="../../../../assets/doc/Persyaratan Keagenan OPA - OMA.pdf"
                      target="_blank"
                      style="color: #13aad4"
                      >{{ translateArr.bp17 }}</a
                    >
                  </span>
                </ng-template>
              </div>

              <ng-container *ngIf="translate === 'cn'; else notChineseButton">
                <div
                  class="col-12"
                  style="text-align: center; padding-bottom: 90px"
                >
                  <button
                    mat-raised-button
                    style="width: 178px !important; height: 30px"
                    color="primary"
                    class="login-button btn btn-lg w-100"
                    type="submit"
                    [disabled]="!form.valid"
                    (click)="addInquiry()"
                  >
                    <div
                      class="row"
                      style="margin: 0px; margin-left: -25px; display: block"
                    >
                      {{ translateArr.bp18 }}
                      <span class="aligned-with-icon">
                        <mat-icon style="padding-left: 5px; padding-top: 3px"
                          >arrow_forward</mat-icon
                        >
                      </span>
                    </div>
                  </button>

                  <ng-container *ngIf="submitted">
                    <div class="col-12">
                      <span
                        class="d-block pt-2 mt-4 border-top dark-grey-txt"
                        style="text-align: center; font-size: 12px"
                        >{{ translateArr.bp39 }}</span
                      >
                      <span
                        class="d-block pt-2 blue-txt"
                        style="
                          text-align: center;
                          font-size: 20px;
                          font-weight: bold;
                        "
                        >{{ translateArr.bp40 }}</span
                      >
                    </div>
                  </ng-container>
                  <ng-container *ngIf="submissionFailed">
                    <div class="col-12">
                      <span
                        class="d-block pt-2 mt-4 border-top dark-grey-txt"
                        style="text-align: center"
                        >{{ translateArr.bp41 }}</span
                      >
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #notChineseButton>
                <div
                  class="col-12"
                  style="text-align: left; padding-bottom: 50px"
                >
                  <button
                    style="width: 150px !important"
                    mat-rised-button
                    class="login-button btn btn-xs w-100"
                    type="submit"
                    [disabled]="!form.valid"
                    (click)="addInquiry()"
                  >
                    <div
                      class="row"
                      style="justify-content: center; align-items: center"
                    >
                      <span style="font-size: 14px">
                        {{ translateArr.bp18 }}
                      </span>
                      <span>
                        <mat-icon>arrow_forward</mat-icon>
                      </span>
                    </div>
                  </button>

                  <ng-container *ngIf="submitted">
                    <div class="col-12">
                      <span
                        class="d-block pt-2 mt-4 border-top dark-grey-txt"
                        style="text-align: center; font-size: 12px"
                        >{{ translateArr.bp39 }}</span
                      >
                      <span
                        class="d-block pt-2 blue-txt"
                        style="
                          text-align: center;
                          font-size: 20px;
                          font-weight: bold;
                        "
                        >{{ translateArr.bp40 }}</span
                      >
                    </div>
                  </ng-container>
                  <ng-container *ngIf="submissionFailed">
                    <div class="col-12">
                      <span
                        class="d-block pt-2 mt-4 border-top dark-grey-txt"
                        style="text-align: center"
                        >{{ translateArr.bp41 }}</span
                      >
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>
