import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators  } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import blackpink from './registrasi-front-translate.json'


@Component({
  selector: 'app-registrasi-front',
  templateUrl: './registrasi-front.component.html',
  styleUrls: ['./registrasi-front.component.css']
})
export class RegistrasiFrontComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  openDialog() {
    
    const dialogRef = this.dialog.open(RegisterDialog, {
      
    });
  }
}


@Component({
  selector: 'register',
  templateUrl: 'register.html',
  providers: [ApiService]

})

export class RegisterDialog implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  AgentRegisterForm: FormGroup

  constructor(
    public dialogRef: MatDialogRef<RegisterDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.AgentRegisterForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      phone_number: [null, Validators.compose([Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), Validators.required])],
      email: [null, Validators.compose([Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required])]
    });

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  OnSubmit() {

    const name = this.AgentRegisterForm.value.name;
    const email = this.AgentRegisterForm.value.email;
    const phone_number = this.AgentRegisterForm.value.phone_number;

    this.apiService.agentRegister(name, email, phone_number).subscribe((res) => {
      console.log(res)
      console.log("sukses")
      this.dialogRef.close();
      Swal.fire({
        title: "Informasi Mitra berhasil disimpan!!",
        text: "Terimakasih telah daftar menjadi mitra ONDELIVERY, Kami akan menghubungi anda untuk proses selanjutnya.",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "success"
      });
    })
  }
}
