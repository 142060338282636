import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Optional } from '@angular/core';
import blackpink from './signup-translate.json'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [ApiService]
})

export class SignUpComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  msg = '';
  form: FormGroup;
  errorRegister: string| null = null

  constructor(
    private service: ApiService,
    private routes: Router,
    private fb: FormBuilder,
    public dialog: MatDialog) { }

  hide = true;

  ngOnInit() {
    if (localStorage.getItem("jwt") != null) {
      this.routes.navigate(['/profile'])
    }
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required])],
      phone_number: [null, Validators.compose([Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), Validators.required])],
      firstname: [null, Validators.compose([Validators.required])],
      lastname: [null, Validators.compose([Validators.required])]
    });

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  register() {
    const username = this.form.value.username;
    const password = this.form.value.password;
    const email = this.form.value.email;
    const phone_number = this.form.value.phone_number;
    const first_name = this.form.value.firstname;
    const last_name = this.form.value.lastname;
    const mode = "LINK"

    this.service.register(username, password, email, first_name, last_name, phone_number, mode).subscribe(data => {
      // console.log(data)
      this.routes.navigate(['thank-you'])
    }, e => {
      // console.log(e)
      if(e.status == 400){
        switch(e.error.message){
          case "Username/Email Has Been Taken":
            this.errorRegister = "USERNAME_EMAIL_TAKEN"
            break
        }
      } else {
        this.errorRegister = "SERVICE_ERROR"
      }
    })
  }
}