import { Component, OnInit } from "@angular/core";
import blackpink from "./about-translate.json";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
})
export class AboutComponent implements OnInit {
  translate = localStorage.getItem("translate");
  translateArr = blackpink[localStorage.getItem("translate")];
  imagesPartners: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Anteraja-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Aroma Prima Livindo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Blibli-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/BPJS-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Foom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/InHarmony-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Jubelio-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Kaisar-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/LOGO-LKPP-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monarre-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Anteraja-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Aroma Prima Livindo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Blibli-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/BPJS-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sociolla-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/SPX-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Tempo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Vitabiotics-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/wings-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Foom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/InHarmony-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Jubelio-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Kaisar-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/LOGO-LKPP-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monarre-min.png",
  ];

  imagesPartners2: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Monologiz-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monotaro-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Nature market-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/nozomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/OT_Logo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Pacific city-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Quantum-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Relx-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sigmatech-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sinarmas-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sociolla-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/SPX-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Tempo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Vitabiotics-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/wings-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Monologiz-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/monotaro-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Nature market-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/nozomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/OT_Logo-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Pacific city-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Quantum-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Relx-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sigmatech-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Sinarmas-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-partner-bisnis/Deca Group-min.png",
  ];

  imagesPublications: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/24h-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Akurat-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Bisnis indonesia-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/images-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/industriycoid-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/iNewsLogo-tag-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Inilahcom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Kontan_co_id-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo_BeritaSatu_Media-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo_Tabloid-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo-ANTARANews-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/logo-cobisnis-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/logo-rmid-min.png",

    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo-sindonews-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Marketeers-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/marketing-co-id-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/merdeka-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/MNC_Trijaya_Jakarta-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/PT-Republika-Media-Mandiri-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/sinar harapan-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Swasembada-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Tabloid bintang-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/tempo-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Tribun news-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/warta ekonomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Yahoo-Logo-min.png",
  ];

  imagesPublications2: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo-sindonews-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Marketeers-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/marketing-co-id-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/merdeka-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/MNC_Trijaya_Jakarta-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/PT-Republika-Media-Mandiri-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/sinar harapan-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Swasembada-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Tabloid bintang-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/tempo-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Tribun news-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/warta ekonomi-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Yahoo-Logo-min.png",

    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/24h-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Akurat-01-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Bisnis indonesia-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/images-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/industriycoid-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/iNewsLogo-tag-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Inilahcom-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Kontan_co_id-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo_BeritaSatu_Media-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo_Tabloid-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/Logo-ANTARANews-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/logo-cobisnis-min.png",
    "https://storage.onindonesia.id/ondelivery.id/about-us/logo-publikasi-media/logo-rmid-min.png",
  ];

  constructor() {}

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")];
  }
}
