import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import { DeleteBooking } from './detail-booking/detail-booking.component';
import blackpink from './order-translate.json'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  page: number = 1
  size: number = 10

  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('panel') public panel: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  translateArr = blackpink[localStorage.getItem("translate")]
  bookingData: any;
  displayedColumns: string[] = ['bookingId', 'type', 'createdAt', 'action'];

  dataSource = new MatTableDataSource();
  isLoading: boolean = false;
  totalBooking: any;
  status: any;
  statuss: any;

  constructor(
    private apiService: ApiService,
    private routes: Router,
    public dialog: MatDialog
  ) {
    this.fetchBooking()
  }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
    this.fetchBooking()
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  fetchBooking() {
    // this.isLoading = true
    this.apiService.getUserBooking(this.size, this.page).subscribe(data => {
      // console.log(data)
      this.dataSource = data.data
      this.totalBooking = data.total

      this.isLoading = false
    }, e => {
      // console.log(e)
    })
  }

  formatDate(date: any) {
    return dayjs(date).format('DD MMMM YYYY, hh:mm WIB')
  }

  cancel(booking_id: string) {
    const dialogRef = this.dialog.open(DeleteBookingList, {
      data: booking_id
    });
    dialogRef.afterClosed().subscribe(e => {
      this.fetchBooking()
    })
  }

  onPaginateChange(event: any) {
    this.dataSource.data = []
    // console.log(event)
    if (event.pageSize != this.size) {
      this.page = 1
      this.size = event.pageSize
    } else {
      this.page = event.pageIndex + 1
    }
    this.fetchBooking()
  }

  resetPageIndex() {
    this.page = 0
  }
}

@Component({
  selector: 'delete-booking-list',
  templateUrl: 'delete-booking-list.html',
  providers: [ApiService],
  styleUrls: ['./detail-booking/detail-booking.component.css']
})

export class DeleteBookingList {
  local_data: any;
  translateArr = blackpink[localStorage.getItem("translate")]

  @ViewChild('panel') public panel: ElementRef;

  constructor(
    private service: ApiService,
    private routes: Router,
    public dialogRef: MatDialogRef<DeleteBooking>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = data;
  }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  doAction(data: string) {
    this.service.cancelBooking(data).subscribe(data => {
      this.dialogRef.close({ event: 'Cancel', data: this.local_data });
      Swal.fire({
        title: "Sukses!",
        text: "Booking berhasil dihapus!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "success"
      })
    }, (e) => {
      // console.log(e)
      Swal.fire({
        title: "Gagal!",
        text: "Pesanan gagal dihapus",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "warning"
      })
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}