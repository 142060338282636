<div class="main">
  <app-scrollup></app-scrollup>
  <section class="section service-area overflow-hidden ptb_100">
    <div class="container">
      <div class="text-center">
        <h2>Pusat Bantuan</h2>
        <h4>
          Kami ingin mengenalmu dan dengan senang hati akan menjawab
          pertanyaanmu!
        </h4>
      </div>
      <div>
        <mat-form-field class="col-sm-12" appearance="outline">
          <input matInput placeholder="Tulis pertanyaanmu disini" />
          <mat-icon matSuffix style="cursor: pointer" (click)="search()"
            >search</mat-icon
          >
        </mat-form-field>
      </div>
    </div>
  </section>
  <section class="section service-area">
    <div class="container">
      <div class="row help-desc" style="gap: 10px">
        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="/faq">
            <h3 class="text-white">Profil</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_Profil.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>

        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="faq">
            <h3 class="text-white">Pengiriman</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_pengiriman.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>
        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="faq">
            <h3 class="text-white">Pembayaran</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_Pembayaran.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>
        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="faq">
            <h3 class="text-white">Promosi</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_Promo.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>

        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="faq">
            <h3 class="text-white">Keagenan</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_keagenan.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>
        <mat-card
          class="col-md-3 col-sm-6 mb-4 col-lg-3"
          style="
            background-color: #1254a0;
            min-height: 160px;
            max-width: 300px;
            border-radius: 8px;
          "
        >
          <a href="faq">
            <h3 class="text-white">Lainnya</h3>
            <div style="display: flex; justify-content: end">
              <img
                style="width: 80px; height: 80px"
                src="https://storage.onindonesia.id/ondelivery.id/help/help category_lainnya.png"
                alt=""
                loading="lazy"
              />
            </div>
          </a>
        </mat-card>
      </div>
      <div class="d-flex justify-content-start help-desc">
        <div class="column">
          <h2>Hubungi Kami</h2>
          <h4>
            Kami ingin mengenalmu dan dengan senang <br />
            hati akan menjawab pertanyaanmu!
          </h4>
        </div>
      </div>
      <div class="d-flex m-auto">
        <div class="d-flex justify-content-end col-md-4 col-lg-4 col-sm-12">
          <img
            style="min-height: 450px; max-height: 450px"
            src="https://storage.onindonesia.id/ondelivery.id/help/Banner Icon.png"
            alt=""
            loading="lazy"
          />
        </div>

        <mat-card
          class="col-md-8 col-lg-7 col-sm-12"
          style="
            min-height: 450px;
            max-height: 600px;
            border-radius: 20px;
            padding-left: 30px;
          "
        >
          <div class="m-3">
            <div class="row">
              <mat-form-field class="col-sm-6" appearance="outline">
                <mat-label>Nama</mat-label>
                <input matInput type="text" placeholder="Masukkan nama" />
                <mat-icon matSuffix>people</mat-icon>
              </mat-form-field>
              <mat-form-field class="col-sm-6" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" placeholder="Masukkan Email" />
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
              <mat-form-field class="col-sm-6" appearance="outline">
                <mat-label>No.Telp</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Masukkan Nomor Telepon"
                />
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
              <mat-form-field class="col-sm-6" appearance="outline">
                <mat-label>No. AWB</mat-label>
                <input matInput type="text" placeholder="Masukkan no resi" />
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
              <mat-form-field class="col-sm-12" appearance="outline">
                <mat-label>Penjelasan Masalah</mat-label>
                <textarea
                  matInput
                  placeholder="Masukkan penjelasan masalah"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                  cdkAutosizeMaxRows="4"
                ></textarea>
              </mat-form-field>

              <div class="col-lg-12" style="padding: 0 15px">
                <button
                  mat-raised-button
                  class="col-sm-12"
                  style="background-color: #1254a0; color: white"
                >
                  Kirim
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row col-sm-12 m-auto" style="padding: 70px 0">
        <div class="col-sm-7" id="map"></div>
        <div class="col-sm-5 column" style="padding-left: 50px">
          <div class="d-flex flex-direction-row">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/location.svg"
              style="height: 50px; width: 50px"
              alt=""
              loading="lazy"
            />

            <div class="column">
              <h3 style="margin: 10px 0 15px 10px">Alamat Kami</h3>
              <span style="margin: 10px 0 15px 10px; text-align: left"
                >Sedayu City, Jl. Boulevard Raya, Blok E, No. 2, RT.3/RW.9
                Cakung Barat, Jakarta Utara Kode Pos ID 14140
              </span>
            </div>
          </div>

          <div class="d-flex flex-direction-row pt-5">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/phone.svg"
              style="height: 50px; width: 50px"
              alt=""
              loading="lazy"
            />
            <div class="column">
              <h3 style="margin: 10px 0 15px 10px">Telephone</h3>
              <span style="margin: 10px 0 15px 10px; text-align: left"
                >(021) 50555007
              </span>
            </div>
          </div>

          <div class="d-flex flex-direction-row pt-5">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/mail.svg"
              style="height: 50px; width: 50px"
              alt=""
              loading="lazy"
            />
            <div class="column">
              <h3 style="margin: 10px 0 15px 10px">Alamat Email</h3>
              <span style="margin: 10px 0 15px 10px; text-align: left"
                >businessdevelopment&#64;ondelivery.id
              </span>
            </div>
          </div>

          <div class="d-flex flex-direction-row pt-5">
            <img
              src="https://storage.onindonesia.id/ondelivery.id/svgs/phone.svg"
              style="height: 50px; width: 50px"
              alt=""
              loading="lazy"
            />
            <div class="column">
              <h3 style="margin: 10px 0 15px 10px">Whatsapp</h3>
              <span style="margin: 10px 0 15px 10px; text-align: left"
                >+62 81210053430
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer-one></app-footer-one>
