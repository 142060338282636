import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { existValidation } from "../utility.service";
import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from "rxjs/operators";
import { ApiService } from "src/app/api.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CostResultDialogComponent } from "../../../src/app/components/track/cost/cost-result-dialog/cost-result-dialog.component";
import gsap from "gsap";

declare var $: any;

@Component({
  selector: "app-theme",
  templateUrl: "./theme.component.html",
  styleUrls: ["./theme.component.scss"],
})
export class ThemeComponent implements OnInit, AfterViewInit {
  imagesPartner: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo APL.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo BLIBLI.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo DECA GROUP.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo INHARMONY.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo JUBELIO.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo KAISAR.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo MODENA.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo MONARRE.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo MONOTARO.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo NOZOMI.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo ORANGTUA.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo SCARLETT.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo SHIPPER.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo SINARMAS MSIG LIFE.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo SOCIOLLA.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo VITABIOTICS.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo WHITELAB.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Logo WINGS.png",
  ];

  imagesScreen: string[] = [
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 1.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 2.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 3.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 4.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 5.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 6.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 7.png",
    "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 8.png",
  ];

  benefitText: object[] = [
    {
      header: "Auto Pickup",
      content:
        "Kiriman Di Pick Up Tanpa Minimum Order Atau Setara Dengan 1 Kg, ONRIDER Auto Jemput Kirimanmu",
    },
    {
      header: "Gerai",
      content:
        "Konten ini hanyalah contoh apabila button Gerai di hover atau di mouse over, udah itu aja sih",
    },
    {
      header: "Promo",
      content:
        "Konten ini hanyalah contoh apabila button Promo di hover atau di mouse over, udah itu aja sih",
    },
  ];

  waybillForm: FormGroup;
  costForm: FormGroup;
  originSearchData: any = [];
  destinationSearchData: any = [];

  message: string;
  benefitContent: any = this.benefitText[0];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    public dialogRef: MatDialog
  ) {}

  @ViewChild("panel") public panel: ElementRef;

  ngOnInit() {
    this.waybillForm = this.fb.group({
      waybill: [null, Validators.compose([Validators.required])],
    });

    this.costForm = this.fb.group(
      {
        origin: [null, Validators.compose([Validators.required])],
        dest: [null, Validators.compose([Validators.required])],
        weight: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        long: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        width: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
        height: [
          1,
          Validators.compose([
            Validators.pattern("[1-9][0-9]*"),
            Validators.required,
          ]),
        ],
      },
      { validators: existValidation }
    );

    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "bottom",
        inline: "start",
      });
    }, 300);

    // auto complete start
    this.originSearchData = this.costForm.get("origin")?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      startWith(""),
      switchMap((value) => this.origin_serv(value))
    );

    this.destinationSearchData = this.costForm.get("dest")?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      startWith(""),
      switchMap((value) => this.destination_serv(value))
    );
    // auto complete end
  }

  private initAnimation(): void {
    var vsOpts = {
      $slides: $(".v-slide"),
      $list: $(".v-slides"),
      duration: 6,
      lineHeight: 50,
    };

    var vSlide = gsap.timeline({
      paused: true,
      repeat: -1,
    });

    vsOpts.$slides.each(function (i: number) {
      vSlide.to(vsOpts.$list, vsOpts.duration / vsOpts.$slides.length, {
        y: -vsOpts.lineHeight * i,
        ease: "elastic.out(1, 0.4)",
      });
    });
    vSlide.play();
  }

  // auto complete start
  private origin_serv(value: string): any {
    return this.apiService
      .originSearchNew(value)
      .pipe(catchError(() => of([])));
  }

  displayFnOrigin(sender?: any): string | undefined {
    return sender
      ? sender.urban_name +
          ", " +
          sender.sub_district_name +
          ", " +
          sender.city_name +
          ", Prov. " +
          sender.province_name +
          ", " +
          sender.postal_code
      : undefined;
  }

  private destination_serv(value: string): any {
    return this.apiService
      .destinationSearchNew(value)
      .pipe(catchError(() => of([])));
  }
  displayFnDest(consignee?: any): string | undefined {
    return consignee
      ? consignee.urban_name +
          ", " +
          consignee.sub_district_name +
          ", " +
          consignee.city_name +
          ", Prov. " +
          consignee.province_name +
          ", " +
          consignee.postal_code
      : undefined;
  }
  // auto complete end

  checkPrice() {
    // console.log(this.costForm.value, "pricing");
    if (!this.costForm.valid) {
      this.costForm.markAllAsTouched();
      return;
    }
  }

  searchAirwayBill() {
    if (!this.waybillForm.valid) {
      this.waybillForm.markAllAsTouched();
      return;
    }
    this.router.navigate(["/track"], {
      state: { waybill: this.waybillForm.value.waybill },
    });
  }

  ngAfterViewInit() {
    $(".owl-one").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 1000,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });

    this.initAnimation();
  }

  openVideoDialog(): void {
    this.dialogRef.open(VideoComponent, {});
  }

  onPanelOpened(i: number): void {
    switch (i) {
      case 1:
        console.log("Panel 1 opened");
        break;
      case 2:
        console.log("Panel 2 opened");
        break;
    }
  }

  onPanelClosed(i: number): void {
    switch (i) {
      case 1:
        console.log("Panel 1 Closed");
        break;
      case 2:
        console.log("Panel 2 Closed");
        break;
    }
  }

  hoverEffect(i: number): void {
    switch (i) {
      case 1:
        console.log("Panel 1 Hover");
        break;
      case 2:
        console.log("Panel 2 Hover");
        break;
    }
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  gotoDropPoint() {
    this.router.navigate(["drop-point"]);
  }

  hoverButton1() {
    this.benefitContent = this.benefitText[0];
  }

  hoverButton2() {
    this.benefitContent = this.benefitText[1];
  }

  hoverButton3() {
    this.benefitContent = this.benefitText[2];
  }

  outButton() {
    this.benefitContent = this.benefitText[1];
  }

  gabungClick() {
    this.router.navigate(["/courier-partner"]);
  }
}

@Component({
  selector: "app-video-player",
  template: `
    <object
      class="responsive-object"
      height="400"
      data="https://www.youtube.com/embed/3mvu0meNX68?si=uc6DgLxY70P-ScIE"
    ></object>
  `,
  styles: [
    `
      :host ::ng-deep .mat-dialog-container {
        padding: 0 !important;
      }
      .responsive-object {
        width: 100%;
        max-width: 700px;
      }
      @media (min-width: 768px) {
        .responsive-object {
          width: 700px;
        }
      }
    `,
  ],
})
export class VideoComponent {
  constructor(public dialogRef: MatDialogRef<VideoComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
