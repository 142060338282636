<div class="inner inner-pages">
  <div class="main">
    <!-- <app-main-header></app-main-header> -->
    <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-12 col-md-8">
            <div class="welcome-intro">
              <h1 class="text-white">{{translateArr.bp1}}</h1>
              <p class="text-white my-4">{{translateArr.bp2}} <b style="color: black;">{{translateArr.bp4}}</b></p>
              <button class="btn sApp-btn text-uppercase" (click)="OK()">{{translateArr.bp3}}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>