import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import blackpink from './thank-you-translate.json'

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(private routes: Router) { }
  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }
  OK(): void {
    this.routes.navigate([''])
  }
}
