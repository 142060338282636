import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { clearLocalStorage } from 'src/app/utility.service'
import blackpink from './profile-translate.json'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(private routes: Router, private service: ApiService) { }

  loggedinUser: string
  ngOnInit(): void {
    if(localStorage.getItem('username')!=null){
      this.loggedinUser = localStorage.getItem('username')
    }

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  logout() {
    this.service.logOff(localStorage.getItem('jwt')).subscribe((data)=>{
      clearLocalStorage()
      this.routes.navigate(['/'])
    }, (error)=>{      
    })
  }
}
 