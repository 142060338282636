<section class="section breadcrumb-area bg-overlay d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Breamcrumb Content -->
                <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                    <h2 class="text-white text-capitalize">{{translateArr.bp1}}</h2>
                    <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item"><a class="text-uppercase text-white" href="index.html">{{translateArr.bp2}}</a></li>
                        <li class="breadcrumb-item text-white active">{{translateArr.bp3}}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</section>