import { Component, OnInit } from '@angular/core';
import blackpink from './footer-one-translate.json';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.css']
})
export class FooterOneComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
