<!-- NOTE: Bekas Rifqi -->
<!-- <div class="b2b-logistic">
  <div class="main">
    <app-scrollup></app-scrollup>
    <section id="btb-logistic" class="section b2b-logistic">
      <div class="container ptb_100">
        <div class="banner">
          <div class="d-flex flex-column justify-content-center">
            <h3>B2B</h3>
            <h3>Logistik</h3>
          </div>
          <div class="d-flex justify-content-end">
            <img
              style="width: 100%"
              src="https://storage.onindonesia.id/ondelivery.id/rectangle-dummy.png"
            />
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one>
  </div>
</div> -->

<section class="section service-area overflow-hidden ptb_150">
  <app-scrollup></app-scrollup>
  <div class="image-container">
    <img
      src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Thumbnail b2b.png"
      alt="ONDELIVERY Logistics"
      class="background-image"
      loading="lazy"
    />

    <div class="text-overlay">
      <h1 class="text-white">
        ONDELIVERY <br />
        Logistics
      </h1>

      <div class="button-custom-padding">
        <a href="/cost-estimation">
          <button mat-raised-button color="primarya">Learn More</button>
        </a>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- <div
      class="d-flex row col-sm-12 col-lg-12 col-md-12"
      style="padding-bottom: 80px"
    >
      <div
        class="col-sm-6 col-md-4 col-lg-3 column"
        style="display: flex; align-items: center"
      >
        <div class="column">
          <h2 class="m-0">ONDELIVERY</h2>
          <h2>LOGISTICS</h2>
          <a href="/vip-customer">
            <button
              mat-flat-button
              style="
                color: white;
                background: #1254a0;
                border-radius: 8px;
                padding: 8px 15px;
              "
            >
              Learn More
            </button>
          </a>
        </div>
      </div>
      <div class="col-sm-6 col-md-8 col-lg-9">
        <img
          loading="lazy"
          src="assets/img/b2b-logistik/Thumbnail b2b.png"
          style="width: 100%; height: 100%; border-radius: 8px"
          alt=""
        />
      </div>
    </div> -->
    <div class="d-flex" style="padding-top: 40px">
      <h3>
        Untuk Perusahaan, pengiriman barang hingga mencakup seluruh rangkaian
        proses, mulai dari penjemputan awal, transfer dari tempat A ke B, hingga
        ke pengantaran tujuan (motor dan truck)
      </h3>
    </div>

    <div class="d-flex row col-sm-12 col-lg-12 col-md-12 ptb_100">
      <div class="col-12" style="padding-bottom: 50px">
        <img
          loading="lazy"
          style="width: 100%; border-radius: 8px"
          src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Banner full in B2B logistics (1st - last mile)-min.png"
          alt=""
        />
      </div>
      <div class="d-flex row col-12">
        <div class="col-sm-3 col-md-3 col-lg-3 spacer-top-bottom">
          <div class="d-flex text-left">
            <img
              style="width: 70px; height: 70px"
              src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Icon planning (1st - last mile).svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="d-flex text-left pt-4">
            <h3>Planning</h3>
          </div>
          <ul>
            <li>• Carrier</li>
            <li>• Fullfillment</li>
          </ul>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 spacer-top-bottom">
          <div class="d-flex text-left">
            <img
              style="width: 70px; height: 70px"
              src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Icon 1st mile (1st - last mile).svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="d-flex text-left pt-4">
            <h3>First Mile</h3>
          </div>
          <ul>
            <li>• PO Manajement</li>
            <li>• Stock Transfer</li>
          </ul>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 spacer-top-bottom">
          <div class="d-flex text-left">
            <img
              style="width: 70px; height: 70px"
              src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Icon Mid mile (1st - last mile).svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="d-flex text-left pt-4">
            <h3>Middle Mile</h3>
          </div>
          <ul>
            <li>• Stock Transfer AntarGudang</li>
          </ul>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 spacer-top-bottom">
          <div class="text-left">
            <img
              style="width: 70px; height: 70px"
              src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/Icon Last mile (1st - last mile).svg"
              alt=""
              loading="lazy"
            />
          </div>
          <div class="text-left pt-4">
            <h3>Last Mile</h3>
          </div>
          <ul>
            <li>• Pengantaran ke Tujuan</li>
            <li>• Pengiriman yang tepat Waktu</li>
            <li>• Harga Terjangkau</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="banner-container">
      <img
        src="https://storage.onindonesia.id/ondelivery.id/b2b-logistik/banner-web-logistics.png"
        alt="Ondelivery Logistics"
        loading="lazy"
        class="background-image-footer"
      />
      <div class="card-overlay">
        <div class="text-banner-overlay">
          <div class="banner-description">
            Andalkan ONDELIVERY untuk Memenuhi Kebutuhan Bisnismu
          </div>
          <div class="banner-subdescription">
            Apapun, Kapanpun, dan Dimanapun
          </div>
          <a href="/keagenan">
            <div class="button-container">
              <div class="button-join">
                <div class="text-button">Gabung Sekarang</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer-one></app-footer-one>
