<ngx-spinner type="square-jelly-box" size="medium" color="#ffffff" bdColor="rgba(0, 0, 0, 0.8)"
  [fullScreen]="true">Loading ....
</ngx-spinner>

<div class="card" #panel>
  <h3 class="contact-title m-0">{{translateArr.bp1}}</h3>
  <h5 class="text-secondary fw-3 pt-3 pb-4">{{translateArr.bp2}}</h5>
  <!-- METODE PENGIRIMAN -->
  <div class="pb-4">
    <h4>Metode Pengiriman</h4>
    <hr />
    <div class="col-12 row pr-0 w-100">
      <div class="row w-100">
        <div class="col-lg-5 col-md-6 col-6 pr-0">
          <p>Metode Pengiriman</p>
        </div>
        <div class="col-lg-7 col-md-6 col-6 pr-0">
          <p>: {{bookingData[0].metode_pengiriman === 'WALK_IN' ? 'Walk in' : 'Pick up'}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- INFORMASI PENGIRIM -->
  <div class="pb-4">
    <h4>{{translateArr.bp3}}</h4>
    <hr />
    <div class="col-12 row pr-0 w-100">
      <div class="row w-100">
        <div class="col-lg-5 col-md-6 col-6 pr-0">
          <p>{{translateArr.bp4}}</p>
        </div>
        <div class="col-lg-7 col-md-6 col-6 pr-0">
          <p>: {{bookingData[0].sender_name}}</p>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-lg-5 col-md-6 col-6 pr-0">
          <p>{{translateArr.bp5}}</p>
        </div>
        <div class="col-lg-7 col-md-6 col-6 pr-0">
          <p>: {{bookingData[0].sender_phone}}</p>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-lg-5 col-md-6 col-6 pr-0">
          <p>{{translateArr.bp6}}</p>
        </div>
        <div class="col-lg-7 col-md-6 col-6 pr-0">
          <p>: {{bookingData[0].sender_address}}</p>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-lg-5 col-md-6 col-6 pr-0">
          <p>{{translateArr.bp11}}</p>
        </div>
        <div class="col-lg-7 col-md-6 col-6 pr-0">
          <p>: {{bookingData[0].urban_sender}}, {{bookingData[0].district_sender}}, Prov.
            {{bookingData[0].province_sender}},
            {{bookingData[0].post_id}}</p>
        </div>
      </div>
    </div>
  </div>


  <div *ngFor="let item of bookingData; let i = index">
    <!-- INFORMASI PENERIMA -->
    <div class="pb-3">
      <h4 *ngIf="oneReceiver, else manyReceiver1">{{translateArr.bp7}}</h4>
      <h4><ng-template #manyReceiver1>{{translateArr.bp7}} {{i+1}}</ng-template></h4>
      <hr />
      <div class="col-12 row pr-0 w-100">
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp8}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.receiver_name}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp9}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.receiver_phone}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp10}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.receiver_address}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp11}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.urban_receiver}}, {{item.district_receiver}}, Prov.
              {{item.province_receiver}},
              {{item.post_id_receiver}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- INFORMASI DETAIL PAKET -->
    <div class="pb-3">
      <h4 *ngIf="oneReceiver, else manyReceiver2">{{translateArr.bp7}}</h4>
      <h4><ng-template #manyReceiver2>{{translateArr.bp7}} {{i+1}}</ng-template></h4>
      <hr />
      <div class="col-12 row pr-0 w-100">
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp13}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.koli}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp14}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.weight}} Kg</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp15}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.goods_id}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp16}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.length}} cm x {{item.width}} cm x {{item.height}} cm</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp17}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.goods_desc}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- INFORMASI PENGIRIMAN -->
    <div class="pb-3">
      <h4 *ngIf="oneReceiver, else manyReceiver3">{{translateArr.bp7}}</h4>
      <h4><ng-template #manyReceiver3>{{translateArr.bp7}} {{i+1}}</ng-template></h4>
      <hr />
      <div class="col-12 row pr-0 w-100">
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp19}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.service_name}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp20}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.estimate_price}}</p>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-lg-5 col-md-6 col-6 pr-0">
            <p>{{translateArr.bp21}}</p>
          </div>
          <div class="col-lg-7 col-md-6 col-6 pr-0">
            <p>: {{item.notes}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 pt-5 text-right">
    <button class="btn button cancel-button mr-3 mt-sm-3" type="submit"
      (click)="cancel()">{{translateArr.bp22}}</button>
    <button class="btn button mt-sm-3 mr-3" type="submit" (click)="orderNow()">{{translateArr.bp23}}</button>
  </div>
</div>