import { Component, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-benefit-component",
  templateUrl: "./benefit-component.component.html",
  styleUrls: ["./benefit-component.component.scss"],
})
export class BenefitComponentComponent implements OnInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  benefitText: object[] = [
    {
      header: "Auto Pickup",
      content: "Kirimanmu di pick-up ontime di lokasi penjemputan.",
    },
    {
      header: "Harga Terjangkau",
      content:
        "Biaya pengiriman terjangkau untuk pengiriman hingga seluruh Indonesia",
    },
    {
      header: "Fleksibilitas",
      content:
        "FTL, Kirim muatan dalam jumlah besar tanpa batasan berat atau dimensi.",
    },
  ];

  imageScreens: string[][] = [
    [
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 1.png",
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 2.png",
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 3.png",
    ],
    [
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 4.png",
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 5.png",
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 6.png",
    ],
    [
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 7.png",
      "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 8.png",
      // "https://storage.onindonesia.id/ondelivery.id/home-asset/Mockup Phone 9.png",
    ],
  ];

  imagesScreen: string[] = this.imageScreens[0];

  currentCarouselId: string = "banner-carousel";
  benefitContent: any = this.benefitText[0];
  selectedButton: number = 1;

  ngAfterViewInit() {
    this.addCarouselEventListener();
    // $(".owl-one").owlCarousel({
    //   loop: true,
    //   margin: 10,
    //   nav: true,
    //   autoplay: true,
    //   autoplayTimeout: 1000,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     600: {
    //       items: 3,
    //     },
    //     1000: {
    //       items: 5,
    //     },
    //   },
    // });
  }

  addCarouselEventListener() {
    const carouselElement = document.querySelector(".carousel");
    if (carouselElement) {
      this.renderer.listen(carouselElement, "slid.bs.carousel", () => {
        const activeItem = carouselElement.querySelector(
          ".carousel-item.active"
        );
        const index = Array.from(activeItem.parentElement.children).indexOf(
          activeItem
        );
        this.updateSelectedButton(index);
      });
    }
  }

  hoverButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.benefitContent = this.benefitText[buttonNumber - 1];
    this.imagesScreen = this.imageScreens[buttonNumber - 1];
    this.currentCarouselId = `banner-carousel${
      buttonNumber === 1 ? "" : buttonNumber
    }`;
  }

  updateSelectedButton(index: number) {
    this.selectedButton = Math.floor(index / 3) + 1;
  }
}
