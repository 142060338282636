import { Component, OnInit } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  ONAPPS_MAPS_API_KEY = "AIzaSyDPX9Rwk8CKJJlFmIUsKgmW13iJnvIsdZg";
  private map: google.maps.Map;
  constructor() {}

  ngOnInit(): void {
    this.getMaps();
  }

  search(): void {
    console.log("Searching...");
  }

  getMaps() {
    const loader = new Loader({
      apiKey: this.ONAPPS_MAPS_API_KEY,
    });
    loader.load().then(() => {
      const latLng = { lat: -6.161373992888674, lng: 106.92572601141073 };
      const address =
        "Rukan SCBR, Jalan Boulevard Raya Jl. Agung Sedayu City No.2 Blok. E, RT.3/RW.9, Cakung Bar., Kec. Cakung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 14140";

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: latLng,
      });

      const marker = new google.maps.Marker({
        position: latLng,
        map: map,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: address,
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    });
  }
}
