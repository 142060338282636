import { BrowserModule } from "@angular/platform-browser";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { NgModule } from "@angular/core";

import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatTableModule } from "@angular/material/table";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { AppRoutes } from "./app-routing.module";
import { AuthGuard } from "./auth.guard";
import { AgentGuard } from "./agent.guard";
import { ApiService } from "./api.service";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { AppComponent } from "./app.component";
import { FeatureOneComponent } from "./components/features/feature-one/feature-one.component";
import { FooterOneComponent } from "./components/footer/footer-one/footer-one.component";
import { ScrollupComponent } from "./components/scrollup/scrollup.component";
import { ThemeComponent } from "./themes/theme.component";
import { BreadcrumbInformationComponent } from "./components/breadcrumb/breadcrumb-information/breadcrumb-information.component";
import { BreadcrumbProfileComponent } from "./components/breadcrumb/breadcrumb-profile/breadcrumb-profile.component";
import { BreadcrumbRegisterComponent } from "./components/breadcrumb/breadcrumb-register/breadcrumb-register.component";
import { TrackingComponent } from "./components/track/tracking/tracking.component";
import {
  DroppointComponent,
  MapsInfo,
} from "./components/track/droppoint/droppoint.component";
import { CostComponent } from "./components/track/cost/cost.component";
import { OnserviceComponent } from "./components/onservice/onservice.component";
import { AboutComponent } from "./components/about/about.component";
import { FaqComponent } from "./components/information/faq/faq.component";
import { NewsPromosComponent } from "./components/information/news-promos/news-promos.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { RegisterUserComponent } from "./components/register-user/register-user.component";
import { ProfileComponent } from "./components/accounts/profile/profile.component";
import { LoginComponent } from "./components/accounts/login/login.component";
import { SignUpComponent } from "./components/accounts/signup/signup.component";
import { ResetComponent } from "./components/accounts/reset/reset.component";
import { OrderComponent } from "./components/accounts/profile/order/order.component";
import { HistoryComponent } from "./components/accounts/profile/history/history.component";
import { MyprofileComponent } from "./components/accounts/profile/myprofile/myprofile.component";
import { AgentComponent } from "./components/accounts/profile/agent/agent.component";
import { SubmitDialogComponent } from "./components/accounts/reset/submit-dialog/submit-dialog.component";
import { NewPasswordComponent } from "./components/accounts/new-password/new-password.component";
import { CostResultDialogComponent } from "./components/track/cost/cost-result-dialog/cost-result-dialog.component";
import { SafePipe } from "./safe.pipe";
import { VerifyEmailComponent } from "./components/accounts/verify-email/verify-email.component";
import { CreateBookingComponent } from "./components/accounts/profile/create-booking/create-booking.component";
import { CreateBookingDialogComponent } from "./components/accounts/profile/create-booking/create-booking-dialog/create-booking-dialog.component";
import { MatStepperModule } from "@angular/material/stepper";
import { ChangePasswordComponent } from "./components/accounts/profile/change-password/change-password.component";
import { RegisterDialog } from "./components/registrasi-front/registrasi-front.component";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { NewsDetailComponent } from "./components/information/news-promos/news-detail/news-detail.component";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { SafeUrlPipe } from "./safe-url.pipe";
import { DetailBookingComponent } from "./components/accounts/profile/order/detail-booking/detail-booking.component";
import { TrainingComponent } from "./components/training/training.component";
import { BreadcrumbTrainingComponent } from "./components/breadcrumb/breadcrumb-training/breadcrumb-training.component";
import { TrainingDetailComponent } from "./components/training/training-detail/training-detail.component";
import { BreadcrumbDetailtrainingComponent } from "./components/breadcrumb/breadcrumb-detailtraining/breadcrumb-detailtraining.component";
import { AgentInquiriesComponent } from "./components/contact/agent-inquiries/agent-inquiries.component";
import { BusinessInquiriesComponent } from "./components/contact/business-inquiries/business-inquiries.component";
import { NumberDirective } from "./components/contact/numbers-input";
import { WhatsappCornerComponent } from "./components/whatsapp-corner/whatsapp-corner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { MyBookingComponent } from "./components/accounts/profile/my-booking/my-booking.component";
import { CourierPartnerComponent } from "./components/courier-partner/courier-partner.component";
import { UploadDocumentComponent } from "./components/courier-partner/upload-document/upload-document.component";
import { VerifyPhoneComponent } from "./components/accounts/verify-phone/verify-phone.component";
import { NavigationListBarComponent } from "./components/navigation-list-bar/navigation-list-bar.component";
import { EventsComponent } from "./components/events/events.component";
import { OnappsComponent } from "./components/onapps/onapps.component";
import { TrackingDialogComponent } from "./components/track/tracking/tracking-dialog/tracking-dialog.component";
import { B2bLogisticComponent } from "./components/b2b-logistic/b2b-logistic.component";
import { AgentInquiriesInfoComponent } from "./components/contact/agent-inquiries-info/agent-inquiries-info.component";
import { BusinessInquiriesInfoComponent } from "./components/contact/business-inquiries-info/business-inquiries-info.component";
import { HelpComponent } from "./components/help/help.component";
import { ExpressComponent } from "./components/track/cost/express/express.component";
import { B2bCostComponent } from "./components/track/cost/b2b-cost/b2b-cost.component";
import { TruckingComponent } from "./components/trucking/trucking.component";
import { BenefitComponentComponent } from './components/benefit-component/benefit-component.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    // CounterComponent,
    FeatureOneComponent,
    // WorkComponent,
    // PricingOneComponent,
    // TeamComponent,
    // ContactComponent,
    FooterOneComponent,
    // FooterTwoComponent,
    ScrollupComponent,
    ThemeComponent,
    // FeatureTwoComponent,
    // DiscoverTwoComponent,
    // ReviewComponent,
    // BannerComponent,
    // FeatureThreeComponent,
    // ServiceFourComponent,
    // DiscoverFourComponent,
    // FeatureFourComponent,
    // FeatureFiveComponent,
    // BrandComponent,
    // FeatureSixComponent,
    // ThankYouComponent,
    LoginComponent,
    SignUpComponent,
    ResetComponent,
    // BreadcrumbPricingComponent,
    // BreadcrumbReviewsComponent,
    // BreadcrumbFaqComponent,
    // BreadcrumbContactComponent,
    // BreadcrumbCareerComponent,
    // BreadcrumbBlogComponent,
    // ReviewPageComponent,
    // SubscribePageComponent,
    MainHeaderComponent,
    TrackingComponent,
    DroppointComponent,
    CostComponent,
    OnserviceComponent,
    AboutComponent,
    FaqComponent,
    NewsPromosComponent,
    // TermsConditionsComponent,
    // BreadcrumbTrackComponent,
    // BreadcrumbServiceComponent,
    BreadcrumbInformationComponent,
    BreadcrumbRegisterComponent,
    // WhyUsComponent,
    // TrackFrontComponent,
    RegisterUserComponent,
    // PrivacyPolicyComponent,
    ProfileComponent,
    BreadcrumbProfileComponent,
    OrderComponent,
    HistoryComponent,
    MyprofileComponent,
    AgentComponent,
    SubmitDialogComponent,
    NewPasswordComponent,
    CostResultDialogComponent,
    SafePipe,
    MapsInfo,
    VerifyEmailComponent,
    CreateBookingComponent,
    CreateBookingDialogComponent,
    ChangePasswordComponent,
    // DangerousGoodsComponent,
    // OnserviceFrontComponent,
    // WhyUsFrontComponent,
    // RegistrasiFrontComponent,
    // TrackFrontDummyComponent,
    // PromoFrontComponent,
    // PromoOktComponent,
    RegisterDialog,
    // CareerComponent,
    NewsDetailComponent,
    SafeHtmlPipe,
    // VacanciesComponent,
    // DetailVacancyComponent,
    DetailBookingComponent,
    TrainingComponent,
    BreadcrumbTrainingComponent,
    TrainingDetailComponent,
    SafeUrlPipe,
    BreadcrumbDetailtrainingComponent,
    AgentInquiriesComponent,
    BusinessInquiriesComponent,
    NumberDirective,
    WhatsappCornerComponent,
    MyBookingComponent,
    CourierPartnerComponent,
    UploadDocumentComponent,
    VerifyPhoneComponent,
    NavigationListBarComponent,
    EventsComponent,
    OnappsComponent,
    TrackingDialogComponent,
    HelpComponent,
    AgentInquiriesInfoComponent,
    BusinessInquiriesInfoComponent,
    B2bLogisticComponent,
    ExpressComponent,
    B2bCostComponent,
    TruckingComponent,
    BenefitComponentComponent,
  ],
  imports: [
    BrowserModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MaterialModule,
    NgbModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: "legacy" }),
  ],
  providers: [
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ApiService,
    AgentGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginComponent, MapsInfo, RegisterDialog],
})
export class AppModule {}
