import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import blackpink from './submit-dialog-translate.json'

@Component({
  selector: 'app-submit-dialog',
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.css']
})
export class SubmitDialogComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor(public dialogRef: MatDialogRef<SubmitDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private routes: Router,) { }

    OK(): void{
      this.dialogRef.close()
    }
    
    ngOnInit(): void {
      this.translateArr = blackpink[localStorage.getItem("translate")]
  } 

}
