<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <app-breadcrumb-training></app-breadcrumb-training>
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section id="blog" class="section blog-area">
      <div class="container">
        <div class="pt_70">
          <div class="d-flex flex-column wrap pl-3">
            <h2>{{translateArr.bp1}}</h2>
            <h4 class="m-0">{{translateArr.bp2}}</h4>
            <div class="row col-12 pl-0 py-3">
              <div class="col-md-7 col-sm-12">
                <input class="form-control" type="search" (keydown.enter)="applyFilter()" [(ngModel)]="searchText"
                  placeholder="{{translateArr.bp3}}">
              </div>
              <div class="col">
                <button type="submit" class="btn btn-primary" (click)="applyFilter()">
                  {{translateArr.bp4}}
                </button>
              </div>
            </div>
          </div>
          <div class="py-5">
            <nav mat-tab-nav-bar>
              <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive" queryParamsHandling="preserve"
                (click)="resetPageIndex()">
                {{link.label}}</a>
            </nav>

            <div class="pt-3" fxLayout="row wrap">
              <div style="overflow-x:auto;">
                <table mat-table [dataSource]="dataSource" matSort class="tables wrap" style="overflow-x:auto;">
                  <ng-container matColumnDef="recipient">
                    <th mat-header-cell *matHeaderCellDef> Recipient </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex test">
                        <ul class="list-news ">
                          <li>
                            <div class="single-post media py-md-3 p-sm-0 py-3 d-flex flex-wrap px-3">
                              <div class="post-img mr-4">
                                <a *ngIf="element.video.url != null; then VideoThumb; else YoutubeThumb"
                                  [routerLink]="['./../post', element.key]">
                                  <video class="vid-thumb" src="{{ element.video ? element.video.url : '' }}"></video>
                                </a>
                                <ng-template #VideoThumb>
                                  <a [routerLink]="['./../post', element.key]">
                                    <video class="vid-thumb" src="{{ element.video ? element.video.url : '' }}"></video>
                                  </a>
                                </ng-template>
                                <ng-template #YoutubeThumb>
                                  <a [routerLink]="['./../post', element.key]">
                                    <img src="https://img.youtube.com/vi/{{changeUrl(element.youtube_url)}}/0.jpg" />
                                  </a>
                                </ng-template>
                              </div>

                              <div class="post-content media-body pl-md-3 pl-sm-0">
                                <h3 class="h3">
                                  <a [routerLink]="['./../post', element.key]">{{element.title}}</a>
                                </h3>
                                <div class="post-date">
                                  {{arrayToString(element.target)}} |
                                  {{element.updatedAt}}</div>
                                <div class="over" [innerHtml]="element.description | safeHtml"></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-4" style="color: gray;" colspan="9999">
                      {{translateArr.bp5}}
                    </td>
                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 15]" [length]="totalData" [pageSize]="queryTable.pageSize"
                  (page)="onPaginateChange($event)" [pageIndex]="queryTable.pageIndex"
                  [disabled]="isLoading"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one>
  </div>
</div>