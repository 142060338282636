import { Component, OnInit } from '@angular/core';
import blackpink from './agent-translate.json'

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
