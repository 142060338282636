import { Component, OnInit } from '@angular/core';
import blackpink from './breadcrumb-training-translate.json'

@Component({
  selector: 'app-breadcrumb-training',
  templateUrl: './breadcrumb-training.component.html',
  styleUrls: ['./breadcrumb-training.component.css']
})
export class BreadcrumbTrainingComponent implements OnInit {

  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
