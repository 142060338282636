<form id="contact-form ">
  <div class="row">
    <div class="form-group row col-12">
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>From</mat-label>
          <input matInput [matAutocomplete]="autoDist" [formControl]="form.controls['origin']" />
          <mat-autocomplete autoActiveFirstOption #autoDist="matAutocomplete" [displayWith]="displayFnOrigin">
            <mat-option *ngFor="let option of originSearchData | async" [value]="option">
              {{ option.urban_name }},
              {{ option.sub_district_name }},
              {{ option.city_name }}, Prov.{{
              option.province_name
              }},
              {{ option.postal_code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <small *ngIf="
                        form.controls['origin'].hasError('required') &&
                        form.controls['origin'].touched
                      " class="text-danger support-text">{{ translateArr.bp20 }}</small>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>To</mat-label>
          <input matInput [matAutocomplete]="autoDistt" [formControl]="form.controls['dest']" />
          <mat-autocomplete autoActiveFirstOption #autoDistt="matAutocomplete" [displayWith]="displayFnDest">
            <mat-option *ngFor="let option of destinationSearchData | async" [value]="option">
              {{ option.urban_name }},
              {{ option.sub_district_name }},
              {{ option.city_name }}, Prov.{{
              option.province_name
              }},
              {{ option.postal_code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <small *ngIf="
                        form.controls['dest'].hasError('required') &&
                        form.controls['dest'].touched
                      " class="text-danger support-text">{{ translateArr.bp6 }}</small>
      </div>
      <div class="col-sm-2">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput [formControl]="form.controls['weight']" />
          <span matSuffix class="suffix">{{
            translateArr.bp8
            }}</span>
        </mat-form-field>
        <small *ngIf="
                        form.controls['weight'].hasError('pattern') &&
                        form.controls['weight'].touched
                      " class="text-danger support-text">{{ translateArr.bp9 }}</small>
        <small *ngIf="
                        form.controls['weight'].hasError('required') &&
                        form.controls['weight'].touched
                      " class="text-danger support-text">{{ translateArr.bp10 }}</small>
      </div>
    </div>
    <div class="form-group row col-12">
      <label for="dimension" class="col-sm-2 col-form-label text-left">
        <mat-checkbox [formControl]="form.controls['dimension']" (change)="checkDimension($event)">
        </mat-checkbox>
        <span></span>
        {{ translateArr.bp21 }}
      </label>
    </div>
    <div class="form-group row col-12" *ngIf="dimensionCheck === true">
      <div class="col-sm-8">
        <div class="d-flex col-12 row wrap">
          <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-3 pl-0 pr-3">
            <mat-label>{{ translateArr.bp12 }}</mat-label>
            <input matInput type="number" [formControl]="form.controls['long']" />
            <span matSuffix class="suffix">{{
              translateArr.bp13
              }}</span>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-3 pl-0 pr-3">
            <mat-label>{{ translateArr.bp14 }}</mat-label>
            <input matInput type="number" [formControl]="form.controls['width']" />
            <span matSuffix class="suffix">{{
              translateArr.bp13
              }}</span>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-3 pl-0 pr-3">
            <mat-label>{{ translateArr.bp15 }}</mat-label>
            <input matInput type="number" [formControl]="form.controls['height']" />
            <span matSuffix class="suffix">{{
              translateArr.bp13
              }}</span>
          </mat-form-field>
        </div>
        <small *ngIf="
                        (form.controls['long'].hasError('required') &&
                          form.controls['long'].touched) ||
                        (form.controls['width'].hasError('required') &&
                          form.controls['width'].touched) ||
                        (form.controls['height'].hasError('required') &&
                          form.controls['height'].touched)
                      " class="text-danger support-text">{{ translateArr.bp16 }}</small>
        <div>
          <small *ngIf="
                          (form.controls['long'].hasError('pattern') &&
                            form.controls['long'].touched) ||
                          (form.controls['width'].hasError('pattern') &&
                            form.controls['width'].touched) ||
                          (form.controls['height'].hasError('pattern') &&
                            form.controls['height'].touched)
                        " class="text-danger support-text">{{ translateArr.bp17 }}</small>
        </div>
        <div>
          <small *ngIf="
                          form.errors != null &&
                          form.errors.existValue &&
                          form.controls['weight'].touched &&
                          form.controls['long'].touched &&
                          form.controls['width'].touched &&
                          form.controls['height'].touched
                        " class="text-danger support-text">{{ translateArr.bp18 }}</small>
        </div>
      </div>
    </div>
    <div class="col-sm-2 pb-3" style="max-width: 150px !important">
      <button class="btn w-100" type="submit" (click)="checkPrice()">
        {{ translateArr.bp19 }}
      </button>
    </div>
  </div>
</form>