<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-breadcrumb-blog></app-breadcrumb-blog> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section id="blog" class="section blog-area">
      <div class="container ptb_100">
        <section id="highlight-news" class="section">
          <a href="article/post/{{ newNews[newsHighlightIndex]?.key }}">
            <mat-card class="highlight-news-card">
              <div class="description">
                <div>
                  <h5>{{ newNews[newsHighlightIndex]?.category }}</h5>
                  <h4>{{ newNews[newsHighlightIndex]?.title }}</h4>
                  <p>{{ newNews[newsHighlightIndex]?.description }}</p>
                </div>
                <div class="d-flex" style="gap: 8px; margin-bottom: 20px">
                  <ng-container *ngFor="let item of newNews; let i = index">
                    <span
                      class="stepper"
                      [ngClass]="{ active: i === newsHighlightIndex }"
                    ></span>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <img
                  [src]="newNews[newsHighlightIndex]?.image.url"
                  alt="news image"
                />
              </div>
            </mat-card>
          </a>
        </section>

        <section id="newer-news" class="section">
          <h2>Berita Terbaru</h2>
          <h6>Update promo dan berita terbaru seputar ONDELIVERY</h6>
          <div class="row">
            <div class="owl-one owl-carousel owl-theme">
              <div class="item" *ngFor="let news of newNews">
                <mat-card class="newer-news">
                  <a href="article/post/{{ news.key }}">
                    <img [src]="news.image.url" alt="news image"
                  /></a>
                  <div class="card-description">
                    <a href="article/post/{{ news.key }}">
                      <div class="row">
                        <div class="col-6">
                          <h5>{{ news.category }}</h5>
                        </div>
                        <div class="d-flex justify-content-end col-6">
                          <!-- <h5>{{ news.category }}</h5> -->
                          <h6>{{ news.createdAt }}</h6>
                        </div>
                      </div>
                      <h4>{{ news.title }}</h4>
                      <p>{{ news.description }}</p>
                    </a>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </section>

        <section id="other-news" class="section">
          <h2>Berita Lainnya</h2>
          <h6>Update promo dan berita terbaru seputar ONDELIVERY</h6>

          <div class="py-5">
            <nav mat-tab-nav-bar>
              <a
                mat-tab-link
                *ngFor="let link of navLinks"
                [routerLink]="link.link"
                routerLinkActive
                #rla="routerLinkActive"
                [active]="rla.isActive"
                queryParamsHandling="preserve"
                (click)="resetPageIndex()"
              >
                {{ link.label }}</a
              >
            </nav>
          </div>

          <div class="news-container py-3">
            <mat-card class="news-card" *ngFor="let news of newsList">
              <img
                mat-card-image
                class="card-image"
                [src]="news.image.url"
                alt="News image"
              />
              <mat-card-content>
                <div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="text-muted" style="font-size: 12px">{{
                      news.createdAt
                    }}</span>
                    <span
                      style="
                        font-size: 12px;
                        font-weight: 700;
                        border-radius: 5px;
                        background: #e5e7eb;
                        padding: 5px 8px;
                      "
                      >{{ news.category }}</span
                    >
                  </div>
                  <h5 class="news-title">{{ news.title }}</h5>
                  <p class="news-description">{{ news.description }}</p>
                </div>
                <div style="position: absolute; bottom: 15px; left: 50%">
                  <a href="article/post/{{ news.key }}" class="read-more-news"
                    >Lihat Selengkapnya
                    <mat-icon>arrow_right_alt</mat-icon>
                  </a>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-paginator
            [pageSizeOptions]="[6, 12, 18]"
            [length]="totalData"
            [pageSize]="queryTable.pageSize"
            (page)="onPaginateChange($event)"
            [pageIndex]="queryTable.pageIndex"
            [disabled]="isLoading"
          >
          </mat-paginator>
        </section>

        <!-- <div class="pt_70">
          <div class="d-flex flex-column wrap pl-3">
            <h2>{{translateArr.bp1}}</h2>
            <h4 class="m-0">{{translateArr.bp2}}</h4>
            <div class="row col-12 pl-0 py-3">
              <div class="col-md-7 col-sm-12">
                <input class="form-control" type="search" [(ngModel)]="searchText" placeholder="{{translateArr.bp3}}">
              </div>
              <div class="col">
                <button type="submit" class="btn btn-primary" (click)="applyFilter()">
                  {{translateArr.bp4}}
                </button>
              </div>
            </div>
          </div> -->

        <!-- <div class="py-5">
            <nav mat-tab-nav-bar>
              <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive" queryParamsHandling="preserve"
                (click)="resetPageIndex()">
                {{link.label}}</a>
            </nav>

            <div class="pt-3" fxLayout="row wrap">
              <div style="overflow-x:auto;">
                <table mat-table [dataSource]="dataSource" matSort class="tables wrap" style="overflow-x:auto;">
                  <ng-container matColumnDef="recipient">
                    <th mat-header-cell *matHeaderCellDef> Recipient </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex test">
                        <ul class="list-news ">
                          <li>
                            <div class="single-post media py-md-3 p-sm-0 py-3 d-flex flex-wrap px-3">
                              <div class="post-img mr-4">
                                <a href="article/post/{{element.key}}"><img class="align-self-center br_5"
                                    src="{{element.image.url}}" alt=""></a>
                              </div>
                              <div class="post-content media-body pl-md-3 pl-sm-0">
                                <h3 class="h3">
                                  <a href="article/post/{{element.key}}">{{element.title}}</a>
                                </h3>
                                <div class="post-date">{{element.category | uppercase }}
                                  |
                                  {{element.dept}} |
                                  {{element.updatedAt}}</div>
                                <div class="over">{{element.description}}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-4" style="color: gray;" colspan="9999">
                      {{translateArr.bp5}}
                    </td>
                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[6, 12, 18]" [length]="totalData" [pageSize]="queryTable.pageSize"
                  (page)="onPaginateChange($event)" [pageIndex]="queryTable.pageIndex"
                  [disabled]="isLoading"></mat-paginator>
              </div>
            </div>
          </div>-->
        <!-- </div> -->
      </div>
    </section>
  </div>
</div>
<app-footer-one></app-footer-one>
