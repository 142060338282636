<mat-dialog-content style="overflow: hidden !important">
  <mat-card class="mat-elevation-z12">
    <mat-card-title style="text-align: center; padding-bottom: 2%">
      <img
        style="height: 50px !important"
        src="https://storage.onindonesia.id/ondelivery.id/logo-ondelivery-full.png"
        loading="lazy"
      />
    </mat-card-title>
    <mat-card-content>
      <form class="mt-4" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ translateArr.bp10 }}"
            required
            [formControl]="form.controls['username']"
          />
          <small
            *ngIf="
              form.controls['username'].hasError('required') &&
              form.controls['username'].touched
            "
            class="text-danger support-text"
            >{{ translateArr.bp1 }}</small
          >
          <small
            *ngIf="errorLogin == 'INVALID_CREDENTIAL'"
            class="text-danger support-text"
            ><br />{{ translateArr.bp2 }}</small
          >
          <small
            *ngIf="errorLogin == 'ACCOUNT_DISABLED'"
            class="text-danger support-text"
            ><br />{{ translateArr.bp3 }}</small
          >
          <small
            *ngIf="errorLogin == 'ACCOUNT_NOT_CONFIRMED'"
            class="text-danger support-text"
            ><br />{{ translateArr.bp12 }}</small
          >
          <small
            *ngIf="errorLogin == 'OTP_NOT_CONFIRMED'"
            class="text-danger support-text"
            ><br />{{ translateArr.bp13 }}</small
          >
          <small
            *ngIf="errorLogin == 'SERVICE_ERROR'"
            class="text-danger support-text"
            ><br />{{ translateArr.bp14 }}</small
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ translateArr.bp11 }}"
            type="password"
            required
            minlength="6"
            [type]="hide ? 'password' : 'text'"
            [formControl]="form.controls['password']"
          />
          <small
            *ngIf="
              form.controls['password'].hasError('required') &&
              form.controls['password'].touched
            "
            class="text-danger support-text"
            >{{ translateArr.bp4 }}</small
          >
          <!-- <small *ngIf="InvalidEmailPassword[1]" class="text-danger support-text"><br />Invalid Password</small> -->
          <span
            style="cursor: pointer"
            required="required"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon style="font-size: 21px; padding-bottom: 30px">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </span>
        </mat-form-field>
        <button
          color="primary"
          class="login-button btn btn-lg"
          type="submit"
          [disabled]="!form.valid"
        >
          {{ translateArr.bp6 }}
        </button>
        <span style="font-size: 12px; margin-top: 5px">
          <a href="/reset" style="color: #13aad4">{{ translateArr.bp7 }}</a>
        </span>
      </form>
    </mat-card-content>
    <mat-card-content>
      <span style="font-size: 12px; margin-top: 5px; align-items: center">
        {{ translateArr.bp8 }}
        <a href="/signup" style="color: #13aad4">{{ translateArr.bp9 }}</a>
      </span>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
