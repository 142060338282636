import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { AgentGuard } from "./agent.guard";

import { ThemeComponent } from "./themes/theme.component";
import { ReviewPageComponent } from "./components/inner-pages/review-page/review-page.component";
import { TrackingComponent } from "./components/track/tracking/tracking.component";
import { DroppointComponent } from "./components/track/droppoint/droppoint.component";
import { CostComponent } from "./components/track/cost/cost.component";
import { OnserviceComponent } from "./components/onservice/onservice.component";
import { AboutComponent } from "./components/about/about.component";
import { FaqComponent } from "./components/information/faq/faq.component";
import { NewsPromosComponent } from "./components/information/news-promos/news-promos.component";
import { TermsConditionsComponent } from "./components/information/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/information/privacy-policy/privacy-policy.component";

import { ResetComponent } from "./components/accounts/reset/reset.component";
import { ProfileComponent } from "./components/accounts/profile/profile.component";
import { LoginComponent } from "./components/accounts/login/login.component";
import { SignUpComponent } from "./components/accounts/signup/signup.component";

import { VerifyEmailComponent } from "./components/accounts/verify-email/verify-email.component";

import { OrderComponent } from "./components/accounts/profile/order/order.component";
import { MyprofileComponent } from "./components/accounts/profile/myprofile/myprofile.component";
import { HistoryComponent } from "./components/accounts/profile/history/history.component";
import { AgentComponent } from "./components/accounts/profile/agent/agent.component";
import { NewPasswordComponent } from "./components/accounts/new-password/new-password.component";
import { CreateBookingComponent } from "./components/accounts/profile/create-booking/create-booking.component";
import { ChangePasswordComponent } from "./components/accounts/profile/change-password/change-password.component";
import { DangerousGoodsComponent } from "./components/information/dangerous-goods/dangerous-goods.component";
import { PromoOktComponent } from "./components/promo-front/promo-okt/promo-okt.component";
import { ThankYouComponent } from "./components/accounts/signup/thank-you/thank-you.component";
import { CareerComponent } from "./components/career/career.component";
import { NewsDetailComponent } from "./components/information/news-promos/news-detail/news-detail.component";
import { VacanciesComponent } from "./components/career/vacancies/vacancies.component";
import { DetailVacancyComponent } from "./components/career/detail-vacancy/detail-vacancy.component";
import { CreateBookingDialogComponent } from "./components/accounts/profile/create-booking/create-booking-dialog/create-booking-dialog.component";
import { DetailBookingComponent } from "./components/accounts/profile/order/detail-booking/detail-booking.component";
import { TrainingComponent } from "./components/training/training.component";
import { TrainingDetailComponent } from "./components/training/training-detail/training-detail.component";
import { AgentInquiriesComponent } from "./components/contact/agent-inquiries/agent-inquiries.component";
import { BusinessInquiriesComponent } from "./components/contact/business-inquiries/business-inquiries.component";
import { MyBookingComponent } from "./components/accounts/profile/my-booking/my-booking.component";
import { CourierPartnerComponent } from "./components/courier-partner/courier-partner.component";
import { UploadDocumentComponent } from "./components/courier-partner/upload-document/upload-document.component";
import { VerifyPhoneComponent } from "./components/accounts/verify-phone/verify-phone.component";
import { EventsComponent } from "./components/events/events.component";
import { OnappsComponent } from "./components/onapps/onapps.component";
import { TrackingDialogComponent } from "./components/track/tracking/tracking-dialog/tracking-dialog.component";
import { HelpComponent } from "./components/help/help.component";
import { BusinessInquiriesInfoComponent } from "./components/contact/business-inquiries-info/business-inquiries-info.component";
import { AgentInquiriesInfoComponent } from "./components/contact/agent-inquiries-info/agent-inquiries-info.component";
import { B2bLogisticComponent } from "./components/b2b-logistic/b2b-logistic.component";
import { TruckingComponent } from "./components/trucking/trucking.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: ThemeComponent,
  },
  {
    path: "thank-you",
    component: ThankYouComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "reset",
    component: ResetComponent,
  },
  {
    path: "reviews",
    component: ReviewPageComponent,
  },
  {
    path: "keagenan",
    component: AgentInquiriesComponent,
  },
  {
    path: "keagenan-info",
    component: AgentInquiriesInfoComponent,
  },
  {
    path: "vip-customer",
    component: BusinessInquiriesComponent,
  },
  {
    path: "vip-customer-info",
    component: BusinessInquiriesInfoComponent,
  },
  {
    path: "track",
    component: TrackingComponent,
  },
  {
    path: "track/:detail",
    component: TrackingDialogComponent,
  },
  {
    path: "drop-point",
    component: DroppointComponent,
  },
  {
    path: "cost-estimation",
    component: CostComponent,
  },
  {
    path: "ondelivery-express",
    component: OnserviceComponent,
  },
  {
    path: "tentang-kami",
    component: AboutComponent,
  },
  {
    path: "pusat-bantuan",
    component: HelpComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "ondelivery-logistics",
    component: B2bLogisticComponent,
  },
  {
    path: "ondelivery-trucking",
    component: TruckingComponent,
  },

  {
    path: "article/post/:type",
    component: NewsDetailComponent,
  },

  {
    path: "article/:type",
    component: NewsPromosComponent,
  },

  {
    path: "signup",
    component: SignUpComponent,
  },
  {
    path: "reset-password/:key",
    component: NewPasswordComponent,
  },
  {
    path: "verify-email/:key",
    component: VerifyEmailComponent,
  },
  {
    path: "courier-partner/otp",
    component: VerifyPhoneComponent,
  },
  {
    path: "training",
    component: TrainingComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "events",
    component: EventsComponent,
  },
  {
    path: "onapps",
    component: OnappsComponent,
  },
  {
    path: "training/:type",
    canActivate: [AgentGuard],
    runGuardsAndResolvers: "always",
    component: TrainingComponent,
  },
  {
    path: "training/post/:type",
    canActivate: [AgentGuard],
    runGuardsAndResolvers: "always",
    component: TrainingDetailComponent,
  },
  {
    path: "courier-partner",
    component: CourierPartnerComponent,
  },
  {
    path: "upload-document",
    component: UploadDocumentComponent,
  },
  {
    path: "user",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "",
        redirectTo: "/user/profile",
        pathMatch: "full",
      },
      {
        path: "profile",
        component: MyprofileComponent,
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
      },
      {
        path: "create-book",
        component: CreateBookingComponent,
      },
      {
        path: "order",
        component: OrderComponent,
      },
      {
        path: "history",
        component: HistoryComponent,
      },
      {
        path: "agent",
        component: AgentComponent,
      },
      {
        path: "booking-invoice",
        component: CreateBookingDialogComponent,
      },
      {
        path: "detail-booking/:type",
        component: DetailBookingComponent,
      },
      {
        path: "my-booking",
        component: MyBookingComponent,
      },
    ],
  },
  // {
  //   path: "promo-10-10",
  //   component: PromoOktComponent,
  // },

  // {
  //   path: "career",
  //   component: CareerComponent,
  // },
  // {
  //   path: "vacancies",
  //   component: VacanciesComponent,
  // },
  // {
  //   path: "contact",
  //   component: ContactComponent,
  // },
  // {
  //   path: "vacancies/:type",
  //   component: VacanciesComponent,
  // },
  // {
  //   path: "vacancies/:type/:vacancyKey",
  //   component: DetailVacancyComponent,
  // },
  // {
  //   path: "vacancies/job-detail",
  //   component: DetailVacancyComponent,
  // },
  // {
  //   path: "terms-and-conditions",
  //   component: TermsConditionsComponent,
  // },
  // {
  //   path: "privacy-policy",
  //   component: PrivacyPolicyComponent,
  // },
  // {
  //   path: "package-information",
  //   component: DangerousGoodsComponent,
  // },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full",
  },
];
