import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ErrorStateMatcher } from "@angular/material/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Loader } from "@googlemaps/js-api-loader";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DecimalPipe } from "@angular/common";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute } from "@angular/router";
import blackpink from "./droppoint-translate.json";

export interface AddressData {
  locationId: number;
  city: string;
  locationName: string;
  locationSide: string;
  address: string;
  phoneNumber: string;
  mapsLinks: string;
  latitude: string;
  longitude: string;
}

@Component({
  selector: "app-droppoint",
  templateUrl: "./droppoint.component.html",
  styleUrls: ["./droppoint.component.scss"],
})
export class DroppointComponent implements OnInit {
  ONAPPS_MAPS_API_KEY = "AIzaSyDPX9Rwk8CKJJlFmIUsKgmW13iJnvIsdZg";
  translateArr = blackpink[localStorage.getItem("translate")];
  searchText: any;
  displayedColumns = [
    "locationId",
    "city",
    "locationName",
    "locationSide",
    "address",
    "action",
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatTable, { static: true }) table: MatTable<any> =
    Object.create(null);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);
  @ViewChild("mapRef", { static: true }) mapElement: ElementRef;

  constructor(
    public dialog: MatDialog,
    private service: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.service.viewAddreessDetail().subscribe(
      (data) => {
        this.dataSource.data = data.rows;
        // console.log(this.dataSource.data)
        this.renderMap();
      },
      (e) => {
        console.log(e);
      }
    );

    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  loadMap = () => {
    var image = {
      url: "https://storage.onindonesia.id/ondelivery.id/marker2.png",
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 0),
    };

    var InforObj = [];
    var map = new window["google"].maps.Map(this.mapElement.nativeElement, {
      center: { lat: -6.240093581893598, lng: 106.83323034429847 },
      zoom: 11,
    });

    for (let i = 0; i < this.dataSource.data.length; i++) {
      const marker = new window["google"].maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(this.dataSource.data[i].latitude),
          parseFloat(this.dataSource.data[i].longitude)
        ),
        icon: image,
        animation: window["google"].maps.Animation.DROP,
        map: map,
      });

      const contentString =
        '<div id="content" class="p-3" style="width: 350px;">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h4 id="thirdHeading" class="thirdHeading"><strong>' +
        this.dataSource.data[i].locationName +
        "</strong></h4>" +
        '<div id="bodyContent">' +
        "<p>" +
        this.dataSource.data[i].address +
        "</p>" +
        "<p>" +
        this.dataSource.data[i].city +
        "</p>" +
        "</div>" +
        "</div>";

      const infowindow = new window["google"].maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", function () {
        closeOtherInfo();
        infowindow.open(marker.get("map"), marker);
        InforObj[0] = infowindow;
      });
    }

    function closeOtherInfo() {
      if (InforObj.length > 0) {
        InforObj[0].set("marker", null);
        InforObj[0].close();
        InforObj.length = 0;
      }
    }
  };

  renderMap() {
    window["initMap"] = () => {
      this.loadMap();
    };

    const apiKey = "AIzaSyDPX9Rwk8CKJJlFmIUsKgmW13iJnvIsdZg"; // Your environment variable
    if (!window.document.getElementById("google-map-script")) {
      var s = window.document.createElement("script");
      s.id = "google-map-script";
      s.type = "text/javascript";
      s.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;

      window.document.body.appendChild(s);
    } else {
      this.loadMap();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  performSearch() {}

  openDialog(obj: any) {
    // obj.action = action;
    const dialogRef = this.dialog.open(MapsInfo, {
      height: "600px",
      width: "1200px",
      data: obj,
    });
  }
}

@Component({
  selector: "mapsinfo",
  templateUrl: "./mapsinfo.html",
})
export class MapsInfo implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  local_data: any;
  latitude: string;
  longitude: string;
  locationName: string;
  address: string;

  @ViewChild("mapRef", { static: true }) mapElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<MapsInfo>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddressData
  ) {
    this.local_data = { ...data };
    this.latitude = this.local_data.latitude;
    this.longitude = this.local_data.longitude;
    this.locationName = this.local_data.locationName;
    this.address = this.local_data.address;
    console.log(this.local_data.latitude);
  }

  ngOnInit() {
    this.renderMap();
    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  loadMap = () => {
    this.local_data.latitude = this.latitude;
    this.local_data.longitude = this.longitude;
    var image = {
      url: "https://storage.onindonesia.id/ondelivery.id/marker2.png",
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 0),
    };

    var map = new window["google"].maps.Map(this.mapElement.nativeElement, {
      center: {
        lat: +this.local_data.latitude,
        lng: +this.local_data.longitude,
      },
      zoom: 15,
    });

    var marker = new window["google"].maps.Marker({
      position: {
        lat: +this.local_data.latitude,
        lng: +this.local_data.longitude,
      },
      map: map,
      // draggable: true,
      animation: window["google"].maps.Animation.DROP,
      icon: image,
    });

    var contentString =
      '<div id="content" class="p-3" style="width: 350px;">' +
      '<div id="siteNotice">' +
      "</div>" +
      '<h4 id="thirdHeading" class="thirdHeading"><strong>' +
      this.local_data.locationName +
      "</strong></h4>" +
      '<div id="bodyContent">' +
      "<p>" +
      this.local_data.address +
      "</p>" +
      "<p>" +
      this.local_data.phoneNumber +
      "</p>" +
      "</div>" +
      "</div>";

    var infowindow = new window["google"].maps.InfoWindow({
      content: contentString,
    });

    marker.addListener("click", function () {
      infowindow.open(map, marker);
    });
  };

  renderMap() {
    window["initMap"] = () => {
      this.loadMap();
    };
    if (!window.document.getElementById("google-map-script")) {
      var s = window.document.createElement("script");
      s.id = "google-map-script";
      s.type = "text/javascript";
      s.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDA2Kjc0wnLONRqMnois3qU2CiEmy84fw4&callback=initMap";

      window.document.body.appendChild(s);
    } else {
      this.loadMap();
    }
  }
}
