// DEKLARASI LIBRARY AND MODULE WHAT NEEDEED
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { of, Subscription } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
} from "rxjs/operators";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";
import blackpink from "./create-booking-translate.json";
import { NgxSpinnerService } from "ngx-spinner";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Loader } from "@googlemaps/js-api-loader";
// import { styles } from './mapstyles';

export interface Food {
  id: number;
  value: string;
  viewValue: string;
}

// DEFINE COMPONENT HTML AND CSS
@Component({
  selector: 'app-create-booking',
  templateUrl: "./create-booking.component.html",
  styleUrls: ["./create-booking.component.css"],
})

// EXPORT CLASS COMPONENT CREATEBOOKING KETIKA INISIASI
export class CreateBookingComponent implements OnInit {
  form1: FormGroup;
  form: FormGroup;

  private map: google.maps.Map

  get users(): FormArray {
    return this.form.get('users') as FormArray;
  }

  translateArr = blackpink[localStorage.getItem("translate")];

  showDimForm: boolean[] = [];
  showInsForm: boolean[] = [];
  serviceLeadTime: string[] = [];
  insuranceCost: number[] = [];
  minimalWeight: String[] = [];
  maximalWeight: String[] = [];
  goods_value: number[] = [];
  notes_instructions: string[] = [];
  debounce_weight: number[] = [];
  debounce_length: number[] = [];
  debounce_width: number[] = [];
  debounce_heigth: number[] = [];
  debounce_insurance: number[] = [];
  debounce_goods_value: number[] = [];
  weightSum: number[] = [];
  volumeSum: number[] = [];
  inputSum: number[] = [];
  serviceCost: number[] = [];
  totalCostPerOnce: number[] = [];
  contentList: any = [];
  serviceOptionData: any = [];
  serviceData: any = [];
  contentData: any = [];
  originData: any;
  destinationData: any = []
  senderListRev: any = [];
  destinationListRev: any = [];

  weight_subs: Subscription[] = [];
  length_subs: Subscription[] = [];
  width_subs: Subscription[] = [];
  height_subs: Subscription[] = [];
  goods_value_subs: Subscription[] = [];
  promo_subs: Subscription[] = [];
  volcheck_subs: Subscription[] = [];
  inscheck_subs: Subscription[] = [];
  service_subs: Subscription[] = [];
  content_subs: Subscription[] = []

  bookingData: any[] = [];
  destination: any = [];
  cityCheck: boolean[] = [];
  provinceCheck: boolean[] = [];

  showFormGroup: any = [];
  arrayBookingData: any[] = [];

  finalTotalCost: number;
  bookingIndex: number;
  verChecked: boolean;
  errorMessage: String;
  debounce_lat: number;
  debounce_lng: number;
  temp_location: any;
  oneReceiver: boolean;
  showDeleteButton: boolean;
  showAddButton: boolean;

  ONAPPS_MAPS_API_KEY = "AIzaSyDPX9Rwk8CKJJlFmIUsKgmW13iJnvIsdZg"

  // INISIALISASI METODE PENGIRIMAN
  foods: Food[] = [
    { id: 1, value: "PICKUP", viewValue: "Pick up" },
  ];

  //INISIASI INSTANCE DIDALAM FORM PENGIRIM
  form_instance_1 =
    {
      mode: ["PICKUP", Validators.compose([Validators.required])],
      sender_name: [null, Validators.compose([Validators.required])],
      sender_address: [null],
      sender_phone: [
        null,
        Validators.compose([
          Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"),
          Validators.required,
          Validators.minLength(5),
        ]),
      ],
      destinationPengirim: [null, Validators.compose([Validators.required])],
      sender_loc: [null, Validators.compose([Validators.required])]
    };

  //INISIASI INSTANCE DIDALAM FORM PENERIMA
  form_instance = {
    receiver_name: [null, Validators.compose([Validators.required])],
    receiver_address: [null, Validators.compose([Validators.required])],
    receiver_phone: [
      null,
      Validators.compose([
        Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"),
        Validators.required,
        Validators.minLength(5),
      ]),
    ],
    destination: [null, Validators.compose([Validators.required])],
    goods_length: [0],
    goods_width: [0],
    goods_heigth: [0],
    packing_fee: [0],

    content_type: [null, Validators.compose([Validators.required])],
    service: [null],
    notes_instructions: [""],

    volChecked: [false],
    insChecked: [false],
    goods_koli: [1],
    goods_weight: [
      1,
      Validators.compose([Validators.pattern("^-?[0-9]+(?:.[0-9]{1,2})?$")]),
    ],

    freight_charge: [0],
    shipment_type: [3],
    side_note: [null],

    goods_value: [0],
    goods_value_cod: [0],

    insurance_fee: [0],
    total_fee: [0],
    goods_desc: [""],
    service_name: [null],
    mode: ["PICKUP", Validators.compose([Validators.required])],
  };

  displayNoteError: any = []

  form1NoteError = [
    "Mode Pengiriman",
    "Nama",
    "Alamat",
    "Nomor telpon",
    "Kota/Kecamatan",
    "Titik Penjemputan"
  ]

  formNoteError = [
    {

    }
  ]

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private routes: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.apiService.setBooking(this.bookingData);
  }

  @ViewChild("panel") public panel: ElementRef;
  @ViewChild("receiverPanel") public recPanel: ElementRef;
  @ViewChild('mapRef', { static: true }) mapElement: ElementRef;

  ngOnInit(): void {
    // SET INITIAL VALUE OF ALL VARIABLE IN FORM
    this.bookingIndex = 1;
    this.finalTotalCost = 0;
    this.verChecked = false;
    this.oneReceiver = true;
    this.showDeleteButton = false;
    this.showAddButton = true;
    this.temp_location = 'ONDELIVERY.ID'

    this.showFormGroup = [true, false, false, false, false, false, false, false, false, false];
    this.showDimForm = [false, false, false, false, false, false, false, false, false, false];
    this.showInsForm = [false, false, false, false, false, false, false, false, false, false];

    this.insuranceCost = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.goods_value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.debounce_weight = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    this.debounce_length = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.debounce_width = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.debounce_heigth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.debounce_goods_value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.weightSum = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    this.volumeSum = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.inputSum = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    this.serviceCost = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.totalCostPerOnce = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // SET FORM GROUP AND FORM ARRAY
    this.form1 = this.fb.group(this.form_instance_1)
    this.form = this.fb.group({
      users: this.fb.array([
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance),
        this.fb.group(this.form_instance)
      ])
    })

    // UPDATE MAP
    this.updateMap();

    // ADD FIRST ARRAY BOOKING DATA
    this.addData();

    //TRACKING PERUBAHAN DATA DARI FORM BERDASARKAN INPUT DARI USER
    this.subscibeOrigin();
    this.subscibeDestination();
    this.subscibeService();
    this.subscibeContent();
    this.subscibeWeight();
    this.subscibeCheckVolume();
    this.subscibeCheckInsurance();
    this.subscibeVolume();
    this.subscibeInsurance();

    //REQUEST DATA JENIS BARANG YANG AKAN DIKIRIM
    this.requestContentType();
  }

  // UPDATING MAPPING BY ORIGIN SELECT
  updateMap() {
    const loader = new Loader({
      apiKey: this.ONAPPS_MAPS_API_KEY,
    });

    loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: this.temp_location }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          this.debounce_lat = location.lat()
          this.debounce_lng = location.lng()

          const mapOptions = {
            center: { lat: location.lat(), lng: location.lng() },
            zoom: 15,
          };

          this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

          const marker = new google.maps.Marker({
            position: mapOptions.center,
            map: this.map,
          });

          this.map.addListener('click', (event) => {
            const clickedLocation = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng()
            }

            marker.setPosition(clickedLocation);
            this.debounce_lat = marker.getPosition().lat()
            this.debounce_lng = marker.getPosition().lng()
            // console.log(this.debounce_lat);
            // console.log(this.debounce_lng);
          })
        } else {
          // console.log("Geocode was not successful for the following reason: " + status);
        }

      });
    })
  }

  // GET ORIGIN DATA BY INPUT CHNAGES
  subscibeOrigin() {
    this.senderListRev = this.form1
      .get("destinationPengirim")?.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        startWith(""),
        switchMap((value) => this.sender_prePostalServ(value))
      );
  }

  // GET DESTINATION DATA BY INPUT CHNAGES
  subscibeDestination() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.destinationListRev[i] = this.users.controls[i]
        .get("destination")?.valueChanges.pipe(
          debounceTime(250),
          distinctUntilChanged(),
          startWith(""),
          switchMap((value) => this.receiver_prePostalServ(value))
        );
    }
  }

  // SUBSCIBES PERUBAHAN DATA PADA PILIHAN SERVICE
  subscibeService() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.service_subs[i] = this.users.controls[i]
        .get("service")!.valueChanges.pipe(distinctUntilChanged())
        .subscribe((term) => {
          this.serviceData[i] = term;
          if (this.serviceData[i].maxKg === null) { this.maximalWeight[i] = "-" } else { this.maximalWeight[i] = this.serviceData[i].maxKg.toString(); }
          if (this.serviceData[i].minKg === null) { this.minimalWeight[i] = "-" } else { this.minimalWeight[i] = this.serviceData[i].minKg.toString(); }
          this.serviceLeadTime[i] = this.serviceData[i].eta;
          this.checkService(i);
          // console.log(this.serviceData[i])
          // console.log("im Here! = 1")
        });
    }
  }

  // SUBSCIBES PERUBAHAN DATA PADA PILIHAN JENIS BARANG
  subscibeContent() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.content_subs[i] = this.users.controls[i]
        .get("content_type")!.valueChanges.pipe(distinctUntilChanged())
        .subscribe((term) => {
          this.contentData[i] = term;
        });
    }
  }

  // GET PERUBAHAN DATA BERAT BY INPUT CHANGES
  subscibeWeight() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.weight_subs[i] = this.users.controls[i]
        .get("goods_weight")!.valueChanges.pipe(
          filter((res) => {
            return res !== null && res >= 1;
          }),
          debounceTime(1000),
          startWith(1),
          distinctUntilChanged()
        )
        .subscribe((term) => {
          var initialWeightState: number = 1;
          this.debounce_weight[i] = parseFloat(term);
          if (initialWeightState !== term) {
            initialWeightState = term;
            this.checkService(i);
            // console.log("im Here! = 2")
          }
        });
    }
  }

  // SUBSCIBES PERUBAHAN DATA PADA CHECK BOX USING DIMENSION
  subscibeCheckVolume() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.volcheck_subs[i] = this.users.controls[i]
        .get("volChecked")!.valueChanges.pipe(distinctUntilChanged())
        .subscribe((term) => {
          this.showDimForm[i] = term;
          this.checkService(i);
          // console.log("im Here! = 3")
        });
    }
  }

  // SUBSCIBES PERUBAHAN DATA PADA CHECK BOX USING INSURANCE
  subscibeCheckInsurance() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.inscheck_subs[i] = this.users.controls[i]
        .get("insChecked")!.valueChanges.pipe(distinctUntilChanged())
        .subscribe((term) => {
          this.showInsForm[i] = term;
          this.checkService(i);
          // console.log("im Here! = 4")
        });
    }
  }

  // GET PERUBAHAN DATA PADA SETIAP KOLOM PADA DIMENSION FORM
  // VALUE CHANGES DISUBCRIBES HANYA SAAT CHECK TRUE
  subscibeVolume() {
    // SUBSCIBES PERUBAHAN DATA DI KOLOM PANJANG
    for (let i = 0; i < this.users.controls.length; i++) {
      this.length_subs[i] = this.users.controls[i]
        .get("goods_length")!.valueChanges.pipe(
          filter((res) => {
            return res !== null && res >= 1;
          }),
          debounceTime(1000),
          startWith(0),
          distinctUntilChanged()
        )
        .subscribe((term) => {
          if (this.showDimForm[i] === true) {
            this.debounce_length[i] = parseFloat(term);
            this.checkService(i);
            // console.log("im Here! = 5")
          }
        });
    }

    // SUBSCIBES PERUBAHAN DATA DI KOLOM LEBAR
    for (let i = 0; i < this.users.controls.length; i++) {
      this.width_subs[i] = this.users.controls[i]
        .get("goods_width")!.valueChanges.pipe(
          filter((res) => {
            return res !== null && res.length >= 1;
          }),
          debounceTime(1000),
          startWith(0),
          distinctUntilChanged()
        )
        .subscribe((term) => {
          if (this.showDimForm[i] === true) {
            this.debounce_width[i] = parseFloat(term);
            this.checkService(i);
            // console.log("im Here! = 6")
          }
        });
    }

    // SUBSCIBES PERUBAHAN DATA DI KOLOM TINGGI
    for (let i = 0; i < this.users.controls.length; i++) {
      this.height_subs[i] = this.users.controls[i]
        .get("goods_heigth")!.valueChanges.pipe(
          filter((res) => {
            return res !== null && res.length >= 1;
          }),
          debounceTime(1000),
          startWith(0),
          distinctUntilChanged()
        )
        .subscribe((term) => {
          if (this.showDimForm[i] === true) {
            this.debounce_heigth[i] = parseFloat(term);
            this.checkService(i);
            // console.log("im Here! = 7")
          }
        });
    }
  }

  // GET PERUBAHAN DATA HARGA BARANG UNTUK INSURANCE FEE PADA SAAT CHECK TRUE
  subscibeInsurance() {
    for (let i = 0; i < this.users.controls.length; i++) {
      this.goods_value_subs[i] = this.users.controls[i]
        .get("goods_value")!.valueChanges.pipe(
          filter((res) => {
            return res !== null && res.length >= 1;
          }),
          debounceTime(1500),
          startWith(0),
          distinctUntilChanged()
        )
        .subscribe((term) => {
          this.debounce_goods_value[i] = parseFloat(term);
          if (this.showInsForm[i] === true) {
            this.checkService(i);
            // console.log("im Here! = 8")
          }
        });
    }
  }

  // JENIS BARANG YANG DIKIRIM DOKUMEN ATAU PAKET
  requestContentType() {
    this.apiService.content_list().subscribe((contentVal) => {
      this.contentList = contentVal;
    });
  }

  // TRACK ACTION BY SELECTED EVENT TO PROCEED ORIGINDATA TO API
  originOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.originData = event.option.value;
    if (this.form1.value.sender_loc === null) {
      this.temp_location = this.originData?.urban_name + ", "
        + this.originData?.sub_district_name + ", "
        + this.originData?.city_name + ", Prov. "
        + this.originData?.province_name
    }

    for (let i = 0; i < this.bookingIndex; i++) {
      this.checkDestination(i);
      this.checkService(i)
      // console.log("im Here! = 9")
    }
    this.updateMap()
  }

  // TRACK ACTION BY SELECTED EVENT TO PROCEED DESTINATIONDATA TO API
  destinationOptionSelected(event: MatAutocompleteSelectedEvent, i: number): void {
    this.destinationData[i] = event.option.value;
    this.checkDestination(i);
    this.checkService(i);
    // console.log("im Here! = 10")
  }

  // CHECKDESTINATION PROGRAM TO GAIN DATA SERVICE AVAIL BY CALCULATE ORIGIN AND DESTINATION INPUT
  checkDestination(i: number) {
    var temp_length;
    var temp_width;
    var temp_height;

    if (this.showDimForm[i] === false) {
      (temp_length = 0), (temp_width = 0), (temp_height = 0);
    } else {
      temp_length = this.debounce_length[i];
      temp_width = this.debounce_width[i];
      temp_height = this.debounce_heigth[i];
    }

    if (this.destinationData[i]?.id !== undefined && this.originData?.id !== undefined) {
      var vorm = {
        weight: this.debounce_weight[i],
        dimension: {
          length: temp_length,
          width: temp_width,
          height: temp_height,
        },
        destinationId: this.destinationData[i].id,
        originId: this.originData.id
      };
      this.apiService.findDestWaybill(vorm).subscribe(
        (res) => {
          this.serviceOptionData[i] = res.services;
        },
        // (err) => {
        //   console.log(err);
        // }
      );
    }
  }

  // CHECKSERVICE PROGRAM TO GAIN COST AND ALL NEEDEED DATA FOR REQUEST BOOKING NUMBER
  checkService(i: number) {
    var temp_goods_value;
    var temp_length;
    var temp_width;
    var temp_heigth;

    if (this.showDimForm[i] === false) {
      temp_length = 0;
      temp_width = 0;
      temp_heigth = 0;
    } else {
      temp_length = this.debounce_length[i];
      temp_width = this.debounce_width[i];
      temp_heigth = this.debounce_heigth[i];
    }

    if (this.showInsForm[i] === false) {
      temp_goods_value = 0;
    } else {
      temp_goods_value = this.debounce_goods_value[i];
    }

    if (this.originData?.id !== undefined && this.destinationData[i]?.id !== undefined && this.serviceData[i]?.serviceId !== undefined) {
      var vorm = {
        serviceId: this.serviceData[i].serviceId,
        weight: this.debounce_weight[i],
        dimension: {
          length: temp_length,
          width: temp_width,
          height: temp_heigth,
        },
        destinationId: this.destinationData[i].id,
        originId: this.originData.id,
        goodsValue: temp_goods_value,
        packingCost: 0,
        // coupon: promo_code,
      };

      // console.log(vorm)

      this.apiService.calculateService(vorm).subscribe(
        (res) => {
          // console.log(res)
          this.weightSum[i] = res.realWeight;
          this.volumeSum[i] = res.volumetricWeight;
          this.inputSum[i] = res.inputWeight;
          this.insuranceCost[i] = res.insuranceCost;
          this.serviceCost[i] = res.freightCost;
          this.totalCostPerOnce[i] = res.totalCost;

          // GET FINAL TOTAL COST
          this.finalTotalCost = this.totalCostPerOnce.reduce((acc, curr) => acc + curr, 0);
        }
      );
    }
  }

  // CHECKING AND INDEXING TO EVERY FORM FIELD THAT STILL EMPTY
  emptyFormChecker() {
    this.displayNoteError = []
    var emptyField = 0

    if (!this.form1.valid) {
      for (const controlName in this.form1.controls) {
        const control = this.form1.controls[controlName];
        if (control.invalid && control.untouched) {
          emptyField = Object.keys(this.form1.controls).indexOf(controlName)
          console.log(emptyField)
          this.displayNoteError.push(this.form1NoteError[emptyField])
        }
      }

      console.log(this.displayNoteError)

      Swal.fire({
        title: "Gagal!",
        text: `Data tidak lengkap atau area tidak valid.\nLengkapi : ${this.displayNoteError}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        icon: "warning",
      });

    }
  }

  // REQUEST DATA ORIGIN AUTOCOMPLETE BY PERUBAHAN DATA PADA FORM VALUE ORIGIN
  private sender_prePostalServ(value: string): any {
    if (value === '') { value = "XXXXXX"; }

    return this.apiService
      .originSearchNew(value)
      .pipe(catchError((err) => of([])));
  }

  // MEMFORMAT TAMPILAN DATA DARI RESPONE ORIGIN UNTUK MENGGUNAKAN DISPLAYWITH 
  displayFnSender(sender?: any): string | undefined {
    return sender
      ? sender.urban_name +
      ", " +
      sender.sub_district_name +
      ", " +
      sender.city_name +
      ", Prov. " +
      sender.province_name +
      ", " +
      sender.postal_code
      : undefined;
  }

  // REQUEST DATA DESTINATION AUTOCOMPLETE BY PERUBAHAN DATA PADA FORM VALUE DESTINATION
  private receiver_prePostalServ(value: string): any {
    if (value === '') { value = "XXXXXX"; }

    return this.apiService
      .destinationSearchNew(value)
      .pipe(catchError((err) => of([])));
  }

  // MEMFORMAT TAMPILAN DATA DARI RESPONE DESTINATION UNTUK MENGGUNAKAN DISPLAYWITH 
  displayFnreceiver(receiver?: any): string | undefined {
    return receiver
      ? receiver.urban_name +
      ", " +
      receiver.sub_district_name +
      ", " +
      receiver.city_name +
      ", Prov. " +
      receiver.province_name +
      ", " +
      receiver.postal_code
      : undefined;
  }

  searchPickUpPosition() {
    this.temp_location = this.form1.value.sender_loc
    this.updateMap()
  }

  addReceiver() {
    // MANIPULATE DATA FOR PASSING INTO BOOKING DIALOG BASE ON BOOKINGINDEX
    // SET ONERECEIVER TO FALSE
    this.oneReceiver = false
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
      // ADD BOOKINGINDEX NUMBER UNTIL 10 MAXIMAL
      if (this.bookingIndex < 10) {
        this.bookingIndex = this.bookingIndex + 1;

      } else {
        this.bookingIndex = 10;

        // Swal.fire({
        //   title: "Mohon maaf!",
        //   text: "Pesanan maximal tidak boleh lebih dari 10.",
        //   buttonsStyling: false,
        //   customClass: {
        //     confirmButton: "btn btn-success",
        //   },
        //   icon: "warning",
        // });
      }

      // COUNT BOOKINGINDEX AND SHOW FORM GROUP BASE HOW MANY NUMBER INSIDE
      for (let i = 0; i <= this.bookingIndex; i++) {
        if (this.bookingIndex > i) { this.showFormGroup[i] = true; }
      }

      // CONDITION CHECKER FOR ADD BUTTON
      if (this.bookingIndex === 10) {
        this.showAddButton = false;
      } else {
        this.showAddButton = true;
      }

      // CONDITION CHECKER FOR ADD BUTTON
      if (this.bookingIndex === 1) {
        this.showDeleteButton = false;
      } else {
        this.showDeleteButton = true;
      }

      // CALL PROGRAM TO UPDATE DATA FROM THE FORMGROUP AND STORE IT INTO ARRAYBOOKINGDATA 
      this.addData();
    }, 500);
  }

  deleteReceiver() {
    // MANIPULATE DATA FOR PASSING INTO BOOKING DIALOG BASE ON BOOKINGINDEX
    this.spinner.show();

    // DECREASE BOOKINGINDEX NUMBER UNTIL 1 MAXIMAL
    if (this.bookingIndex > 1) {
      this.showFormGroup[this.bookingIndex - 1] = false;
      this.bookingIndex = this.bookingIndex - 1;

    } else {
      this.bookingIndex = 1;

      // Swal.fire({
      //   title: "Mohon maaf!",
      //   text: "Masukan minimal 1 pesanan.",
      //   buttonsStyling: false,
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //   },
      //   icon: "warning",
      // });
    }

    if (this.bookingIndex === 1) {
      this.oneReceiver = true;
    }

    // CONDITION CHECKER FOR ADD BUTTON
    if (this.bookingIndex === 10) {
      this.showAddButton = false;
    } else {
      this.showAddButton = true;
    }

    // CONDITION CHECKER FOR ADD BUTTON
    if (this.bookingIndex === 1) {
      this.showDeleteButton = false;
    } else {
      this.showDeleteButton = true;
    }

    // CALL PROGRAM TO UPDATE DATA FROM THE FORMGROUP AND STORE IT INTO ARRAYBOOKINGDATA 
    this.addData();

    setTimeout(() => {
      //SET PANEL TO ADD AND DELETE BUTTON
      this.recPanel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      this.spinner.hide()
    }, 300);
  }

  addData() {
    this.arrayBookingData.length = this.bookingIndex;

    for (let i = 0; i < this.bookingIndex; i++) {
      this.arrayBookingData[i] = {
        mode: this.form1.value.mode,

        destination_id: this.destinationData[i]?.id,
        origin_id: this.originData?.id,
        service_id: this.serviceData[i]?.serviceId,

        sender_name: this.form1.value.sender_name,
        sender_phone: this.form1.value.sender_phone,
        sender_address: this.form1.value.sender_address,

        receiver_name: this.users.controls[i].value.receiver_name,
        receiver_phone: this.users.controls[i].value.receiver_phone,
        receiver_address: this.users.controls[i].value.receiver_address,

        goods_id: this.contentData[i]?.id,
        goods_desc: this.users.controls[i].value.goods_desc,
        goods_weight: this.users.controls[i].value.goods_weight,
        koli: this.users.controls[i].value.goods_koli,
        length: this.users.controls[i].value.goods_length,
        width: this.users.controls[i].value.goods_width,
        height: this.users.controls[i].value.goods_heigth,
        notes: this.users.controls[i].value.notes_instructions,
        insurance_exist: this.users.controls[i].value.insChecked,
        goods_value: this.users.controls[i].value.goods_value,
        estimate_price: this.totalCostPerOnce[i],

        province_receiver: this.destinationData[i]?.province_name,
        city_receiver: this.destinationData[i]?.city_name,
        urban_receiver: this.destinationData[i]?.urban_name,
        district_receiver: this.destinationData[i]?.sub_district_name,
        post_id_receiver: this.destinationData[i]?.postal_code,

        province_sender: this.originData?.province_name,
        city_sender: this.originData?.city_name,
        urban_sender: this.originData?.urban_name,
        district_sender: this.originData?.sub_district_name,
        post_id_sender: this.originData?.postal_code,

        lat: this.debounce_lat,
        long: this.debounce_lng,

        urban_id_receiver: this.destinationData[i]?.urban_id,
        sub_district_id_receiver: this.destinationData[i]?.sub_district_id,
        city_id_receiver: this.destinationData[i]?.city_id,

        urban_id_sender: this.originData?.urban_id,
        sub_district_id_sender: this.originData?.sub_district_id,
        city_id_sender: this.originData?.city_id,

        insurance_fee: this.insuranceCost[i],
        service_name: this.serviceData[i]?.name,

        max_Weight: this.serviceData[i]?.maxKg,
        min_weight: this.serviceData[i]?.minKg,

        total_final_cost: this.finalTotalCost,
      }
    }

    // console.log(this.arrayBookingData)
  }

  // checkData() {
  //   this.addData();
  //   console.log(this.arrayBookingData)
  //   console.log("TotalCostPerOnce : " + this.totalCostPerOnce)
  //   console.log("FinalFinalCost : " + this.finalTotalCost)
  // }

  bookNow() {
    this.addData();

    if (this.verChecked) {
      this.errorMessage = "";
      this.spinner.show();

      setTimeout(() => {
        this.spinner.hide();
      }, 1500);

      const cityCheck = this.destinationData[0]?.city_name.includes("SERIBU");
      const provinceCheck = this.destinationData[0]?.province_name.includes("JAKARTA");
      const cityCheckSender = this.originData?.city_name.includes("SERIBU");
      const provinceCheckSender = this.originData?.province_name.includes("JAKARTA");

      var usersTouchCheck = 0;

      console.log("1 : ")
      console.log("provinceCheck" + provinceCheck)
      console.log("provinceCheckSender" + provinceCheckSender)

      if (provinceCheck && provinceCheckSender) {

        console.log("2 : ")
        console.log("cityCheck" + cityCheck)
        console.log("cityCheckSender" + cityCheckSender)

        if (cityCheck === false && cityCheckSender === false) {
          for (let i = 0; i <= this.bookingIndex; i++) {
            this.users.controls[i].markAllAsTouched();
            if (this.users.controls[i].valid) { usersTouchCheck++ }
          }
          console.log("3 : ")
          console.log("usersTouchCheck : " + usersTouchCheck)
          console.log("bookingIndex : " + this.bookingIndex)

          if (this.form1.valid && usersTouchCheck === this.bookingIndex) {
            this.apiService.bookingData = this.arrayBookingData;

            console.log("4 : ")
            console.log("FORM VALID")
            console.log(this.apiService.bookingData)

            setTimeout(() => {
              this.spinner.hide();
            }, 5000);

            setTimeout(() => {
              this.panel.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            });
            this.routes.navigate(["/user/booking-invoice"]);
          } else {

            if (this.form1.valid && this.form1.touched) {
              this.emptyFormChecker();
            }
            else if (usersTouchCheck === this.bookingIndex) {
              Swal.fire({
                title: "Gagal!",
                text: "Layanan sementara hanya di wilayah Jakarta. Ganti wilayah di kolom Kota/Kecamatan",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                icon: "warning",
              });
            }
          }
        } else {
          Swal.fire({
            title: "Gagal!",
            text: "Layanan sementara hanya di wilayah Jakarta. Ganti wilayah di kolom Kota/Kecamatan",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-success",
            },
            icon: "warning",
          });
        }
      } else {
        Swal.fire({
          title: "Gagal!",
          text: "Layanan sementara hanya di wilayah Jakarta. Ganti wilayah di kolom Kota/Kecamatan",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
          icon: "warning",
        });
      }
    } else {
      this.errorMessage = ""
      setTimeout(() => {
        this.errorMessage = "Pastikan data anda sudah benar sebelum pemesanan.";
      }, 100);
    }
  }

  // MEMBERSIHKAN DATA LALU KEMBALI KE HALAMAN CREATE BOOKING
  onReset() {
    this.panel.nativeElement.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
    location.reload()
  }
}

