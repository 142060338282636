<div class="card" #panel>
  <h3 class="contact-title">{{translateArr.bp1}}</h3>
  <!-- <h5 class="text-secondary fw-3 pb-4">{{translateArr.bp13}}</h5> -->
  <h5 class="text-secondary fw-3 pb-4">Pesanan kamu akan tampil disini</h5>

  <div style="overflow-x:auto;">
    <table mat-table [dataSource]=" dataSource" matSort matSortActive="createdAt" matSortDirection="asc"
      class="table wrap">

      <ng-container matColumnDef="bookingId">
        <mat-header-cell *matHeaderCellDef> {{translateArr.bp3}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.booking_number}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> {{translateArr.bp4}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef> {{translateArr.bp5}} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{formatDate(element.created_at)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>{{translateArr.bp6}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="d-flex flex-row wrap">
            <a href="/user/detail-booking/{{element.booking_number}}" type="submit" class="btn-job mr-2">
              <span class="material-icons">
                visibility
              </span>
            </a>
            <!-- <a (click)="cancel(element.booking_number)"
              [ngClass]="{'enable' : ['ON_PICKUP','WAITING_PICKUP'].includes(element.status)}" type="submit"
              class="btn-job">
              <span class="material-icons cancel">
                cancel
              </span>
            </a> -->
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <ng-container *ngIf="!isLoading">
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell p-4" style="color: gray;" colspan="9999">
            {{translateArr.bp8}}
          </td>
        </tr>
      </ng-container>

    </table>
    <div style="text-align: center;" *ngIf="isLoading">
      <br>
      <mat-spinner style="margin:0 auto;"></mat-spinner><br>
      <h4>{{translateArr.bp7}}</h4>
    </div>
  </div>

  <mat-paginator [pageSize]="size" [length]="totalBooking" (page)="onPaginateChange($event)"></mat-paginator>

</div>