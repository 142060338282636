import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit {
  isVisible = false;
  constructor() {}

  ngOnInit(): void {
    this.startLoop();
  }

  showVideoOverlay(): void {
    this.isVisible = true;
  }

  hideVideoOverlay(): void {
    this.isVisible = false;
  }

  startLoop(): void {
    this.showVideoOverlay();
    setTimeout(() => {
      this.hideVideoOverlay();
      setTimeout(() => this.startLoop(), 2000);
    }, 5000);
  }
}
