import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "src/app/api.service";
import dayjs from "dayjs";
import blackpink from "./myprofile-translate.json";

@Component({
  selector: "app-myprofile",
  templateUrl: "./myprofile.component.html",
  styleUrls: ["./myprofile.component.css"],
})
export class MyprofileComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  imageUrl: string =
    "https://usapi.onindonesia.id/get-profile-img/" +
    localStorage.getItem("username");
  profileData = new FormData();
  genders = ["Laki-Laki", "Perempuan"];
  basicProfileForm: FormGroup;

  submitted = false;
  userinfo: any = {
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    gender: null,
    phone_number: null,
  };

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    // console.log(this.imageUrl)
  }

  ngOnInit() {
    this.basicProfileForm = this.fb.group({
      profile_img: [null],
      date_of_birth: [null],
      phone_number: [null],
      gender: [null],
    });

    this.apiService.retrieveBasicProfile().subscribe((response) => {
      this.userinfo = response;
      for (const key in this.basicProfileForm.controls) {
        this.basicProfileForm.controls[key].setValue(this.userinfo[key]);
      }
    });

    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  handleFileInput(file: FileList) {
    // console.log("HR")
    // Set File to Upload
    this.profileData.set("profile_img", file.item(0));

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(file.item(0));
  }

  OnSubmit() {
    const basicProfileJSON = JSON.stringify({
      gender: this.basicProfileForm.get("gender").value,
      // date_of_birth: dateOfBirth.toDateString(),
      date_of_birth: dayjs(
        this.basicProfileForm.get("date_of_birth").value
      ).format("YYYY-MM-DD"),
      phone_number: this.basicProfileForm.get("phone_number").value,
    });

    this.profileData.set("basic_profile", basicProfileJSON);
    // console.log(basicProfileJSON)
    this.apiService
      .updateBasicProfile(this.profileData, localStorage.getItem("jwt"))
      .subscribe((res) => {
        this.dialog.open(DialogAlert, {
          width: "300px",
        });

        // console.log("SUCCESS")
      });
  }

  formatDate(date: any) {
    return dayjs(date).format("DD MMMM YYYY");
  }
  useDefaultProfileImg() {
    this.imageUrl =
      "https://storage.onindonesia.id/ondelivery.id/user-avatar.png";
  }
}

@Component({
  selector: "dialog-alert",
  templateUrl: "dialog-alert.html",
  styleUrls: ["./myprofile.component.css"],
})
export class DialogAlert {
  translateArr = blackpink[localStorage.getItem("translate")];
}
