<div class="inner inner-pages">
  <div class="main">
    <!-- <app-main-header></app-main-header> -->
    <section class="section welcome-area bg-overlay subscribe-area h-100vh ptb_100">
      <div class="container h-100">
        <div class="row align-items-center justify-content-center h-100">
          <div class="col-12 col-md-8 col-lg-5 mt-5">
            <!-- Contact Box -->
            <div class="subscribe-content bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
              <!-- Contact Form -->
              <form id="contact-form">
                <div class="contact-top">
                  <h3 class="contact-title">{{translateArr.bp1}}</h3>
                  <h5 class="text-secondary fw-3 py-3">{{translateArr.bp2}}</h5>
                </div>
                <form class="subscribe-form">
                  <div class="form-group">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="{{translateArr.bp8}}" [formControl]="form.controls['email']">
                    <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                      class="text-danger support-text">{{translateArr.bp3}}</small>
                    <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched"
                      class="text-danger support-text">{{translateArr.bp4}}</small>
                  </div>
                  <button (click)="resetpass()" type="submit" class="btn btn-lg btn-block"
                    [disabled]="!form.valid">{{translateArr.bp5}}</button>
                </form>
              </form>
              <p class="form-message p-3">{{translateArr.bp6}}</p>
              <a href="/signup" style="color: #13AAD4;">{{translateArr.bp7}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>