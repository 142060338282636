<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
  <div class="container new-max-width position-relative">
    <a class="navbar-brand" href="index.html">
      <img
        class="navbar-brand-regular"
        src="https://storage.onindonesia.id/ondelivery.id/Ondelivery Logo.png"
        alt="brand-logo"
        style="width: 180px"
      />
      <img
        class="navbar-brand-sticky"
        src="https://storage.onindonesia.id/ondelivery.id/Ondelivery Logo.png"
        alt="sticky brand-logo"
        style="width: 180px"
      />
    </a>

    <div class="navbar-inner">
      <!--  Mobile Menu Toggler -->
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="navbarToggler"
        aria-label="Toggle navigation"
        (click)="onClick2()"
      >
        <span
          class="navbar-toggler-icon"
          style="color: black !important"
        ></span>
      </button>

      <nav class="d-flex">
        <ul class="navbar-nav w-100" id="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:;"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ translateArr.bp1 }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/ondelivery-express">{{
                  translateArr.bp2
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" href="/ondelivery-trucking">{{
                  translateArr.bp3
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" href="/ondelivery-logistics">{{
                  translateArr.bp4
                }}</a>
              </li>
              <!-- <li>
                <a class="dropdown-item" href="/faq">{{ translateArr.bp5 }}</a>
              </li> -->
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:;"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Partner
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/vip-customer-info"
                  >VIP Customer</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/keagenan-info">Keagenan</a>
              </li>
              <!-- <li>
                <a class="dropdown-item" href="/b2b">{{ translateArr.bp4 }}</a>
              </li> -->
              <!-- <li>
                <a class="dropdown-item" href="/faq">{{ translateArr.bp5 }}</a>
              </li> -->
            </ul>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="/our-service">{{ translateArr.bp6 }}</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="/article/all">{{ translateArr.bp7 }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/tentang-kami">{{ translateArr.bp9 }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/pusat-bantuan">{{
              translateArr.bp21
            }}</a>
          </li>

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:;"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ translateArr.bp9 }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/about-us">{{
                  translateArr.bp16
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" href="/career">{{
                  translateArr.bp8
                }}</a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item" *ngIf="agent != null">
            <a class="nav-link" href="/training/all">Training</a>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:;"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Partner
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/business-inquiries">{{
                  translateArr.bp18
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" href="/agent-inquiries">{{
                  translateArr.bp17
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" href="/courier-partner">{{
                  translateArr.bp19
                }}</a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item" *ngIf="agent != null">
            <a class="nav-link" href="/contact">Kontak</a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              style="cursor: pointer"
              (click)="openDialog()"
              >{{ translateArr.bp20 }}</a
            >
          </li> -->

          <div
            class="button-container"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <!-- <span class="call-center">{{translateArr.bp11}}</span> -->
            <div class="example-button-row corner-btn">
              <div
                mat-raised-button
                (click)="openDialog()"
                class="btn"
                *ngIf="!loggedin"
              >
                <div class="material-icons pr-1" style="font-size: 16px">
                  person
                </div>
                <span style="font-size: 12px; font-weight: 500">{{
                  translateArr.bp12
                }}</span>
              </div>

              <div
                *ngIf="loggedin"
                (click)="(false)"
                mat-raised-button
                class="btn"
                [matMenuTriggerFor]="acc"
              >
                <div class="acc">
                  <span class="material-icons pr-1" style="font-size: 16px"
                    >person</span
                  >
                  <span style="font-size: 12px; font-weight: 500">{{
                    loggedinUser
                  }}</span>
                </div>
              </div>
              <mat-menu #acc="matMenu">
                <a mat-menu-item href="/user" style="color: black">
                  <span class="material-icons pr-1">contact_page</span>
                  {{ translateArr.bp14 }}
                </a>
                <button (click)="logout()" mat-menu-item>
                  <span class="material-icons pr-1">logout</span>
                  {{ translateArr.bp15 }}
                </button>
              </mat-menu>

              <div
                mat-button
                [matMenuTriggerFor]="menu"
                mat-raised-button
                class="mx-1 btn lang-button"
              >
                <span
                  class="pr-2"
                  style="
                    padding: 0px !important;
                    font-size: 12px;
                    font-weight: 500;
                  "
                >
                  <img
                    style="height: 10px !important; padding-bottom: 2px"
                    src="https://storage.onindonesia.id/ondelivery.id/{{
                      lang
                    }}.png"
                  />
                  {{ lang | uppercase }}
                </span>
              </div>
              <mat-menu #menu="matMenu">
                <button id="idn" (click)="onClick($event)" mat-menu-item>
                  <span class="pr-2">
                    <img
                      style="height: 13px !important"
                      src="https://storage.onindonesia.id/ondelivery.id/idn.png"
                    />
                  </span>
                  ID
                </button>
                <button id="eng" (click)="onClick($event)" mat-menu-item>
                  <span class="pr-2">
                    <img
                      style="height: 13px !important"
                      src="https://storage.onindonesia.id/ondelivery.id/eng.png"
                    />
                  </span>
                  EN
                </button>
                <button id="cn" (click)="onClick($event)" mat-menu-item>
                  <span class="pr-2">
                    <img
                      style="height: 13px !important"
                      src="https://storage.onindonesia.id/ondelivery.id/cn.png"
                    />
                  </span>
                  中文
                </button>
              </mat-menu>
            </div>
          </div>
        </ul>
      </nav>
    </div>

    <div
      class="order-container"
      style="
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 16px;
      "
    >
      <div style="padding-right: 20px">
        <button
          class="btn order-now"
          (click)="orderNow()"
          style="padding: 5px 15px; height: 45px"
        >
          <p
            style="
              font-size: 12px;
              margin: 0 !important;
              text-align: center;
              font-weight: 500;
            "
          >
            Kirim Sekarang
          </p>
        </button>
      </div>

      <button
        class="navbar-toggler d-lg-none"
        style="background-color: #1254a0 !important; border-radius: 8px"
        type="button"
        data-toggle="navbarToggler"
        aria-label="Toggle navigation"
        (click)="onClick2()"
      >
        <span
          class="navbar-toggler-icon"
          style="color: black !important"
        ></span>
      </button>
    </div>
  </div>
</header>
