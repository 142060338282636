<section class="section service-area overflow-hidden ptb_50">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-12 col-lg-6 order-2 order-lg-2">
        <!-- Service Text -->
        <div class="service-text pt-4 pt-lg-0">
          <h2>{{ translateArr.bp1 }}</h2>
          <p class="d-sm-block mt-4">{{ translateArr.bp2 }}</p>
          <p class="d-sm-block mt-4">{{ translateArr.bp3 }}</p>
          <p class="d-sm-block mt-4">{{ translateArr.bp4 }}</p>
          <!-- <div class="icon-box d-flex mt-5">
                        <div class="service-icon">
                            <span><mat-icon>local_shipping</mat-icon></span>
                        </div>
                        <div class="service-icon px-3">
                            <span><mat-icon>find_in_page</mat-icon></span>
                        </div>
                        <div class="service-icon">
                            <span><mat-icon>important_devices</mat-icon></span>
                        </div>
                    </div> -->
          <a href="/tentang-kami" class="btn mt-4" style="padding: 14px 18px"
            >{{ translateArr.bp5 }} <i class="fas fa-angle-double-right"></i
          ></a>
        </div>
      </div>
      <div class="col-12 col-lg-4 order-1 order-lg-1 d-none d-lg-block">
        <!-- Service Thumb -->
        <div class="service-thumb" style="width: 150%">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/onapps-opening-page.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</section>
