import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubmitDialogComponent } from './submit-dialog/submit-dialog.component';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import blackpink from './reset-translate.json'

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  form: FormGroup
  constructor(public dialog: MatDialog, private fb: FormBuilder, private api: ApiService, private routes: Router) {}

  ngOnInit():void {
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    });
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  resetpass() {
    this.api.requestResetPwd(this.form.value.email).subscribe()
    const dialogRef = this.dialog.open(SubmitDialogComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.routes.navigate(['/'])
    })
  }

}
