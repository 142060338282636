<section
  id="home"
  class="section welcome-area bg-overlay"
  style="margin-bottom: 280px"
>
  <div class="container">
    <div
      class="row align-items-center justify-content-center"
      style="position: relative"
    >
      <!-- Welcome Intro Start -->
      <div class="col-12 col-lg-7 d-none d-lg-block">
        <div class="welcome-intro">
          <h1 class="text-white">{{ translateArr.bp1 }}</h1>
          <p class="text-white my-4">{{ translateArr.bp2 }}</p>
          <div class="button-group store-buttons d-flex justify-content-left">
            <a
              href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
            >
              <img
                src="https://storage.onindonesia.id/ondelivery.id/google-play-black.png"
                alt=""
              />
            </a>
            <a
              href="https://apps.apple.com/id/app/onapps-indonesia/id1587666667"
            >
              <img
                src="https://storage.onindonesia.id/ondelivery.id/app-store-black.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-5 mt-5 padding-box">
        <!-- Contact Box -->
        <div
          class="bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg"
        >
          <!-- Contact Form -->
          <form id="contact-form">
            <div class="contact-top">
              <h3 class="contact-title">{{ translateArr.bp3 }}</h3>
              <h5 class="text-secondary fw-3 py-3">{{ translateArr.bp4 }}</h5>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-user-alt"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="{{ translateArr.bp21 }}"
                      required
                      [formControl]="form.controls['username']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['username'].hasError('required') &&
                        form.controls['username'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp5 }}</small
                    >
                    <small
                      *ngIf="errorRegister == 'USERNAME_EMAIL_TAKEN'"
                      class="text-danger support-text"
                      ><br />Username or email has been taken</small
                    >
                  </mat-hint>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-envelope-open"></i
                      ></span>
                    </div>
                    <input
                      type="email"
                      placeholder="{{ translateArr.bp22 }}"
                      class="form-control"
                      required
                      [formControl]="form.controls['email']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['email'].hasError('required') &&
                        form.controls['email'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp6 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['email'].invalid &&
                        form.controls['email'].touched &&
                        !form.controls['email'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp7 }}</small
                    >
                  </mat-hint>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-user-alt"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="{{ translateArr.bp23 }}"
                      required
                      [formControl]="form.controls['phone_number']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['phone_number'].hasError('required') &&
                        form.controls['phone_number'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp8 }}</small
                    >
                    <small
                      *ngIf="
                        form.controls['phone_number'].invalid &&
                        form.controls['phone_number'].touched &&
                        !form.controls['phone_number'].hasError('required')
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp9 }}</small
                    >
                  </mat-hint>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-user-alt"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="{{ translateArr.bp24 }}"
                      required
                      [formControl]="form.controls['firstname']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['firstname'].hasError('required') &&
                        form.controls['firstname'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp10 }}</small
                    >
                  </mat-hint>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-user-alt"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="{{ translateArr.bp25 }}"
                      required
                      [formControl]="form.controls['lastname']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['lastname'].hasError('required') &&
                        form.controls['lastname'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp11 }}</small
                    >
                  </mat-hint>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-unlock-alt"></i
                      ></span>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="{{ translateArr.bp26 }}"
                      type="password"
                      required
                      minlength="6"
                      matInput
                      [type]="hide ? 'password' : 'text'"
                      [formControl]="form.controls['password']"
                    />
                  </div>
                  <mat-hint>
                    <small
                      *ngIf="
                        form.controls['password'].hasError('required') &&
                        form.controls['password'].touched
                      "
                      class="text-danger support-text"
                      >{{ translateArr.bp12 }}</small
                    >
                  </mat-hint>
                </div>

                <span class="d-inline-block py-3" style="font-size: 12px"
                  >{{ translateArr.bp13 }}
                  <a href="/terms-and-conditions" style="color: #13aad4">{{
                    translateArr.bp14
                  }}</a>
                  &amp;
                  <a href="/privacy-policy" style="color: #13aad4">{{
                    translateArr.bp15
                  }}</a>
                  ONDELIVERY
                </span>
              </div>

              <div class="col-12">
                <button
                  color="primary"
                  class="login-button btn btn-lg mt-3 w-100"
                  type="submit"
                  [disabled]="!form.valid"
                  (click)="register()"
                >
                  {{ translateArr.bp16 }}
                </button>
              </div>
              <div class="col-12">
                <span class="d-block pt-2 mt-4 border-top"
                  >{{ translateArr.bp17 }}
                  <a href="/" style="color: #13aad4">{{
                    translateArr.bp18
                  }}</a></span
                >
              </div>
            </div>
          </form>
          <p class="form-message"></p>
        </div>
      </div>
    </div>
  </div>
  <!-- Shape Bottom -->
  <div class="shape-bottom">
    <svg
      viewBox="0 0 1920 310"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="svg replaced-svg"
    >
      <title>{{ translateArr.bp19 }}</title>
      <desc>{{ translateArr.bp20 }}</desc>
      <defs></defs>
      <g
        id="sApp-Landing-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="sApp-v1.0"
          transform="translate(0.000000, -554.000000)"
          fill="#FFFFFF"
        >
          <path
            d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
            id="sApp-v1.0"
          ></path>
        </g>
      </g>
    </svg>
  </div>
</section>
