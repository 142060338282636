import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import blackpink from './change-password-translate.json'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  //test commit

  hide = true;
  public form: FormGroup;

  constructor(public dialog: MatDialog, private apiService: ApiService, private routes: Router, private fc: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit() {
    this.form = this.fc.group({
      old_password: [null, Validators.compose([Validators.required])],
      new_password: [null, Validators.compose([Validators.required])],
      confirm_password: [null, Validators.compose([Validators.required])],
    }, {
      validator: this.checkIfMatchingPasswords('new_password', 'confirm_password')
    });
    // console.log(this.form.valid)

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  checkIfMatchingPasswords(new_password: string, confirm_password: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[new_password],
        passwordConfirmationInput = group.controls[confirm_password];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  resetPassword() {
    if (window.confirm("Do you really want to change your password?")) {
      this.apiService.ChangeUserPassword(this.form.value.old_password, this.form.value.new_password).subscribe(
        (res) => {
          this.dialog.open(DialogAlertPass, {
            width: '300px',
          });
          this.form.reset()
          this.routes.navigate(['/']);
        },
        (err) => {
          console.log(err);
          window.alert("System error. Please remain calm");
        }
      );
    }
  }
}

@Component({
  selector: 'dialog-alert',
  templateUrl: 'dialog-alert.html',
  styleUrls: ['./change-password.component.css'],
})
export class DialogAlertPass { }