<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="line-spin-clockwise-fade"
  [fullScreen]="true"
>
  <p style="color: white">loading...</p>
</ngx-spinner>

<div class="partner-page">
  <div class="main">
    <!-- <app-scrollup></app-scrollup> -->
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section
      style="background-color: #ffffff; display: flex; flex-direction: column"
      class="flex align-items-center justify-content-center"
    >
      <img
        loading="lazy"
        src="https://storage.onindonesia.id/ondelivery.id/bahan-Landing-Page-Join Us.png"
        alt="Banner of Agent Inquiry"
      />
    </section>

    <section
      #panel
      style="text-align: center; margin-top: 30px; margin-bottom: 10px"
    >
      <p
        class="blue-txt"
        style="
          color: #007bff;
          font-size: 30px;
          font-weight: 700;
          margin-top: 7px;
        "
      >
        {{ translateArr.bp1 }}
      </p>
      <p class="dark-grey-txt" style="font-size: 15px">
        {{ translateArr.bp2 }}
      </p>
    </section>

    <section class="partner-area bg-white" style="text-align: center">
      <div class="partner-row">
        <div class="first-col">
          <div class="first-fields">
            <label class="label">Nama<span style="color: red">*</span></label>
            <input
              #name
              type="text"
              class="form-control"
              aria-describedby="basic-addon2"
              maxlength="50"
              [formControl]="form.controls['name']"
            />
            <mat-hint
              *ngIf="
                form.controls['name'].hasError('required') &&
                form.controls['name'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ name.value.length }} / 50</mat-hint
            >
          </div>

          <div class="first-fields">
            <label class="label">Email<span style="color: red">*</span></label>
            <input
              #email
              type="email"
              class="form-control"
              aria-describedby="basic-addon2"
              maxlength="100"
              [formControl]="form.controls['email']"
            />
            <mat-hint
              *ngIf="
                form.controls['email'].hasError('required') &&
                form.controls['email'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ email.value.length }} / 100</mat-hint
            >
          </div>

          <div class="first-fields">
            <label class="label"
              >Tanggal Lahir<span style="color: red">*</span></label
            >
            <div style="display: flex; flex-direction: row">
              <div style="width: 50%">
                <input
                  #birth
                  matInput
                  [matDatepicker]="picker"
                  class="form-control"
                  [formControl]="form.controls['birth_date']"
                  placeholder="MM/DD/YYYY"
                />
              </div>
              <div
                style="
                  position: relative;
                  left: -45px;
                  bottom: -2px;
                  z-index: 10;
                "
              >
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
            <mat-hint
              *ngIf="
                form.controls['birth_date'].hasError('required') &&
                form.controls['birth_date'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end; width: 50%"
              class="hidden"
              >{{ birth.value.length }} / 10</mat-hint
            >
          </div>

          <div class="first-fields">
            <label class="label">No. HP<span style="color: red">*</span></label>
            <input
              #phone
              type="text"
              class="form-control"
              numbersOnly
              aria-describedby="basic-addon2"
              [formControl]="form.controls['phone']"
            />
            <mat-hint
              *ngIf="
                form.controls['phone'].hasError('required') &&
                form.controls['phone'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ phone.value.length }} / 15</mat-hint
            >
          </div>

          <div class="first-fields">
            <label class="label">Alamat<span style="color: red">*</span></label>
            <textarea
              #address
              type="text"
              class="form-control address-field"
              aria-describedby="basic-addon2"
              maxlength="255"
              [formControl]="form.controls['address']"
            >
            </textarea>
            <mat-hint
              *ngIf="
                form.controls['address'].hasError('required') &&
                form.controls['address'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ address.value.length }} / 255</mat-hint
            >
          </div>
        </div>

        <div class="second-col">
          <div class="second-fields">
            <label class="label"
              >Domisili<span style="color: red">*</span></label
            >
            <input
              #city
              type="text"
              class="form-control"
              aria-describedby="basic-addon2"
              maxlength="50"
              [matAutocomplete]="autoLoc"
              [formControl]="form.controls['operation_city']"
            />
            <mat-autocomplete
              style="font-size: 11px"
              #autoLoc="matAutocomplete"
              aria-describedby="basic-addon2"
              [displayWith]="displayFnLoc"
            >
              <mat-option
                class="complete-list"
                *ngFor="let locationOption of filteredData"
                [value]="locationOption"
              >
                {{ locationOption.location_name }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="!isCityValid" class="text-danger"
              >Kota tidak valid
            </mat-hint>
            <mat-hint
              *ngIf="
                form.controls['operation_city'].hasError('required') &&
                form.controls['operation_city'].touched &&
                isCityValid
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ city.value.length }} / 50</mat-hint
            >
          </div>

          <div class="second-fields">
            <label class="label"
              >No. KTP<span style="color: red">*</span></label
            >
            <input
              #ktp
              type="text"
              class="form-control"
              numbersOnly
              aria-describedby="basic-addon2"
              maxlength="20"
              [formControl]="form.controls['no_ktp']"
            />
            <mat-hint
              *ngIf="
                form.controls['no_ktp'].hasError('required') &&
                form.controls['no_ktp'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ ktp.value.length }} / 20</mat-hint
            >
          </div>

          <div class="second-fields">
            <label class="label"
              >No. SIM<span style="color: red">*</span></label
            >
            <input
              #sim
              type="text"
              class="form-control"
              numbersOnly
              aria-describedby="basic-addon2"
              maxlength="20"
              [formControl]="form.controls['no_sim']"
            />
            <mat-hint
              *ngIf="
                form.controls['no_sim'].hasError('required') &&
                form.controls['no_sim'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ sim.value.length }} / 20</mat-hint
            >
          </div>

          <div class="second-fields">
            <label class="label"
              >No. NPWP<span style="color: red">*</span></label
            >
            <input
              #npwp
              type="text"
              class="form-control"
              numbersOnly
              aria-describedby="basic-addon2"
              maxlength="20"
              [formControl]="form.controls['no_npwp']"
            />
            <mat-hint
              *ngIf="
                form.controls['no_npwp'].hasError('required') &&
                form.controls['no_npwp'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ npwp.value.length }} / 20</mat-hint
            >
          </div>

          <div class="second-fields">
            <label class="label"
              >No. STNK<span style="color: red">*</span></label
            >
            <input
              #stnk
              type="text"
              class="form-control"
              numbersOnly
              aria-describedby="basic-addon2"
              maxlength="20"
              [formControl]="form.controls['no_stnk']"
            />
            <mat-hint
              *ngIf="
                form.controls['no_stnk'].hasError('required') &&
                form.controls['no_stnk'].touched
              "
              class="text-danger"
              >Mohon di isi
            </mat-hint>
            <mat-hint
              style="display: flex; justify-content: flex-end"
              class="hidden"
              >{{ stnk.value.length }} / 20</mat-hint
            >
          </div>

          <div class="second-fields">
            <div class="button-row">
              <button class="btn button" type="submit" (click)="refresh()">
                Reset
              </button>
              <button
                class="btn button"
                type="submit"
                (click)="addRegisterData()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>
