import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoginComponent } from "../accounts/login/login.component";
import { ApiService } from "src/app/api.service";
import { Router } from "@angular/router";
import { clearLocalStorage } from "src/app/utility.service";
import blackpink from "./main-header-translate.json";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];
  constructor(
    public dialog: MatDialog,
    private routes: Router,
    public service: ApiService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  buttonSelected: any;
  image = "https://storage.onindonesia.id/ondelivery.id/ind.png";
  lang = localStorage.getItem("translate") || "idn";
  loggedin: boolean = false;
  loggedinUser: string;
  orderState: boolean;
  agent = localStorage.getItem("agent");
  navbarActive: boolean = false;

  ngOnInit(): void {
    localStorage.setItem("translate", this.lang);
    this.orderState = false;

    // this.burgerIos();
    //Test if user status still loggedin
    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.service.verify(localStorage.getItem("jwt")).subscribe(
        (r) => {
          this.loggedinUser = localStorage.getItem("username");
          this.loggedin = true;
        },
        (e) => {
          clearLocalStorage();
        }
      );
    } else {
      clearLocalStorage();
    }

    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  onClick(event) {
    // console.log(event.target.id)
    if (event.target.id === "idn") {
      this.image = "https://storage.onindonesia.id/ondelivery.id/idn.png";
      this.lang = "idn";
    }
    if (event.target.id === "eng") {
      this.image = "https://storage.onindonesia.id/ondelivery.id/eng.png";
      this.lang = "eng";
    }
    if (event.target.id === "cn") {
      this.image = "https://storage.onindonesia.id/ondelivery.id/cn.png";
      this.lang = "中文";
    }
    localStorage.setItem("translate", event.target.id);
    location.reload();
  }

  // burgerIos = () => {
  //   document.addEventListener("DOMContentLoaded", function () {
  //     document.querySelectorAll(".navbar-toggler").forEach(function (button) {
  //       button.addEventListener("click", function () {
  //         document.getElementById("navbar-nav").classList.toggle("show");
  //       });
  //     });
  //   });
  // };

  onClick2() {
    this.navbarActive = !this.navbarActive;
    const navbar = this.elementRef.nativeElement.querySelector(".navbar");
    const togglerIcon = this.elementRef.nativeElement.querySelector(
      ".navbar-toggler-icon"
    );

    if (navbar.classList.contains("active")) {
      this.renderer.removeClass(navbar, "active");
    } else {
      this.renderer.addClass(navbar, "active");
    }

    if (togglerIcon.classList.contains("active")) {
      this.renderer.removeClass(togglerIcon, "active");
    } else {
      this.renderer.addClass(togglerIcon, "active");
    }
    document.body.classList.toggle("canvas-open", this.navbarActive);
  }

  orderNow() {
    if (this.loggedin) {
      this.routes.navigate(["/user/create-book"]);
    } else {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogRef: MatDialogRef<LoginComponent> =
      this.dialog.open(LoginComponent);

    dialogRef.afterClosed().subscribe(() => {
      location.reload();
    });
  }

  async logout() {
    // console.log(localStorage.getItem('jwt'));
    await this.service.logOff(localStorage.getItem("jwt")).subscribe(
      (data) => {
        this.loggedin = false;
      },
      (e) => {
        console.log(e);
      }
    );
    clearLocalStorage();
    location.reload();
  }
}
