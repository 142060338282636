<div class="blog">
  <div class="main">
    <!-- <app-scrollup></app-scrollup> -->
    <!-- <app-main-header></app-main-header> -->
    <!-- <app-breadcrumb-track></app-breadcrumb-track> -->
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <section id="blog" class="section blog-area" style="padding-bottom: 90px; padding-top: 120px;">
      <div class="container h-100">
        <div class="subscribe-content text-center">
          <h3 style="margin-bottom: 60px">{{ translateArr.bp1 }}</h3>
          <!-- <p class="mb-4 text-center">{{ translateArr.bp2 }}</p> -->
          <!-- <p>{{credit |currency :'Rp. ':'symbol':'.0':'id'}}</p> -->
          <div style="position: relative;">
            <mat-tab-group mat-align-tabs="center">
              <mat-tab label="Express">
                <div class="card p-4 p-lg-5">
                  <app-express></app-express>
                </div>
              </mat-tab>
              <!-- <mat-tab label="Trucking">trucking</mat-tab> -->
              <mat-tab label="B2B Logistik">
                <div class="card p-4 p-lg-5">
                  <app-b2b-cost></app-b2b-cost>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </section>
    <app-navigation-list-bar></app-navigation-list-bar>
    <!-- <app-footer-one></app-footer-one> -->
  </div>
</div>