<div style="min-height: 900px;">
  <mat-tab-group style="background: linear-gradient(-47deg, #1254A0 0%, #13AAD4 100%);">
    <!-- Pick Up -->
    <mat-tab label="Pesanan">
      <ng-template mat-tab-label>
        <span style="color: white;">Pesanan</span>
      </ng-template>
      <ng-template matTabContent>
        <app-order></app-order>
      </ng-template>
    </mat-tab>
    <!-- Pick Up -->

    <!-- Receiving -->
    <mat-tab label="Riwayat">
      <ng-template mat-tab-label>
        <span style="color: white;">Riwayat</span>
      </ng-template>
      <ng-template matTabContent>
        <app-history></app-history>
      </ng-template>
    </mat-tab>
    <!-- Receiving -->
  </mat-tab-group>
</div>