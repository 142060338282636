import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-inquiries-info',
  templateUrl: './agent-inquiries-info.component.html',
  styleUrls: ['./agent-inquiries-info.component.scss']
})
export class AgentInquiriesInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
