import { Component, OnInit, SecurityContext, ViewEncapsulation, Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import dayjs from 'dayjs';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";
import {Title} from "@angular/platform-browser";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Url } from 'url';
@Directive({
  selector: '[appNoRightClick]'
})

export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-training-detail',
  templateUrl: './training-detail.component.html',
  styleUrls: ['./training-detail.component.scss']
})
export class TrainingDetailComponent implements OnInit {

  trainingDetails: any
  id: any
  params: ParamMap
  previewImage: any;
  content: string
  url: string
  durationInSeconds = 2;
  isLoading: boolean;
  token = localStorage.getItem('jwt')
  basicProfileForm: FormGroup;

  userinfo : any = {
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    gender: null,
    phone_number: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ApiService,
    private sanitized: DomSanitizer,
    private titleService:Title,
    private _snackBar: MatSnackBar,
    private location: Location,
    private fb: FormBuilder
  ) {
    this.route.params.subscribe(e => {
      var idUrl = e.type
      idUrl = idUrl.substring(0, idUrl.indexOf('-'));
      this.fetchDetails(idUrl, this.token)
    })
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  fetchDetails(id, token){
    this.isLoading = true
    this.service.trainingDetail(id, this.token).subscribe(data => {
      this.trainingDetails = data
    }, e => {
      // console.log(e)  
    })
  }

  ngOnInit(): void {
    this.loggingDetail()
  }

  formatDate(date: any) {
    return dayjs(date).format('DD MMMM YYYY')
  }

  loggingDetail() {
    this.basicProfileForm = this.fb.group({
      profile_img: [null],
      date_of_birth: [null],
      phone_number: [null],
      gender: [null]
    })
    this.service.retrieveBasicProfile().subscribe(
      response => {
        this.userinfo = response
        const url = window.location.href
        const splitUrl = url.split("/").pop()
        const IdUrl = splitUrl.charAt(0)
        const fullName = response.first_name + ' ' + response.last_name
        this.service.loggingTraining(this.token, this.trainingDetails.title, IdUrl, this.trainingDetails.target, fullName, this.userinfo.email).subscribe(
          response => {
            console.log('s')
          }, e => {
            console.log(e)
          }
        );
      }
    );
  }

  openSnackBar() {
    this._snackBar.open("Copied to clipboard", "OK", {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snack-bar']
    });
  }

}
