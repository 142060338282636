<div class="card">
    <!-- Contact Form -->
    <form [formGroup]="form">
        <div class="contact-top">
            <h3 class="contact-title m-0">{{translateArr.bp1}}</h3>
            <h5 class="text-secondary fw-3 py-3">{{translateArr.bp2}}</h5>
        </div>
        <div class="row">
            <div class="col-7">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><span class="material-icons">vpn_key</span></span>
                        </div>
                        <input type="password" class="form-control" placeholder="{{translateArr.bp7}}"
                            [formControl]="form.controls['old_password']">
                    </div>
                    <mat-hint
                        *ngIf="form.controls['old_password'].hasError('required') && form.controls['old_password'].touched"
                        class="text-danger font-14">{{translateArr.bp3}}</mat-hint>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><span class="material-icons">vpn_key</span></span>
                        </div>
                        <input type="password" class="form-control" placeholder="{{translateArr.bp8}}"
                             [formControl]="form.controls['new_password']">
                    </div>
                    <mat-hint
                        *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched"
                        class="text-danger font-14">{{translateArr.bp3}}</mat-hint>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><span class="material-icons">vpn_key</span></span>
                        </div>
                        <input type="password" placeholder="{{translateArr.bp9}}"
                            class="form-control"  minlength="6"
                            [formControl]="form.controls['confirm_password']">
                    </div>
                    <mat-hint
                        *ngIf="form.controls['confirm_password'].hasError('required') && form.controls['confirm_password'].touched"
                        class="text-danger font-14">{{translateArr.bp4}}</mat-hint>
                    <mat-hint *ngIf="form.controls['confirm_password'].hasError('notEquivalent')"
                        class="text-danger font-14">{{translateArr.bp5}}</mat-hint>
                </div>
                <div>
                    <button class="btn btn-lg mt-sm-3 w-500" type="submit" (click)="resetPassword()"
                    [disabled]="!form.valid">{{translateArr.bp6}}</button>
                </div>
            </div>
        </div>
    </form>
</div>