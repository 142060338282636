import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import blackpink from './upload-document-translate.json';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]

  selectedImage: string[] = [];
  fileUploaded: boolean[] = [];
  images: any[] = [];
  captured: any[] = [];
  status: string[] = [];

  fileName: string[] = [];
  registerData: any;
  isChecked: boolean = true;

  cameraOpened = false;
  photoCaptured = false;
  capturedPhoto: string | undefined;
  file0: File;
  file1: File;
  file2: File;
  file3: File;
  file4: File;

  constructor(
    private router: Router,
    private service: ApiService,
    private spinner: NgxSpinnerService,
  ) { }

  @ViewChild('panel') public panel: ElementRef;
  @ViewChild('ket1') ket1Ref!: ElementRef;
  @ViewChild('photo1') photo1Ref!: ElementRef;
  @ViewChild('ket2') ket2Ref!: ElementRef;
  @ViewChild('photo2') photo2Ref!: ElementRef;
  @ViewChild('ket3') ket3Ref!: ElementRef;
  @ViewChild('photo3') photo3Ref!: ElementRef;
  @ViewChild('ket4') ket4Ref!: ElementRef;
  @ViewChild('photo4') photo4Ref!: ElementRef;
  @ViewChild('ket5') ket5Ref!: ElementRef;
  @ViewChild('photo5') photo5Ref!: ElementRef;
  @ViewChild('fileInput1') fileInput1!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInput2') fileInput2!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInput3') fileInput3!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInput4') fileInput4!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInput5') fileInput5!: ElementRef<HTMLInputElement>;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;

  ngOnInit(): void {

    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    }, 300);


    function dataURLtoFile(dataURL: string) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], 'last_image.png', { type: mime });
    }

    // INITIATION
    const storedData = localStorage.getItem('registerData');
    this.registerData = storedData ? JSON.parse(storedData) : [];
    this.fileUploaded = [false, false, false, false, false];
    const base64ImagesJson = localStorage.getItem('images');

    if (base64ImagesJson) {
      // Parse the JSON string to convert it back to an array
      const base64Images = JSON.parse(base64ImagesJson);

      // Convert each Base64 string back to a File object
      let previousFiles = [];
      for (const base64String of base64Images) {
        const file = dataURLtoFile(base64String);
        previousFiles.push(file);
      }

      for (let [index, file] of previousFiles.entries()) {
        const reader = new FileReader();
        console.log(file);
        console.log(index);
        switch (index) {
          case 0: this.file0 = file; break;
          case 1: this.file1 = file; break;
          case 2: this.file2 = file; break;
          case 3: this.file3 = file; break;
          case 4: this.file4 = file; break;
        }
        reader.readAsDataURL(file)
        this.fileName[index] = "..." + file.name.slice(-20);
        reader.onload = () => {
          this.selectedImage[index] = reader.result as string;
          this.fileUploaded[index] = true;
          this.updateNotif(index)
        }
      }

      //Memasukan data image sebelumnya ke images variabel
      this.images = previousFiles;
      previousFiles = [];
      // console.log(this.images)
    }
  }

  async confirmData() {
    const formData = new FormData();
    // console.log(this.images)

    if (this.images.length >= 5) {
      if (this.isChecked) {
        for (let i = 0; i < this.images.length; i++) {
          formData.append('images', this.images[i]);
        }
        // console.log(formData)

        const payload = {
          name: this.registerData.name,
          id_card_number: this.registerData.no_ktp,
          birth_date: this.registerData.birth_date,
          phone_number: this.registerData.phone,
          address: this.registerData.address,
          operation_city: {
            city_name: this.registerData.operation_city.location_name,
            agent_id: this.registerData.operation_city.location_id
          },
          driver_licence_number: this.registerData.no_sim,
          vehicle_id_number: this.registerData.no_stnk,
          tax_id_number: this.registerData.no_npwp,
          upload_selfie: this.fileName[0],
          upload_vehicle_id: this.fileName[1],
          upload_vehicle_photo: this.fileName[2],
          upload_id_card: this.fileName[3],
          upload_driver_licence: this.fileName[4],
          email: this.registerData.email
        }

        const jsonDataString = JSON.stringify(payload);
        formData.append('data', jsonDataString);


        // Assuming you have an array of File objects called 'images'
        const imageFiles = [...this.images];

        // Convert each File object to Base64 strings
        const base64Images = [];
        for (const file of imageFiles) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const base64String = event.target.result;
            base64Images.push(base64String);
            if (base64Images.length === imageFiles.length) {
              // All images are converted to Base64 strings
              // Save the array in local storage
              localStorage.removeItem('images');
              localStorage.setItem('images', JSON.stringify(base64Images));
            }
          };
          reader.readAsDataURL(file);
        }
        // console.log("SIMPAN BERHASIL!")
        // console.log(params)
        // console.log(JSON.stringify(params))
        await this.service.courierPartnerSignUp(formData).subscribe(response => {
          this.spinner.show();

          setTimeout(() => {
            this.spinner.hide();
            Swal.fire({
              icon: 'success',
              title: 'Pendaftaran Anda telah berhasil!',
              text: `Selanjutnya, kami akan menghubungi Anda untuk informasi tahap selanjutnya.\nRegistration ID : ${response.registration_id}`,
            }).then(() => {
              localStorage.removeItem('registerData');
              this.router.navigate(["/"])
            });
          }, 1500);
        },
          error => {
            console.log(error)
            Swal.fire({
              icon: 'error',
              title: 'Gagal',
              text: `${error.error.message}!`,
            })
          }
        )
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: 'Pastikan baca syarat dan ketentuan lalu ceklist untuk menyelesainkan pendaftaran!',
        });

      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Gagal',
        text: 'Pastikan semua data sudah lengkap!',
      });
    }
  }

  refresh() {
    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    });
    location.reload()
  }

  deleteFile(i: number) {
    this.fileUploaded[i] = false;
    this.selectedImage[i] = ""
    this.images.splice(i, 1);
    this.fileName[i] = "";
    this.status[i] = "";
    // console.log(this.fileUploaded[i])
  }

  previewFile(i: number) {
    if (this.fileUploaded[i]) {
      const win = window.open();
      win.document.write(`<img src="${this.selectedImage[i]}" alt="Preview" />`);
      win.document.close();
    }
  }


  onFileSelected(event: any, i: number, camera: boolean) {
    console.log(event)
    const reader = new FileReader();
    let entryImg: any;

    if (camera) {
      entryImg = event
    } else {
      entryImg = event.target.files[0]
    }

    switch (i) {
      case 0: this.file0 = entryImg; reader.readAsDataURL(this.file0); this.fileName[0] = "..." + this.file0.name.slice(-20); break;
      case 1: this.file1 = entryImg; reader.readAsDataURL(this.file1); this.fileName[1] = "..." + this.file1.name.slice(-20); break;
      case 2: this.file2 = entryImg; reader.readAsDataURL(this.file2); this.fileName[2] = "..." + this.file2.name.slice(-20); break;
      case 3: this.file3 = entryImg; reader.readAsDataURL(this.file3); this.fileName[3] = "..." + this.file3.name.slice(-20); break;
      case 4: this.file4 = entryImg; reader.readAsDataURL(this.file4); this.fileName[4] = "..." + this.file4.name.slice(-20); break;
    }

    reader.onload = () => {
      this.selectedImage[i] = reader.result as string;
      this.updateNotif(i)
    }
  }

  updateNotif(i: number) {
    const maxFileSize = 10 * 1024 * 1024;
    const Ket1 = this.ket1Ref.nativeElement;
    const Photo1 = this.photo1Ref.nativeElement;
    const Ket2 = this.ket2Ref.nativeElement;
    const Photo2 = this.photo2Ref.nativeElement;
    const Ket3 = this.ket3Ref.nativeElement;
    const Photo3 = this.photo3Ref.nativeElement;
    const Ket4 = this.ket4Ref.nativeElement;
    const Photo4 = this.photo4Ref.nativeElement;
    const Ket5 = this.ket5Ref.nativeElement;
    const Photo5 = this.photo5Ref.nativeElement;

    if (i == 0) {
      if (this.file0.size <= maxFileSize) {
        if (this.file0.type === 'image/png' || this.file0.type === 'image/jpg' || this.file0.type === 'image/jpeg') {
          this.fileUploaded[0] = true;
          this.status[0] = "Berhasil!"
          this.images[i] = this.file0
          Ket1.style.border = '2px solid green'; Photo1.style.border = '2px solid green';
        } else {
          this.status[0] = "File not compatible!"
          this.selectedImage[0] = ""
          Ket1.style.border = '2px solid red'; Photo1.style.border = '2px solid red'
        }
      } else {
        this.status[0] = "File too large!"
        this.selectedImage[0] = ""
        Ket1.style.border = '2px solid red'; Photo1.style.border = '2px solid red'
      }
    }
    if (i == 1) {
      if (this.file1.size <= maxFileSize) {
        if (this.file1.type === 'image/png' || this.file1.type === 'image/jpg' || this.file1.type === 'image/jpeg') {
          this.fileUploaded[1] = true;
          this.status[1] = "Berhasil!"
          this.images[i] = this.file1
          Ket2.style.border = '2px solid green'; Photo2.style.border = '2px solid green';
        } else {
          this.status[1] = "File not compatible!"
          this.selectedImage[1] = ""
          Ket2.style.border = '2px solid red'; Photo2.style.border = '2px solid red'
        }
      } else {
        this.status[1] = "File too large!"
        this.selectedImage[1] = ""
        Ket2.style.border = '2px solid red'; Photo2.style.border = '2px solid red'
      }
    }
    if (i == 2) {
      if (this.file2.size <= maxFileSize) {
        if (this.file2.type === 'image/png' || this.file2.type === 'image/jpg' || this.file2.type === 'image/jpeg') {
          this.fileUploaded[2] = true;
          this.status[2] = "Berhasil!"
          this.images[i] = this.file2
          Ket3.style.border = '2px solid green'; Photo3.style.border = '2px solid green';
        } else {
          this.status[2] = "File not compatible!"
          this.selectedImage[2] = ""
          Ket3.style.border = '2px solid red'; Photo3.style.border = '2px solid red'
        }
      } else {
        this.status[2] = "File too large!"
        this.selectedImage[2] = ""
        Ket3.style.border = '2px solid red'; Photo3.style.border = '2px solid red'
      }
    }
    if (i == 3) {
      if (this.file3.size <= maxFileSize) {
        if (this.file3.type === 'image/png' || this.file3.type === 'image/jpg' || this.file3.type === 'image/jpeg') {
          this.fileUploaded[3] = true;
          this.status[3] = "Berhasil!"
          this.images[i] = (this.file3)
          Ket4.style.border = '2px solid green'; Photo4.style.border = '2px solid green';
        } else {
          this.status[3] = "File not compatible!"
          this.selectedImage[3] = ""
          Ket4.style.border = '2px solid red'; Photo4.style.border = '2px solid red'
        }
      } else {
        this.status[3] = "File too large!"
        this.selectedImage[3] = ""
        Ket4.style.border = '2px solid red'; Photo4.style.border = '2px solid red'
      }
    }
    if (i == 4) {
      if (this.file4.size <= maxFileSize) {
        if (this.file4.type === 'image/png' || this.file4.type === 'image/jpg' || this.file4.type === 'image/jpeg') {
          this.fileUploaded[4] = true;
          this.status[4] = "Berhasil!"
          this.images[i] = this.file4
          Ket5.style.border = '2px solid green'; Photo5.style.border = '2px solid green';
        } else {
          this.status[4] = "File not compatible!"
          this.selectedImage[4] = ""
          Ket5.style.border = '2px solid red'; Photo5.style.border = '2px solid red'
        }
      } else {
        this.status[4] = "File too large!"
        this.selectedImage[4] = ""
        Ket5.style.border = '2px solid red'; Photo5.style.border = '2px solid red'
      }
    }
  }

  openDialog(i: number) {
    Swal.fire({
      title: 'Pilihan',
      showDenyButton: true,
      confirmButtonText: 'File Foto',
      confirmButtonColor: '#007bff',
      denyButtonText: 'Kamera',
      denyButtonColor: '#fb3b1e',
      customClass: {
        confirmButton: 'custom-confirm-button',
        denyButton: 'custom-deny-button'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.openFileInput(i);
      } else if (result.isDenied) {
        this.openCamera(i);
      }
    });
  }

  openFileInput(i: number) {
    switch (i) {
      case 0: this.fileInput1.nativeElement.click(); break;
      case 1: this.fileInput2.nativeElement.click(); break;
      case 2: this.fileInput3.nativeElement.click(); break;
      case 3: this.fileInput4.nativeElement.click(); break;
      case 4: this.fileInput5.nativeElement.click(); break;
    }
  }

  openCamera(i: number) {
    const video = document.createElement('video');
    let stream: MediaStream;

    Swal.fire({
      html: `
    <div style="width: 100%; height: 100%;">
      <video #videoElement style="width: 100%; height: 100%;">
      <canvas #canvasElement style="width: 100%; height: 100%;" width="1280" height="720"></canvas>
      </video> 
    </div>
    `,
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#fb3b1e',
      confirmButtonText: 'Capture',
      confirmButtonColor: '#007bff',
      showCancelButton: true,
      allowOutsideClick: false,
      didOpen: () => {
        const video = Swal.getPopup().querySelector('video');
        // Access the camera stream and display it in the video element
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((cameraStream) => {
            stream = cameraStream;
            video.srcObject = stream;
            video.play();
          })
          .catch((error) => {
            // console.log('Failed to access camera:', error);
            Swal.close();
          });
      },
      willClose: () => {
        // Stop the camera stream when Swal is closed or dismissed
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      },
      preConfirm: () => {
        const that = this; // Store 'this' reference

        return new Promise<void>(function (resolve) {
          const video = Swal.getPopup().querySelector('video');
          const canvas = Swal.getPopup().querySelector('canvas');
          const context = canvas.getContext('2d');

          // Draw the current video frame onto the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas content to a Blob object
          canvas.toBlob(function (blob) {
            // Create a file name for the captured image
            const fileName = `captured_image_${Date.now()}.png`;

            // Create a new File object from the Blob
            const file = new File([blob], fileName, { type: 'image/png' });

            // Use the file as needed (e.g., send to server, save locally)
            that.captured[i] = file;

            // Optionally, you can display a preview of the captured image
            that.capturedPhoto = URL.createObjectURL(file);
            that.photoCaptured = true;

            // Clean up the video source and resolve the promise
            video.srcObject = null;
            resolve();
          }, 'image/png');
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(this.cameraOpened);
        this.onFileSelected(this.captured[i], i, true);
      } else if (result.isDismissed) {
        // Handle the cancellation or closing of the Swal dialog
        // You can add your code here to handle the close event
      }
    });

    // Add an event listener to the cancel button
    const cancelButton = Swal.getPopup().querySelector('.swal-button--cancel');
    cancelButton.addEventListener('click', () => {
      Swal.close(); // Close the Swal dialog
    });
  }
}