import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-navigation-list-bar",
  templateUrl: "./navigation-list-bar.component.html",
  styleUrls: ["./navigation-list-bar.component.scss"],
})
export class NavigationListBarComponent implements OnInit {
  currentRoute: string;
  constructor(private router: Router) {
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
      });
  }
  isActive(route: string): boolean {
    return this.currentRoute === route;
  }
}
