import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/api.service';
import { FormControl, Validators } from '@angular/forms';
import jwt_decode from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit, OnDestroy {
  form: FormControl;
  otp: string = '';
  private countdownTimer: ReturnType<typeof setTimeout>;
  public counter: number = 600;
  registerData: any;
  tokenData: any;

  constructor(
    // public dialogRef: MatDialogRef<VerifyPhoneComponent>,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private service: ApiService,
    private spinner: NgxSpinnerService,
  ) {
    this.form = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6)
    ]);
  }

  @ViewChild('panel') public panel: ElementRef;

  ngOnInit(): void {

    // INITIATION
    const storedData2 = localStorage.getItem('token');
    this.tokenData = storedData2 ? JSON.parse(storedData2) : [];


    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      })
    }, 300);

    this.startCountdown();
  }

  ngOnDestroy() {
    this.clearCountdown();
  }


  resendOtp() {
    // Add logic here to resend OTP
    console.log('resend OTP');
    const token = this.tokenData.token

    this.service.resendOTP(token).subscribe(response => {
      // console.log(response) 
      this.tokenData.token = response.token
      this.counter = 600;
    },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: `${error.error.message}!`,
        })
      }
    )

  }

  submitOtp() {
    // data parameter untuk submit OTP
    const token = this.tokenData.token
    const params = {
      otp: this.form.value,
      options: {
        native: true
      }
    }

    this.service.verifyOTP(JSON.stringify(params), token).subscribe(response => {
      // console.log(response)
      const decodedToken: any = jwt_decode(response.token)

      localStorage.setItem('username', decodedToken.user)
      localStorage.setItem('jwt', response.token)
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Verifikasi berhasil',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();

          setTimeout(() => {
            this.spinner.hide();
            this.router.navigate(['upload-document']);
          }, 1500);

        }
      });
    },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: `${error.error.message}!`,
        })
      }
    )
  }

  startCountdown() {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const expTimestamp = this.tokenData.exp;
    const remainingTime = expTimestamp - currentTimestamp;
    this.counter = remainingTime
    // console.log(remainingTime)

    this.countdownTimer = setTimeout(() => {
      const minutes = Math.floor(this.counter / 60 / 6);
      const seconds = this.counter % 60;
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      this.counter--;
      if (this.counter === 0) {
        this.clearCountdown();
        document.getElementById('resendButton').removeAttribute('disabled');
        document.getElementById('counter').style.display = 'none';
      } else {
        document.getElementById('counter').textContent = `Kirim ulang OTP dalam ${formattedTime}`;
        this.startCountdown();
      }
    }, 1000);
  }

  clearCountdown() {
    clearTimeout(this.countdownTimer);
  }

  closeOtpContainer(): void {
    // Add logic here to close the OTP container
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
      this.router.navigate(["courier-partner"])
    }, 1500);
  }

  limitInputLength(event: any) {
    const input = event.target;
    const maxLength = input.getAttribute('maxlength');
    const inputValue = input.value;

    if (inputValue.length >= maxLength) {
      input.value = inputValue.slice(0, maxLength);
    }
  }
}
