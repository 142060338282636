import { Component, OnInit } from '@angular/core';
// import blackpink from './contact-translate.json'

@Component({
  selector: 'app-scrollup',
  templateUrl: './scrollup.component.html',
  styleUrls: ['./scrollup.component.css']
})
export class ScrollupComponent implements OnInit {
  // translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    // this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
