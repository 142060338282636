import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import blackpink from './verify-email-translate.json'

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  token:string;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private routes: Router) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('key')
    // console.log("this is the token: " + this.token)
    this.api.verifySignUp(this.token).subscribe((r)=> {
      // console.log(r)
    }, (e) => {
      console.log(e.error.message)
    })

    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  verifyEmail(){

  }

  
  OK(): void {
    this.routes.navigate([''])
  }
  

}
