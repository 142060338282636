import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ApiService } from '../../../../api.service'
import dayjs from 'dayjs';
import blackpink from './history-translate.json'
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BookingTable } from 'src/app/driver.model';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]

  totalData: number = 0
  dataSource = new MatTableDataSource();
  isLoading: boolean = false;

  displayedColumnsHistory: string[] = ['waybill_number', 'invoice', 'booking_number', 'date', 'action']

  @ViewChild(MatPaginator) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild(MatSort) sort: MatSort = Object.create(null);

  constructor(
    private serviceApi: ApiService,
    private routes: Router,
    private _formBuilder: FormBuilder,
    private router: Router) {
    this.fetchBooking()
  }

  bookingTable: BookingTable = {
    page_size: 10,
    page_index: 1,
  }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
    this.fetchBooking()
  }

  formatDate(date: any) {
    return dayjs(date).format('DD MMM YYYY, hh:mm WIB')
  }

  traceWaybill(waybill: string) {
    this.routes.navigate(['/track'], { state: { waybill: waybill } })
  }

  fetchBooking() {
    // this.isLoading = true

    this.serviceApi.bookingHistory(this.bookingTable).subscribe(data => {
      this.dataSource.data = data.data
      this.totalData = data.total
      // console.log(data)
      this.isLoading = false
    }, e => {
      // console.log(e)
    })
  }

  onPaginateChange(event: any) {
    this.dataSource.data = []
    // console.log(event)
    if (event.pageSize != this.bookingTable.page_size) {
      this.bookingTable.page_index = 1
      this.bookingTable.page_size = event.pageSize
    } else {
      this.bookingTable.page_index = event.pageIndex + 1
    }
    this.fetchBooking()
  }
}
