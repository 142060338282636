import { Component, OnInit } from '@angular/core';
import blackpink from './breadcrumb-profile-translate.json'

@Component({
  selector: 'app-breadcrumb-profile',
  templateUrl: './breadcrumb-profile.component.html',
  styleUrls: ['./breadcrumb-profile.component.css']
})
export class BreadcrumbProfileComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  constructor() { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

}
