import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { QueryTable } from 'src/app/driver.model';
import { MatSort } from '@angular/material/sort';
import { clearLocalStorage } from 'src/app/utility.service'
import blackpink from './training-translate.json'


export interface PeriodicElement {
  category: string;
  createdBy: string;
  dept: string;
  description: string;
  id: number;
  image: string;
  key: string;
  title: string;
  updatedAt: string;
  url: string;
  
}

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  translateArr = blackpink[localStorage.getItem("translate")]
  news: any

  @ViewChild(MatPaginator) paginator: MatPaginator = Object.create(null); 
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild(MatSort, ) sort: MatSort = Object.create(null);

  searchText: any;
  displayedColumns: string[] = ['recipient'];

  // dataSource = new MatTableDataSource();
  dataSource = new MatTableDataSource<PeriodicElement>();

  title = 'angular-material-tab-router';
  navLinks: any[];
  activeLinkIndex = -1;
  type: string = ""
  isLoading: boolean = true
  // params: ParamMap
  totalData: number = 0
  form: FormGroup
  filename : string
  token = localStorage.getItem('jwt')
  youtubeCode: string

  constructor(private router: Router,
    private route: ActivatedRoute, private apiService: ApiService,) {
    this.navLinks = this.navLinkGen
    this.route.params.subscribe(e => {
      this.type = e.type
      if (!(this.type === 'all')) {
        if (this.queryTable.filters.findIndex(e => e.field === 'target') === -1) {
          this.queryTable.filters.push({
            field: 'target',
            keyword: this.type.toUpperCase()
          })
        } else {
          this.queryTable.filters[this.queryTable.filters.findIndex(e => e.field === 'target')] = {
            field: 'target',
            keyword: this.type.toUpperCase()
          }
        }
      } else {
        if (this.queryTable.filters.findIndex(e => e.field === 'target') !== -1) {
          this.queryTable.filters.splice(this.queryTable.filters.findIndex(e => e.field === 'target'), 1)
        }
      }
      this.fetchBlogs()
    })
  }
  get navLinkGen(){
    if ((localStorage.getItem("translate")) === 'idn' && localStorage.getItem("agent") === "ALL") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, {
          label: 'OMA',
          link: ['/training', 'oma'],
          index: 1
        }, {
          label: 'OPA',
          link: ['/training', 'opa'],
          index: 2
        }, {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        },
        {
          label: 'HO',
          link: ['/training', 'HO'],
          index: 4
        },
      ];
    }
    if ((localStorage.getItem("translate")) === 'eng' && localStorage.getItem("agent") === "ALL") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, {
          label: 'OMA',
          link: ['/training', 'oma'],
          index: 1
        }, {
          label: 'OPA',
          link: ['/training', 'opa'],
          index: 2
        }, {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        },
        {
          label: 'HO',
          link: ['/training', 'HO'],
          index: 4
        },
      ];
    }
    if ((localStorage.getItem("translate")) === 'cn' && localStorage.getItem("agent") === "ALL") {
      return  [
        {
          label: '所有文章',
          link: ['/training', 'all'],
          index: 0
        }, {
          label: '促销',
          link: ['/training', 'oma'],
          index: 1
        }, {
          label: '新闻',
          link: ['/training', 'opa'],
          index: 2
        }, {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        },
        {
          label: 'HO',
          link: ['/training', 'HO'],
          index: 4
        },
      ];
    }
    if ((localStorage.getItem("translate")) === 'cn' && localStorage.getItem("agent") === "OMA") {
      return  [
        {
          label: '所有文章',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: '促销',
          link: ['/training', 'oma'],
          index: 1
        }
      ];
    }
    if ((localStorage.getItem("translate")) === 'eng' && localStorage.getItem("agent") === "OMA") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'OMA',
          link: ['/training', 'oma'],
          index: 1
        }
      ];
    }
    if ((localStorage.getItem("translate")) === 'idn' && localStorage.getItem("agent") === "OMA") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'OMA',
          link: ['/training', 'oma'],
          index: 1
        }
      ];
    }
    if ((localStorage.getItem("translate")) === 'idn' && localStorage.getItem("agent") === "OPA") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'OPA',
          link: ['/training', 'opa'],
          index: 2
        }, 
      ];
    }
    if ((localStorage.getItem("translate")) === 'eng' && localStorage.getItem("agent") === "OPA") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'OPA',
          link: ['/training', 'opa'],
          index: 2
        }, 
      ];
    }
    if ((localStorage.getItem("translate")) === 'cn' && localStorage.getItem("agent") === "OPA") {
      return  [
        {
          label: '所有文章',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: '新闻',
          link: ['/training', 'opa'],
          index: 2
        }, 
      ];
    }
    if ((localStorage.getItem("translate")) === 'idn' && localStorage.getItem("agent") === "DC") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        },
      ];
    }
    if ((localStorage.getItem("translate")) === 'eng' && localStorage.getItem("agent") === "DC") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        },  
        {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        },
      ];
    }
    if ((localStorage.getItem("translate")) === 'cn' && localStorage.getItem("agent") === "DC") {
      return  [
        {
          label: '所有文章',
          link: ['/training', 'all'],
          index: 0
        },  
        {
          label: 'DC',
          link: ['/training', 'dc'],
          index: 3
        }
      ];
    }
    if ((localStorage.getItem("translate")) === 'idn' && localStorage.getItem("agent") === "HO") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'HO',
          link: ['/training', 'ho'],
          index: 4
        }, 
      ];
    }
    if ((localStorage.getItem("translate")) === 'eng' && localStorage.getItem("agent") === "HO") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'HO',
          link: ['/training', 'ho'],
          index: 4
        }, 
      ];
    }
    if ((localStorage.getItem("translate")) === 'cn' && localStorage.getItem("agent") === "HO") {
      return  [
        {
          label: 'ALL LEARNING CENTER',
          link: ['/training', 'all'],
          index: 0
        }, 
        {
          label: 'HO',
          link: ['/training', 'ho'],
          index: 4
        }, 
      ];
    }
  }
  queryTable: QueryTable = {
    sort: {
      field : 'publishedAt',
      order : 'descend'
    },
    filters: [
      {
        field : 'dept',
        keyword : 'ONDELIVERY'
      }
    ],
    pageSize: 5,
    pageIndex: 0,
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
    
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  resetPageIndex(){
    this.queryTable.pageIndex = 0
  }

  fetchBlogs() {
    this.isLoading = true
    // console.log(this.queryTable)
    this.apiService.listAllTraining(this.queryTable, this.token).subscribe(data => {
      this.dataSource.data = data.rows
      this.dataSource.sort = this.sort
      this.totalData = data.count
      this.isLoading = false
    }, e => {
      console.log(e)
    })
  }

  ngAfterViewInit() {
    
  }

  applyFilter() {
    if (this.queryTable.filters.findIndex(e => e.field === 'title') === -1) {
      this.queryTable.filters.push({
        field: 'title',
        keyword: this.searchText
      })
    } else {
      this.queryTable.filters[this.queryTable.filters.findIndex(e => e.field === 'title')] = {
        field: 'title',
        keyword: this.searchText
      }
    }

    this.fetchBlogs()
  }

  changeUrl(url) {
    const changeUrl = url.split("/").pop()
    return changeUrl
  }

  arrayToString(array) {
    const stringTarget =  array.join(', ');
    return stringTarget
  }

  onPaginateChange(event: any) {
    // console.log(event)
    if (event.pageSize != this.queryTable.pageSize) {
      this.queryTable.pageIndex = 0
      this.queryTable.pageSize = event.pageSize
    } else {
      this.queryTable.pageIndex = event.pageIndex
    }
    // this.dataSource.data = []
    this.fetchBlogs()
  }

}
