import { Component, OnInit, Renderer2 } from "@angular/core";
import blackpink from "./onservice-translate.json";
// import { $ } from "protractor";

@Component({
  selector: "app-onservice",
  templateUrl: "./onservice.component.html",
  styleUrls: ["./onservice.component.scss"],
})
export class OnserviceComponent implements OnInit {
  translateArr = blackpink[localStorage.getItem("translate")];

  constructor() {}

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")];
  }

  // outButton() {
  //   this.benefitContent = null;
  // }
}
