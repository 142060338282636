import { Component, AfterViewInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { retry } from 'rxjs/operators'

import { ApiService } from '../../../../api.service'
import blackpink from './cost-result-dialog-translate.json'

@Component({
  selector: 'app-cost-result-dialog',
  templateUrl: './cost-result-dialog.component.html',
  styleUrls: ['./cost-result-dialog.component.css']
})
export class CostResultDialogComponent implements AfterViewInit {
  translateArr = blackpink[localStorage.getItem("translate")]
  dataSourcePricing: any = []
  displayedColumnsPricing: string[] = ['service', 'price', 'eta', 'minKg', 'weightCalculated', 'totalKg']
  loadingMode: boolean = true
  pcpPriceValue: any = [];
  posPriceValue: any = [];
  listData: any = [];
  weightCount: any;

  constructor(private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CostResultDialogComponent>) { }

  ngOnInit(): void {
    this.translateArr = blackpink[localStorage.getItem("translate")]
  }

  ngAfterViewInit() {
    //Refine Parameter
    //TODO: remove in the future
    var payload: any = {}
    // payload.origin = this.data.origin
    payload.origin = this.data.origin
    payload.destination = this.data.dest
    payload.weight = this.data.weight
    if ((this.data.long != null) && (this.data.width != null) && (this.data.height != null)) {
      payload.dimension = { long: this.data.long, width: this.data.width, height: this.data.height }
    }

    console.log(payload)

    this.apiService.checkFeeNewNew(payload).subscribe(
      (res) => {
        // var test = []
        console.log(res)
        this.weightCount = res.weight
        let newFilter = res.services.filter(function (el) {
          return el.total != 0;
        });
        console.log(newFilter, 'newfilter')
        // this.dataSourcePricing = res.pcpValue
        // console.log(this.dataSourcePricing)
        for (var i of newFilter) {
          //console.log(i)
          if (i.name == "SAME DAY") {
            //test.push(i)
            i.price = 20000
            //

          }
          if (i.minKg == null) {
            //test.push(i)
            i.minKg = 0
          }
          this.dataSourcePricing.push(i)
        }
        console.log(this.dataSourcePricing)
        this.loadingMode = false
      },
      (err) => {
        console.log(err.error)
        this.dialogRef.close()
        // window.alert("Mohon lengkapi input nya");
      });


  }

  formatPCPRateIntoOndel(pcpRate: number, ondelFee: number) {
    //Format Price String
    const formattedPrice = new Intl.NumberFormat('id', { style: 'currency', currency: 'Rp. ' }).format(pcpRate + ondelFee)
    return formattedPrice;
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
