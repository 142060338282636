<div class="inner inner-pages">
  <div class="main">
    <!-- <app-main-header></app-main-header> -->
    <section class="section welcome-area bg-overlay subscribe-area h-100vh ptb_100">
      <div class="container h-100">
        <div class="row align-items-center justify-content-center h-100">
          <div class="col-12 col-md-8 col-lg-5 mt-5">
            <!-- Contact Box -->
            <div class="subscribe-content bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
              <!-- Contact Form -->
              <form id="contact-form" *ngIf="validLink">
                <div class="contact-top">
                  <h3 class="contact-title">{{translateArr.bp1}}</h3>
                  <h5 class="text-secondary fw-3 py-3">{{translateArr.bp2}}</h5>
                </div>
                <form class="subscribe-form">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                      </div>
                      <input type="password" class="form-control" placeholder="{{translateArr.bp10}}" type="password"
                        [formControl]="form.controls['password']">
                    </div>
                    <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                      class="text-danger support-text">{{translateArr.bp3}}</small>
                    <small *ngIf="form.controls['password'].hasError('minlength') && form.controls['password'].touched"
                      class="text-danger support-text">{{translateArr.bp4}}</small>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                      </div>
                      <input type="password" class="form-control" placeholder="{{translateArr.bp11}}" type="password"
                        [formControl]="form.controls['confirmPassword']">
                    </div>
                    <small
                      *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched"
                      class="text-danger support-text">{{translateArr.bp5}}</small>
                    <small
                      *ngIf="(form.errors != null && form.errors.samevalue) && form.controls['confirmPassword'].touched"
                      class="text-danger support-text">{{translateArr.bp6}}</small>
                  </div>
                  <button (click)="NewPassword()" type="submit" class="btn btn-lg btn-block p-3"
                    [disabled]="!form.valid">{{translateArr.bp7}}</button>
                </form>
              </form>


              <form id="contact-form" *ngIf="!validLink">
                <div class="contact-top">
                  <h3 class="contact-title">{{translateArr.bp8}}</h3>
                  <h5 class="text-secondary fw-3 py-3">{{translateArr.bp9}}</h5>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>