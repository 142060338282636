<section class="benefits-section">
  <div class="benefits-container">
    <div class="block-2">
      <div class="card-container">
        <div class="top-content">
          <div
            [id]="currentCarouselId"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                class="carousel-item"
                *ngFor="let screen of imagesScreen; let i = index"
                [class.active]="i === 0"
              >
                <img
                  [src]="screen"
                  class="d-block w-100"
                  alt="Slide {{ i + 1 }}"
                />
              </div>
            </div>
          </div>
          <div class="button-group">
            <span
              class="dot"
              [class.selected]="selectedButton === 1"
              (click)="hoverButton(1)"
            ></span>
            <span
              class="dot"
              [class.selected]="selectedButton === 2"
              (click)="hoverButton(2)"
            ></span>
            <span
              class="dot"
              [class.selected]="selectedButton === 3"
              (click)="hoverButton(3)"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="block-3">
      <div class="text-benefit">
        <h1>The Benefit For You</h1>
      </div>
      <div class="text-benefit">
        <h2>Lebih Hemat Semakin Cepat</h2>
      </div>
      <div class="button-content">
        <button (mouseover)="hoverButton(1)" class="ben-button-1">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/our-service/Image1.png"
            alt=""
          />
        </button>
        <button (mouseover)="hoverButton(2)" class="ben-button-2">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/our-service/Image.png"
            alt=""
          />
        </button>
        <button (mouseover)="hoverButton(3)" class="ben-button-3">
          <img
            src="https://storage.onindonesia.id/ondelivery.id/our-service/Image3.png"
            alt=""
          />
        </button>
      </div>
      <div class="text-content">
        <h2>{{ benefitContent.header }}</h2>
        <p>{{ benefitContent.content }}</p>
      </div>
    </div>
  </div>
</section>
